
const names = {
	EN: {
		first: {
			male: [
				['Alexander'],
				['Andrew'],
				['Benjamin'],
				['Brian'],
				['Charles'],
				['Daniel'],
				['David'],
				['Edward'],
				['George'],
				['Gregory'],
				['James'],
				['Jason'],
				['Jeffrey'],
				['John'],
				['Jonathan'],
				['Joseph'],
				['Kenneth'],
				['Kevin'],
				['Mark'],
				['Matthew'],
				['Michael'],
				['Nicholas'],
				['Paul'],
				['Richard'],
				['Robert'],
				['Ryan'],
				['Steven'],
				['Timothy'],
				['William'],
			],
			female: [
				['Alexandra'],
				['Amanda'],
				['Amy'],
				['Angela'],
				['Ashley'],
				['Christina'],
				['Christine'],
				['Elizabeth'],
				['Emily'],
				['Hannah'],
				['Jessica'],
				['Jennifer'],
				['Kimberly'],
				['Katherine'],
				['Lauren'],
				['Laura'],
				['Lisa'],
				['Madison'],
				['Mary'],
				['Megan'],
				['Melissa'],
				['Michelle'],
				['Natalie'],
				['Nicole'],
				['Rachel'],
				['Rebecca'],
				['Samantha'],
				['Sarah'],
				['Stephanie'],
				['Victoria'],
			],
		},
		last: [
			['Adams'],
			['Anderson'],
			['Baker'],
			['Brown'],
			['Carter'],
			['Clark'],
			['Hall'],
			['Harris'],
			['Jackson'],
			['Johnson'],
			['Lee'],
			['Lewis'],
			['Miller'],
			['Mitchell'],
			['Parker'],
			['Roberts'],
			['Smith'],
			['Taylor'],
			['Thompson'],
			['Turner'],
			['Walker'],
			['Wilson'],
			['Young'],
		],
	},
	FR: {
		first: {
			male: [
				['Adrien'],
				['Alexandre'],
				['Antoine'],
				['Arnaud'],
				['Arthur'],
				['Baptiste'],
				['Cédric'],
				['Christophe'],
				['Damien'],
				['Édouard'],
				['Étienne'],
				['Fabien'],
				['François'],
				['Gabriel'],
				['Guillaume'],
				['Hugo'],
				['Jérémy'],
				['Julien'],
				['Lucas'],
				['Léo'],
				['Maxime'],
				['Nicolas'],
				['Pierre'],
				['Romain'],
				['Sébastien'],
				['Thomas'],
				['Vincent'],
				['Yannick'],
			],
			female: [
				['Adèle'],
				['Alicia'],
				['Anaïs'],
				['Camille'],
				['Caroline'],
				['Céline'],
				['Charlotte'],
				['Chloé'],
				['Claire'],
				['Élodie'],
				['Émilie'],
				['Emma'],
				['Fanny'],
				['Florence'],
				['Inès'],
				['Julie'],
				['Laure'],
				['Léa'],
				['Manon'],
				['Marie'],
				['Mathilde'],
				['Nathalie'],
				['Pauline'],
				['Sophie'],
				['Valérie'],
				['Vanessa'],
			],
		},
		last: [
			['Blanchard'],
			['Bonnet'],
			['Bruneau'],
			['Caron'],
			['Denis'],
			['Fournier'],
			['Francois'],
			['Garcia'],
			['Girard'],
			['Leclerc'],
			['Lefevre'],
			['Lemoine'],
			['Leroy'],
			['Lopez'],
			['Marchand'],
			['Martin'],
			['Masson'],
			['Meunier'],
			['Muller'],
			['Robin'],
			['Roussel'],
			['Simon'],
		],
	},
	AR: {
		first: {
			male: [
				['Adam', 'آدم'],
				['Abderazzak', 'عبد الرزاق'],
				['Adil', 'عادل'],
				['Anass', 'آنس'],
				['Badr', 'بدر'],
				['Brahim', 'إبراهيم'],
				['Bilal', 'بلال'],
				['Farid', 'فريد'],
				['Fouad', 'فؤاد'],
				['Hamza', 'حمزة'],
				['Hicham', 'هشام'],
				['Ismail', 'إسماعيل'],
				['Kamal', 'كمال'],
				['Karim', 'كريم'],
				['Marwan', 'مروان'],
				['Mehdi', 'مهدي'],
				['Mohammed', 'محمد'],
				['Mohsin', 'محسن'],
				['Mouad', 'معاذ'],
				['Nabil', 'نبيل'],
				['Nassim', 'نسيم'],
				['Omar', 'عمر'],
				['Oussama', 'أسامة'],
				['Riyad', 'رياض'],
				['Salah Eddine', 'صلاح الدين'],
				['Said', 'سعيد'],
				['Sami', 'سامي'],
				['Samir', 'سمير'],
				['Taha', 'طه'],
				['Tarik', 'طارق'],
				['Walid', 'وليد'],
				['Yassine', 'ياسين'],
				['Younes', 'يونس'],
				['Youssef', 'يوسف'],
				['Zakaria', 'زكرياء'],
			],
			female: [
				['Ahlam', 'أحلام'],
				['Asmaa', 'أسماء'],
				['Amal', 'آمال'],
				['Amina', 'أمينة'],
				['Aya', 'آية'],
				['Bouchra', 'بشرى'],
				['Dounia', 'دنيا'],
				['Fatima Zahrae', 'فاطمة الزهراء'],
				['Hanae', 'هناء'],
				['Houda', 'هدى'],
				['Ikram', 'أكرام'],
				['Inssaf', 'إنصاف'],
				['Jamila', 'جميلة'],
				['Karima', 'كريمة'],
				['Lamyae', 'لمياء'],
				['Latifa', 'لطيفة'],
				['Lina', 'لينا'],
				['Loubna', 'لبنى'],
				['Malika', 'مليكة'],
				['Manal', 'منال'],
				['Nada', 'ندى'],
				['Nadia', 'نادية'],
				['Naima', 'نعيمة'],
				['Nour', 'نور'],
				['Oumaima', 'أميمة'],
				['Rabia', 'ربيعة'],
				['Rania', 'رانية'],
				['Safae', 'صفاء'],
				['Sanae', 'سناء'],
				['Siham', 'سهام'],
				['Wafaa', 'وفاء'],
				['Youssra', 'يسرى'],
				['Zineb', 'زينب'],
			],
		},
		last: [
			['Alami', 'العلامي'],
			['Alaoui', 'العلوي'],
			['Almansouri', 'المنصوري'],
			['Belarabi', 'بن العربي'],
			['Belhaj', 'بلحاج'],
			['Benjelloun', 'بنجلون'],
			['Bouabid', 'بوعبيد'],
			['Bouzidi', 'بوزيدي'],
			['Cherkaoui', 'الشرقاوي'],
			['Draoui', 'الضراوي'],
			['Echami', 'الشامي'],
			['Erifi', 'الريفي'],
			['El badoui', 'البدوي'],
			['El benani', 'البناني'],
			['El Fassi', 'الفاسي'],
			['El Kaddouri', 'القدوري'],
			['Haddad', 'الحداد'],
			['Hakimi', 'حكيمي'],
			['Hassani', 'الحسني'],
			['Lamrani', 'لعمراني'],
			['Nassiri', 'الناصيري'],
			['Saadaoui', 'سعداوي'],
			['Sebti', 'السبتي'],
			['Wahib', 'وهيب'],
		],
	},
};
const $sex = ['male', 'female'],
	rand = (min, max) => min + Math.floor(Math.random() * (max - min));

export default {
	name: 'DeveloperPatientsGenerator',
	methods: {
		async generate(lang) {
			const { first, last } = names[lang];
			for (let i = 0; i < 10; i++) {
				const s = $sex[rand(0, 3) > 1.75 ? 1 : 0],
					[fname, fname_ar] = first[s][rand(0, first[s].length - 1)],
					[lname, lname_ar] = last[rand(0, last.length - 1)],
					form = { fname, lname, sex: s },
					set = {
						...form,
						phone: `06${rand(10000000, 99999999)}`,
						email: '',
						fname_ar: fname_ar || '',
						lname_ar: lname_ar || '',
					},
					payload = { set, push: false };
				await this.$store.dispatch('patients/ADD_PATIENT', payload);
			}
			const currentRoute = this.$route.matched?.[0]?.path || this.$route.path;
			if (currentRoute === '/patients') await this.$store.dispatch('patients/SET_PATIENTS');
		},
	},
};
