import { render, staticRenderFns } from "./Select.vue?vue&type=template&id=848f9ae4&lang=pug"
import script from "./Select.vue?vue&type=script&lang=js"
export * from "./Select.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormLabel: require('/app/components/form/Label.vue').default})
