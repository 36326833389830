import { render, staticRenderFns } from "./alerts.vue?vue&type=template&id=26023e43&scoped=true&lang=pug"
import script from "./alerts.vue?vue&type=script&lang=js"
export * from "./alerts.vue?vue&type=script&lang=js"
import style0 from "./alerts.vue?vue&type=style&index=0&id=26023e43&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26023e43",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/app/components/global/Logo.vue').default,Footer: require('/app/components/global/Footer.vue').default})
