export default ({ $t, $useNotify, $sentry }, inject) => {
	const trim = (key, sides) => key.substring(sides, key.length - sides).trim();

	const find = (text, key, $args) => {
		const $key = key[0] === '[' ? key : `.${key}`;
		try {
			const res = eval(`$args${$key}`);
			if (res !== undefined) return `${res}`;
			throw new Error('Undefined value');
		} catch (error) {
			$sentry.captureException(error);
			console.error({ text, path: key, args: $args }, `Cannot resolve : args${$key}`);
			return `{{ ${key} }}`;
		}
	};

	/** Simplify concatenating arguments to a string
	 *
	 * - Argument as a string: It simply does concatenate the two strings
	 * ```
	 * $useBind("patient-records.the-patient", "Adam Anderson")
	 * // The patient Adam Anderson
	 * ```
	 *
	 * - Arguments as an object
	 * ```
	 * text = "Un rendez-vous de {{ from }} à {{ to }} le {{ date }}";
	 * args = {
	 * 		from: "10:30",
	 * 		to: "10:45",
	 * 		date: "12 Septembre 2023",
	 * };
	 * ```
	 *
	 * - Arguments as an array
	 * ```
	 * text = "Un rendez-vous de {?} à {?} le {?}";
	 * args = ["10:30", "10:45", "12 Septembre 2023"];
	 * ```
	 *
	 * - Deep binding
	 * ```
	 * text = "Un rendez-vous le {{ appointment.dates[0] }}";
	 * args = { appointment: { dates: ["12 Septembre 2023"] } };
	 * ```
	 *
	 * - Use array indexes in objects
	 * ```
	 * text = "Un rendez-vous le {0}{{ date }}{1}";
	 * args = {
	 * 		0: "<span class='text-bold'>",
	 * 		1: "</span>",
	 * 		date: "12 Septembre 2023",
	 * };
	 * ```
	 *
	 * - Customize array index formats
	 * 		- By order: `{?} à {?} le {?}`
	 * 		- Short format: `{0} à {1} le {2}`
	 * 		- Full format: `{{ [0] }} à {{ [1] }} le {{ [2] }}` (Also supports deep binding)
	 * 		- Note: You can mix and match these formats within the same string for flexibility.
	 * 		- Example: `{?} {?} {5} {4} {?}` is equivalent to `{0} {1} {5} {4} {2}`
	 */
	const useBind = (text, args, translateText = true) => {
		if (!['object', 'string'].includes(typeof args) || typeof text !== 'string') {
			$sentry.captureException(
				new Error('"text" should be a string and "args" should be an object, an array or a string')
			);
			return console.error(
				{ text, args },
				'"text" should be a string and "args" should be an object, an array or a string'
			);
		}
		let index = 0;
		return `${translateText ? $t(text) : text}${typeof args === 'string' ? ' ' + args : ''}`
			.replace(/{\s*\?\s*}/g, () => `{{ [${index++}] }}`)
			.replace(/{\s*\d+\s*}/g, (num) => `{{ [${trim(num, 1)}] }}`)
			.replace(/{{\s*[\w\.\[\]"'`]+\s*}}/g, (key) => find(text, trim(key, 2), args));
	};

	/**
	 * Simplify using CRUD operations (**As strings**)
	 *
	 * - For the acts `added`, `created`, `updated`, `deleted` ... (Ends by `ed`): Display a success notification
	 * ```
	 * $useBindAction('created', 'patient-records.the-patient');
	 * // Equivalent to
	 * $useNotify(
	 * 	$useBind(
	 * 		'actions.created',
	 * 		{ element: $t('patient-records.the-patient') }
	 * 	)
	 * )
	 * // Output: The patient has been created successfully
	 *
	 * $useBindAction('updated', 'patient-records.the-patient', 'Adam');
	 * // Equivalent to
	 * $useNotify(
	 * 	'success',
	 * 	$useBind(
	 * 		'actions.updated',
	 * 		{ element: $useBind('patient-records.the-patient', 'Adam') }
	 * 	)
	 * )
	 * // Output: The patient Adam has been updated successfully
	 * ```
	 *
	 * - For the other acts (Anything in locale/actions (`add`, `create`, `update`, `delete`, `see`, `save`...)): Returns a string
	 * ```
	 * $useBindAction('add', 'patient-records.a-patient');
	 * // Equivalent to
	 * $useBind('actions.add', $('patient-records.a-patient'))
	 * // Output: Add a patient
	 *
	 * $useBindAction('delete', 'patient-records.the-patient', 'Adam');
	 * // Equivalent to
	 * $useBind('actions.delete', $useBind('patient-records.the-patient', 'Adam'))
	 * // Output: Delete the patient Adam
	 * ```
	 *
	 * - (**Only for notifications**), To append extra text after `successfully`:
	 *
	 * The `args` parameter should include the property `end`
	 * ```
	 * $useBindAction('removed', 'patient-records.the-patient', {
	 * 	end: $t('waiting-room.from-waiting-room'),
	 * });
	 * // Output: The patient has been removed successfully from the waiting room.
	 * ```
	 * Note: `args` behaves like in `useBind`, but ensure it **DOES NOT** include an inner property named `args`, as it would override its value:
	 * ```
	 * $useBindAction('added', 'patient-records.the-patient', {
	 * 	args: "Adam",
	 * 	end: $t('waiting-room.to-waiting-room'),
	 * });
	 * // Output: The patient Adam has been added successfully to the waiting room.
	 * ```
	 */
	const useBindAction = (act, text, args = {}, translateText = true) => {
		let end = '';
		if (typeof args !== 'string') {
			if ('end' in args) end = args.end ? ` ${args.end}` : '';
			if ('args' in args) args = args.args;
		}
		const action = `actions.${act}`,
			element = useBind(text, args || {}, translateText);
		if (act.endsWith('ed')) $useNotify('success', useBind(action, { element, end }));
		else return useBind(action, element[0].toLowerCase() + element.slice(1));
	};

	inject('useBind', useBind);
	inject('useBindAction', useBindAction);
};
