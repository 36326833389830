// ~ Checklist for creating a new component that inherits from this mixin:
// ~> Required: Override the `value` and `disabledValue` props to specify the accepted types, always including the { value, valid } object structure.
// ~> Optional: For components with multiple modes, implement `getModes` and `getMode` methods to determine the component's mode. Refer to their implementation in `data()` and check examples in other components.
// ~> Important: If dealing with non-primitive values or requiring custom cloning, override the `clone` method to create copies without referencing the original. This prevents unexpected updates between internal and external values.
// ~> Important: If `clone` is overridden for non-primitive values, also override `areEqual` to compare values without considering object references.
// ~> Optional: While `isEmpty` is used for basic validation, implement `validateValue` method for custom validation logic, such as pattern matching for search fields.
// ~> Optional: Implement `componentProps` to inject additional props before binding them into the field component with `<Component v-bind="props" @change="onChange" />`.
// ~> Important: For components that change based on mode or other conditions, avoid using `v-if` and `v-else`. Instead, define the component name using `componentProps.is`, e.g., `{ is: this.mode === "number" ? "a-input-number" : "a-input" }`.
// ~> Optional: If the field component accepts values that don't match the internal format, override `formatValue` and `parseValue` to ensure correct value conversion (e.g., MomentJS instances for `a-data-picker` <==> date strings for `FormDatePicker`).
// ~> Optional: Review and override any other methods or props that are not mentioned here as needed. Use `CTRL+F` to search for "@to-override" and "@to-implement" in this mixin to locate customizable elements.

export default {
	name: 'FormField',
	emits: ['input' /* v-model */, 'change', 'suffix'],
	props: {
		// ~ Texts
		// Text label for the component.
		label: { type: [String, Boolean], default: '' },
		// Text label suffix for extra information.
		suffix: { type: [String, Boolean], default: '' },
		// Placeholder text displayed when the field is empty.
		placeholder: { type: [String, Boolean], default: '' },

		// ~ Value handling
		// Accepts values defined in the array returned by the `getModes` method.
		type: { type: String, required: false },
		// @to-override: Accepts either a direct value or a value wrapped in an object { value, valid }. Override this to specify what the current component should accept.
		value: { type: [Object, String, Number, Array], default: '' },
		// @to-override: The displayed value when the field is disabled. If not provided, the current value is used. Does not accept values wrapped in an object.
		disabledValue: { type: [String, Number, Array], required: false },

		// ~ Input handling mechanisms
		// Determines whether the input clearing option is allowed.
		allowClear: { type: Boolean, default: true },
		// Specifies whether the field is required. If not specified, the field is considered required if the value is passed as an object { value, valid }.
		required: { type: Boolean, default: undefined },
		// Indicates if the component is disabled. (Field with a grey background)
		disabled: { type: Boolean, default: false },
		// Indicates if the component is disabled. (Field with a normal white background)
		viewOnly: { type: Boolean, default: false },
	},
	data() {
		const $val = this.value,
			{ value: val, valid, data } = isObject($val) ? $val : { value: $val, valid: true };

		let mode; // @to-implement: Implement the methods `getModes` and `getMode` to determine the component mode. `getModes` should returns an array of supported modes, and `getMode` is to determine the mode based on the provided value type, format, etc., if `type` prop isn't set correctly.
		if (this.getModes && this.getMode)
			mode = this.getModes().includes(this.type) ? this.type : this.getMode(val, isObject($val));

		const $data = { external: this.clone(val, mode), internal: { valid, data, value: undefined } };
		if (!this.getData)
			($data.internal.value = this.clone($data.external, mode)), delete $data.internal.data;

		return mode ? { ...$data, mode } : $data;
	},
	computed: {
		computedValue() {
			let value = this.disabledValue;
			// The 'disabledValue' prop will only be displayed if the field is disabled or in view-only mode.
			if (value === undefined || (!this.disabled && !this.viewOnly)) value = this.internal.value;
			return this.formatValue(value, this.mode);
		},
		isRequired() {
			// If the 'required' prop is not specified, the field is considered required if the value is an object with a { value, valid } structure.
			return typeof this.required === 'boolean' ? this.required : Boolean(isObject(this.value));
		},
		/** Properties to bind directly to the field component using `<Component v-bind="props">`. */
		props() {
			return {
				value: this.computedValue,
				disabled: this.disabled || this.viewOnly,
				placeholder: this.placeholder || this.placeholderText || this.label, // @to-implement: Implement `placeholderText` in `data` or as a computed value to provide a default placeholder when the `placeholder` prop is absent.
				allowClear: this.allowClear,
				...this.componentProps, // @to-implement: Additional props to bind to the field component. Implement it in `data` or as a computed value.
				class: {
					'w-full': true,
					'view-only': this.viewOnly,
					error: !this.disabled && !this.viewOnly && !this.internal.valid,
					...this.componentProps?.class, // @to-implement: Additional class props to bind to the field component.
				},
			};
		},
	},
	methods: {
		/** Only to use: The setter used to update `internal.value` and `internal.data`. Note that `internal.valid` is updated in the watcher of `internal.value` */
		setValue(value, data) {
			value = { ...this.internal, value: this.clone(value, this.mode) };
			// @to-implement: Implement `getData` method if a different value representation is needed within `internal.data` or for `@change` emissions.
			if (this.getData) value.data = data || this.getData(value.value, this.mode);
			this.internal = value;
		},

		/** Only to use: The method used to update the internal value using `<Component v-on:change="onChange">`. */
		onChange(value) {
			if (this.disabled || this.viewOnly) return;
			value = value?.target && 'value' in value.target ? value.target.value : value;
			this.setValue(this.parseValue(value, this.mode));
		},

		/** @to-override: Clones a new value from the original one. Primarily used for non-primitive data types to create a copy without retaining the reference to the original. */
		clone(origin, mode) {
			return origin;
		},

		/** @to-override: Compares two values to determine equality without considering object references. Primarily used for non-primitive data types and is effectively the opposite of the `clone` method. */
		areEqual(internalValue, comparedValue, mode) {
			return internalValue === comparedValue;
		},

		/** @to-override: Converts the internal value to the format needed by the field component. (e.g., string date to MomentJS instance) */
		formatValue(value, mode) {
			return this.clone(value, mode);
		},

		/** @to-override: Converts a value from the field component to the internal value format. (e.g., MomentJS instance to string date) */
		parseValue(value, mode) {
			return this.clone(value, mode);
		},

		/** @to-override: Prepares the data for emission by making adjustments such as removing unnecessary properties from complex data. While this method exists, it is still recommended to align the parent component data format with the internal format. */
		formatToEmit(value, mode) {
			return this.clone(value, mode);
		},

		/** @to-override: Checks if the value is empty. By default, it detects falsy values and empty arrays. */
		isEmpty(value, mode) {
			return Array.isArray(value) ? !value?.length : !value;
		},
	},
	async created() {
		if (this.getData) this.setValue(this.external, this.internal.data);
		await this.onCreate?.(); // @to-implement: This method is introduced to allow control over execution timing, as using `created` lifecycle hook would limit control.
		await this.$nextTick();
	},
	watch: {
		/** This watcher synchronizes the field validity. */
		'value.valid'(valid) {
			this.internal.valid = Boolean(valid);
		},

		/** This watcher synchronizes the external value with the internal value. */
		value: {
			handler(value) {
				if (isObject(value)) value = value.value;
				if (this.areEqual(this.internal.value, value, this.mode)) return;
				this.external = this.clone(value, this.mode);
				this.setValue(this.external);
			},
			deep: true,
		},

		/** This watcher synchronizes the internal value with the external value and field validity. */
		'internal.value': {
			handler(value, oldValue) {
				// If the values are the same, exit the function.
				oldValue = oldValue === undefined ? '' : oldValue;
				if (this.areEqual(value, oldValue, this.mode)) return;

				// Set the field validity.
				if (!this.isRequired && !this.validateValue) this.internal.valid = true;
				else {
					const empty = this.isEmpty(value, this.mode);
					this.internal.valid = Boolean(
						// Valid if both external and internal values are empty, which typically happens when the parent component programmatically clears the field. This distinction is important for operations like clearing forms or closing modals.
						(this.isEmpty(this.external, this.mode) && empty) ||
							// @to-implement: Implement the `validateValue` method to execute custom validation logic, such as pattern matching for search fields, to determine the value’s validity.
							(this.validateValue ? this.validateValue(value, this.mode, empty) : !empty)
					);
					if (this.wrongBehavior) return; // @to-implement: To use the `wrongBehavior` boolean effectively, set it in `data` with a default value of `false`, then pause this code execution and prevent emitting wrong changes after custom validation in `validateValue`.
				}

				// If the current value doesn't equal the external value, emit input and change events to synchronize values.
				if (!this.areEqual(value, this.external, this.mode)) {
					value = this.formatToEmit(value, this.mode);
					const $v = isObject(this.value) ? { ...this.value, ...this.internal, value } : value;
					this.external = value;
					this.$emit('input', $v);
					this.$emit('change', this.getData ? this.getData(value, this.mode, true) : value);
				}
			},
			deep: true,
		},
	},
};

/** A method to check if the passed value is an object of { value, valid, data }, or the value directly such as strings, numbers, arrays or an object in different structure (does't contain the prop `valid`) */
const isObject = (value) =>
	value && typeof value === 'object' && !Array.isArray(value) && typeof value.valid === 'boolean';
