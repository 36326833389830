import getRoutes from '~/utils/app/routes';
import SignIn from '~/apollo/queries/users/sign-in';
import Init from '~/apollo/queries/users/init';
import ScreencastInit from '~/apollo/queries/users/screencast-init';
import UserRoleSubscription from '~/apollo/subscriptions/user-role';

import * as yup from 'yup';

export const state = () => ({
	user: null,
	sessionEnded: false,
});

export const getters = {
	GET_USER({ user }) {
		return user;
	},
	GET_IS_SESSION_ENDED({ sessionEnded }) {
		return sessionEnded;
	},
};

export const actions = {
	async INIT({ commit, dispatch }, { email, getToken, onBeforeSuccess, onSuccess, onError }) {
		let isScreencastEmail = false;
		if (email.startsWith('screencast-') && email.endsWith('@clinikdna.com'))
			try {
				const uuid = email.slice(11, email.length - 14);
				isScreencastEmail = Boolean(yup.string().uuid().validateSync(uuid));
			} catch {}

		let errorExecuted;
		const $onError = (error) => {
			if (errorExecuted) return;
			errorExecuted = true;
			return onError ? onError(error, isScreencastEmail) : this.$useNotify('error');
		};

		const response = getToken ? await getToken($onError) : { token: this.$useToken() };
		if (!response?.token) return $onError();

		return await this.$useSimpleQuery(
			isScreencastEmail ? ScreencastInit : Init,
			{ email },
			async (data) => {
				if (!data.users[0]) return $onError('Details not associated with any account.');
				const user = { ...data.users[0].contact, ...data.users[0], email };
				if (isScreencastEmail) user.role = 'screencast';
				user.full_name = `${user.fname} ${user.lname}`;
				user.title = user.honorific || '';

				const clinic = {
					...user.clinic,
					name: user.clinic?.contact?.name?.[0],
					subscribed: Boolean(user.clinic?.clinic_subscription?.id),
				};

				delete user.clinic, delete user.contact, delete user.honorific, delete user.__typename;
				delete clinic.contact, delete clinic.clinic_subscription, delete clinic.__typename;

				commit('SET_USER', user);
				commit('clinic/SET_CLINIC', clinic, { root: true });
				commit('SET_ROUTES', getRoutes(user.role), { root: true });

				await onBeforeSuccess?.(response);
				if (isScreencastEmail) {
					const language = data.settings[0].language;
					commit('settings/SET_GENERAL', { language }, { root: true });
					await this.$i18n.setLocale(language);
				} else {
					await Promise.all([
						dispatch('settings/SET_GENERAL', false, { root: true }),
						dispatch('settings/SET_NUMBERS_FINANCE', false, { root: true }),
						dispatch('settings/SET_DATE_TIME', false, { root: true }),
					]);
				}
				return onSuccess?.(user, isScreencastEmail);
			},
			(error) => $onError(error),
			{ Authorization: `Bearer ${response.token}` }
		);
	},
	async SIGN_IN_USER({ dispatch, rootGetters }, variables) {
		const onAuthFuncResponse = ({ login }) => login;
		const errorMessage = variables.errorMessage;
		delete variables.errorMessage;

		await dispatch('INIT', {
			email: variables.email,
			getToken: (onError) => this.$useSimpleQuery(SignIn, variables, onAuthFuncResponse, onError),
			onBeforeSuccess: ({ token, intercom_user_hmac }) => {
				this.$useSetCookie('auth_intercom_user_hmac', intercom_user_hmac, variables.must_exp);
				this.$useSetToken(token, variables.must_exp);
				this.$useSetCookie('auth_email', variables.email, variables.must_exp);
			},
			onSuccess: (user, isScreencastEmail) => {
				if (isScreencastEmail) this.$useNotify('success', this.$t('waiting-room.auth-success'));
				else {
					this.$router.push(rootGetters['GET_ROUTES'][0].root);
					const message = this.$useBind('auth.welcome-back-user', {
						title: user.title ? `${user.title}${user.title.endsWith('.') ? '' : '.'} ` : '',
						name: `<b>${user.full_name}</b>`,
					});
					this.$useNotify('success', message);
				}
			},
			onError: () => this.$useNotify('error', this.$t(errorMessage || 'auth.error')),
		});
	},
	async SIGN_OUT_USER({ state: { user }, commit, dispatch }, isSessionEnd) {
		// * If user logged out ==> remove all cookies.
		this.$useRemoveToken();
		this.$useRemoveCookie('auth_email');
		this.$useRemoveCookie('auth_intercom_user_hmac');
		if (isSessionEnd) {
			// Show a modal to inform the user that the session was ended.
			commit('SET_IS_SESSION_ENDED', true);
		} else {
			// Redirect to the sign in page and set back the store to its unauthenticated state
			await dispatch('STORE_PAYLOAD', { email: user.email }, { root: true });
			const message = this.$useBind('auth.see-you-later', { name: `<b>${user.fname}</b>` });
			this.$useNotify('info', message);
			commit('CLEAR_STORE');
			this.$router.push('/sign-in');
		}
	},
	async WATCH_ROLE({ state, dispatch }) {
		if (state.user && state.user.role !== 'owner') {
			const role = state.user.role;
			await this.$useSubscription('role', UserRoleSubscription, { id: state.user.id }, (data) => {
				if (data.users_by_pk?.role !== role) dispatch('SIGN_OUT_USER', true);
			});
		}
	},
	async FORGOTTEN_PASSWORD(_, { email, recaptcha }) {
		return await this.$useSimpleAxios(
			'forgot-password',
			{ email, g_recaptcha_response: recaptcha },
			({ status, emailFound }) => {
				if (emailFound === false) return { emailFound: false, success: false };
				else return { emailFound: true, success: status === 'Succeeded' };
			},
			() => ({ emailFound: true, success: false })
		);
	},
	async RESET_PASSWORD(_, { password, recaptcha, token }) {
		return await this.$useSimpleAxios(
			'reset-password',
			{ password, g_recaptcha_response: recaptcha },
			{ Authorization: `Bearer ${token}` },
			({ passwordReset }) => {
				if (passwordReset) {
					this.$useNotify('success', this.$t('auth.password-reset-success'));
					return true;
				}
			}
		);
	},
};

export const mutations = {
	SET_USER(state, payload) {
		state.user = payload;
	},
	SET_IS_SESSION_ENDED(state, payload) {
		state.sessionEnded = payload;
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
