export default async function ({ store, route, redirect }) {
	if (route.path === '/token-expired') return;

	if (store.state.firstRoute) {
		const query = Object.entries(route.query),
			url = query.length
				? route.path + '?' + query.map(([k, v]) => `${k}=${v}`).join('&')
				: route.path;

		redirect(`/authenticating?r=${encodeURIComponent(url)}`);
	}
}
