
export default {
	name: 'ErrorLayout',
	props: ['error'],
	computed: {
		initialPage() {
			return this.$store.getters['GET_ROUTES']?.[0]?.root || '/sign-in';
		},
		isSignedIn() {
			return this.initialPage !== '/sign-in';
		},
	},
	methods: {
		signOut() {
			this.$store.dispatch('user/SIGN_OUT_USER');
		},
		goBack() {
			this.$router.replace(this.initialPage);
		},
	},
	mounted() {
		this.$i18n.setLocale(this.$useSettings('general').language || 'en');
	},
};
