export default ({ store }, inject) => {
	inject('useRole', (...roles) => {
		let defaultValue = false;
		const lastIndex = roles.length - 1;
		if (typeof roles[lastIndex] === 'boolean') {
			defaultValue = roles[lastIndex];
			roles = roles.slice(0, lastIndex);
		}
		const role = store.getters['user/GET_USER']?.role;
		return role ? roles.includes(role) : defaultValue;
	});
};
