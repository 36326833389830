/** @type {(symbol: string) => Symbol} */
const atLeft = (symbol) => ({ value: `${symbol} {{ value }}` }),
	/** @type {(symbol: string) => Symbol} */
	atRight = (symbol) => ({ value: `{{ value }} ${symbol}` }),
	/** @type {(symbol: string, isAtLeft: boolean) => Symbol} */
	arabic = (symbol, isAtLeft) => ({
		value: (isAtLeft ? atLeft : atRight)(symbol).value,
		locales: ['fa', 'pa', 'ar'],
	});

/**
 * @typedef {Object} Symbol
 * @property {string} value The currency symbol concatenated with "{{ value }}" that represents where the number should be inserted. For example, "$ {{ value }}", "{{ value }} DH", etc.
 */

/**
 * @typedef {Object} Currency
 * @property {string} code The standard (ISO 4217) code of the currency.
 * @property {Symbol[]} symbols An array containing all the symbols associated with the currency.
 */

/** @type {Currency[]} */
export const currencies = Object.entries({
	AED: ['DH', 'AED', arabic('د.إ.')],
	AFN: [atLeft('Afs'), 'AFN', arabic('؋')],
	ALL: ['Lekë', 'Lek', 'L', 'ALL'],
	AMD: ['֏', atLeft('֏'), 'AMD'],
	ANG: [atLeft('ƒ'), 'ANG'],
	AOA: [atLeft('Kz'), 'AOA'],
	ARS: [atLeft('$'), 'ARS'],
	AUD: [atLeft('$'), atLeft('A$'), atLeft('AU$'), 'AUD'],
	AWG: [atLeft('ƒ'), 'AWG'],
	AZN: [atLeft('₼'), 'AZN'],
	BAM: [atLeft('KM'), 'BAM'],
	BBD: [atLeft('$'), 'BDS', 'BBD'],
	BDT: [atLeft('৳'), atLeft('Tk'), 'Tk', 'BDT'],
	BGN: ['лв', 'leva', 'lev', 'BGN'],
	BHD: ['BD', 'BHD', arabic('د.ب.')],
	BIF: [atLeft('FBu'), 'FBu', 'BIF'],
	BMD: [atLeft('$'), atLeft('BM$'), 'BMD'],
	BND: [atLeft('$'), atLeft('BN$'), 'BND'],
	BOB: [atLeft('Bs'), 'BOB'],
	BRL: [atLeft('R$'), atLeft('$'), 'BRL'],
	BSD: [atLeft('$'), atLeft('BS$'), 'BSD'],
	BTN: [atLeft('Nu'), 'BTN'],
	BWP: [atLeft('P'), 'pula', 'BWP'],
	BYN: [atLeft('Br'), 'BYN'],
	BZD: [atLeft('$'), atLeft('BZ$'), 'BZD'],
	CAD: [atLeft('$'), atLeft('C$'), atLeft('CA$'), 'CAD'],
	CDF: ['FC', 'CDF'],
	CHF: ['CHF'],
	CLF: ['UF', 'CLF'],
	CLP: [atLeft('$'), 'CLP'],
	CNY: [atLeft('￥'), atLeft('CN¥'), '元', 'CNY'],
	COP: [atLeft('$'), atLeft('Col$'), 'COP'],
	CRC: [atLeft('₡'), 'CRC'],
	CUC: [atLeft('$'), 'CUC'],
	CUP: [atLeft('₱'), 'CUP'],
	CVE: [atLeft('$'), 'Esc', 'CVE'],
	CZK: ['Kč', 'CZK'],
	DJF: ['Fdj', 'DJF'],
	DKK: [atLeft('kr'), 'DKK'],
	DOP: [atLeft('RD$'), 'DOP'],
	DZD: [atLeft('DA'), 'DA', 'DZD', arabic('د.ج.')],
	EGP: [atLeft('£'), 'EGP', arabic('ج.م.')],
	ERN: ['Nfk', 'ERN', arabic('ناكفا')],
	ETB: ['ብር', 'Br', 'ETB'],
	EUR: [atLeft('€'), 'EUR'],
	FJD: [atLeft('$'), atLeft('FJ$'), 'FJD'],
	FKP: [atLeft('£'), 'FKP'],
	GBP: [atLeft('£'), 'GBP'],
	GEL: [atLeft('₾'), 'lari', 'GEL'],
	GHS: [atLeft('GH₵'), 'GHS'],
	GIP: [atLeft('£'), 'GIP'],
	GMD: [atLeft('D'), 'D', 'GMD'],
	GNF: ['FG', 'Fr', 'GNF'],
	GTQ: [atLeft('Q'), 'GTQ'],
	GYD: [atLeft('$'), atLeft('G$'), 'GYD'],
	HKD: [atLeft('$'), atLeft('HK$'), 'HKD'],
	HNL: [atLeft('L'), 'HNL'],
	HTG: [atLeft('G'), 'HTG'],
	HUF: ['Ft', 'HUF'],
	IDR: [atLeft('Rp'), 'IDR'],
	ILS: [atLeft('₪'), 'ILS'],
	INR: [atLeft('₹'), atLeft('₨'), 'INR'],
	IQD: [atLeft('ID'), 'ID', 'IQD', arabic('د.ع.')],
	IRR: [atLeft('RIs'), 'IRR', arabic('﷼'), arabic('ر.إ.')],
	ISK: ['kr', 'ISK'],
	JMD: [atLeft('$'), atLeft('J$'), 'JMD'],
	JOD: ['JD', 'JOD', arabic('د.أ.')],
	JPY: [atLeft('￥'), atLeft('JP¥'), '円', 'JPY'],
	KHR: ['៛', 'KHR'],
	KPW: [atLeft('₩'), '원', 'KPW'],
	KRW: [atLeft('₩'), '원', 'KRW'],
	KWD: ['KD', 'KWD', arabic('د.ك.')],
	KES: [atLeft('KSh'), 'KSh', 'KES'],
	KGS: ['сом', 'KGS'],
	KMF: ['CF', 'KMF'],
	KYD: [atLeft('$'), atLeft('CI$'), 'KYD'],
	KZT: [atLeft('₸'), 'KZT'],
	LAK: [atLeft('₭'), 'LAK'],
	LBP: ['LL', 'lira', 'LBP', arabic('ل.ل.')],
	LKR: [atLeft('රු'), atLeft('௹'), atLeft('₨'), 'LKR'],
	LRD: [atLeft('$'), 'LD', 'LRD'],
	LSL: [atLeft('M'), atLeft('L'), 'LSL'],
	LYD: ['LD', 'LYD', arabic('د.ل.')],
	MAD: ['DH', 'MAD', arabic('د.م.')],
	MDL: [atLeft('L'), 'lei', 'leu', 'MDL'],
	MGA: [atLeft('Ar'), 'MGA'],
	MKD: [atLeft('ден'), 'den', 'MKD'],
	MMK: [atLeft('K'), 'MMK'],
	MNT: [atLeft('₮'), 'MNT'],
	MOP: [atLeft('MOP$'), 'MOP'],
	MRU: [atLeft('MU'), 'MRU', arabic('أ.م.')],
	MUR: [atLeft('₨'), 'MUR'],
	MVR: [atLeft('Rf'), 'MVR'],
	MXN: [atLeft('$'), atLeft('MX$'), 'MXN'],
	MXV: ['MXV'],
	MYR: [atLeft('RM'), 'MYR'],
	MWK: [atLeft('MK'), 'MWK'],
	MZN: [atLeft('MT'), atLeft('MTn'), 'MTn', 'MZN'],
	NAD: [atLeft('$'), atLeft('N$'), 'NAD'],
	NGN: [atLeft('₦'), 'NGN'],
	NIO: [atLeft('C$'), 'NIO'],
	NOK: ['kr', 'Nkr', 'NOK'],
	NPR: [atLeft('रु'), 'NPR'],
	NZD: [atLeft('$'), atLeft('NZ$'), '$NZ', 'NZD'],
	OMR: ['RO', 'OMR', arabic('﷼'), arabic('ر.ع.')],
	PKR: [atLeft('₨'), 'PKR'],
	PAB: [atLeft('B/'), 'PAB'],
	PGK: [atLeft('K'), 'PGK'],
	PYG: [atLeft('₲'), atLeft('Gs'), 'PYG'],
	PEN: [atLeft('S/'), 'PEN'],
	PLN: ['zł', 'PLN'],
	PHP: [atLeft('₱'), 'PHP'],
	QAR: ['QR', 'QAR', arabic('﷼'), arabic('ر.ق.')],
	RON: ['lei', 'RON'],
	RSD: [atLeft('DIN'), atLeft('дин'), 'RSD'],
	RUB: [atLeft('₽'), 'RUB'],
	RWF: ['RF', 'R₣', 'RWF'],
	SAR: ['SAR', 'SR', arabic('﷼'), arabic('ر.س.')],
	SBD: [atLeft('$'), atLeft('SI$'), 'SBD'],
	SCR: [atLeft('₨'), 'SR', 'SCR'],
	SDG: [atLeft('LS'), 'LS', 'SDG', arabic('ج.س.')],
	SEK: ['kr', 'SEK'],
	SGD: [atLeft('$'), atLeft('S$'), 'SGD'],
	SHP: [atLeft('£'), 'SHP'],
	SLE: [atLeft('Le'), 'SLE'],
	SOS: ['Sh', 'SOS', arabic('شلن')],
	SRD: [atLeft('$'), atLeft('SR$'), 'SRD'],
	SLL: [atLeft('Le'), 'SLL'],
	SSP: [atLeft('£'), 'SSP'],
	STN: ['Db', 'STN'],
	SVC: [atLeft('₡'), 'SVC'],
	SYP: [atLeft('LS'), atLeft('SP'), 'lira', 'SYP', arabic('ل.س.')],
	SZL: [atLeft('E'), 'SZL'],
	THB: [atLeft('฿'), 'THB'],
	TJS: [atLeft('SM'), 'TJS'],
	TMT: ['m', 'TMT'],
	TND: ['DT', 'TND', arabic('د.ت.')],
	TZS: [atLeft('TSh'), 'TZS'],
	TOP: [atLeft('T$'), 'TOP'],
	TTD: [atLeft('$'), atLeft('TT$'), 'TTD'],
	TRY: [atLeft('₺'), 'TRY'],
	TWD: [atLeft('NT$'), atLeft('$'), '元', 'TWD'],
	UAH: [atLeft('₴'), 'грн', 'UAH'],
	UGX: [atLeft('USh'), 'UGX'],
	USD: [atLeft('$'), atLeft('US$'), 'USD'],
	UYI: ['UYI'],
	UYU: [atLeft('$'), atLeft('U$'), '$U', 'UYU'],
	UZS: [atLeft('лв'), atLeft('som'), 'UGX'],
	VEF: [atLeft('Bs'), 'VEF'],
	VND: [atLeft('₫'), atLeft('đ'), 'VND'],
	VUV: ['VT', 'VUV'],
	WST: [atLeft('$'), atLeft('WS$'), 'WST'],
	XAF: ['FCFA', 'XAF'],
	XCD: [atLeft('$'), atLeft('EC$'), 'XCD'],
	XDR: ['SDR', 'XDR'],
	XOF: ['CFA', 'XOF'],
	XPF: [atLeft('F'), 'XPF'],
	XSU: ['XSU'],
	XUA: ['XUA'],
	YER: ['YRl', 'YER', arabic('﷼'), arabic('ر.ي.')],
	ZMW: [atLeft('ZK'), 'ZK', 'ZMW'],
	ZWL: [atLeft('$'), atLeft('Z$'), 'ZWL'],
	ZAR: [atLeft('R'), 'ZAR'],
})
	.map(([code, symbols]) => ({
		code,
		symbols: symbols
			.map((symbol) => (typeof symbol === 'string' ? atRight(symbol) : symbol))
			.filter((symbol) => !symbol.locales),
	}))
	.sort((currency1, currency2) => (currency1.code > currency2.code ? 1 : -1));

export const importantCurrencies = [
	'USD',
	'EUR',
	'GBP',
	'CAD',
	'JPY',
	'CNY',
	'MAD',
	'SAR',
	'BHD',
	'INR',
	'KRW',
	'SEK',
	'NOK',
	'DKK',
	'RUB',
];

/** @type {(code: string) => Currency} */
export const getCurrency = (code) => currencies.find((currency) => currency.code === code);

// const singularAndPlural = {
// 	AFN: ['Af', 'Afs'],
// 	ALL: ['Lek', 'Lekë'],
// 	BGN: ['lev', 'leva'],
// 	MDL: ['leu', 'lei'],
// 	RON: ['leu', 'lei'],
// };
