
export default {
	name: 'DateTime',
	data() {
		return { refresh: false, interval: null };
	},
	computed: {
		now() {
			this.refresh;
			return this.$$moment(this.$moment.tz(this.$useSettings('dates').timezone));
		},
		currentDate() {
			return this.$useFormat('date', this.now);
		},
		currentTime() {
			return this.$useFormat('time', this.now);
		},
	},
	created() {
		try {
			if (window && this.interval === null)
				this.interval = setInterval(() => (this.refresh = !this.refresh), 4000);
		} catch (error) {
			this.$sentry.captureException(error);
		}
	},
	beforeDestroy() {
		clearInterval(this.interval);
		this.interval = null;
	},
};
