import { render, staticRenderFns } from "./Devtool.vue?vue&type=template&id=6ca02533&scoped=true&lang=pug"
import script from "./Devtool.vue?vue&type=script&lang=js"
export * from "./Devtool.vue?vue&type=script&lang=js"
import style0 from "./Devtool.vue?vue&type=style&index=0&id=6ca02533&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca02533",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopUp: require('/app/components/pop/Up.vue').default,DeveloperCopyUserInfo: require('/app/components/developer/CopyUserInfo.vue').default,DeveloperPatientsGenerator: require('/app/components/developer/PatientsGenerator.vue').default,DeveloperTreatmentsGenerator: require('/app/components/developer/TreatmentsGenerator.vue').default,DeveloperAppointmentsGenerator: require('/app/components/developer/AppointmentsGenerator.vue').default})
