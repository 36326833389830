import PaginateTreatments from '~/apollo/queries/treatments/paginate';
import TreatmentQuery from '~/apollo/queries/treatments/one';
import TreatmentsSearch from '~/apollo/queries/treatments/all';
import CreateTreatment from '~/apollo/mutations/treatments/create';
import UpdateTreatment from '~/apollo/mutations/treatments/update';
import TreatmentsUsage from '~/apollo/queries/treatments/check-usage';
import UpdateTreatmentDeleted from '~/apollo/mutations/treatments/delete';

const PARSE = (treatment, { id, key } = {}) => {
	treatment = { ...treatment };
	const { treatment_price_alt: price_alt, tax_alt } = treatment;
	if (price_alt) treatment = treatment.treatment || {};
	else treatment = treatment.treatment || treatment || {};
	id = treatment.id || id || (key ? `_disabled_${key}` : '_disabled_0');
	if (treatment.is_deleted && !id.startsWith('_disabled_')) id = `_disabled_${id}`;
	treatment.title = treatment.title || '';
	treatment.price = price_alt || treatment.price || 0;
	treatment.tax = tax_alt || treatment.tax || null;
	treatment.description = treatment.description || '';
	const [color, style] = treatment.style?.split('-') || ['white', 'simple'];
	Object.assign(treatment, { id, color, style });
	delete treatment.__typename, delete treatment.tax?.__typename;
	return treatment;
};

export { PARSE as PARSE_TREATMENT };

export const state = () => ({
	treatment: null,
	treatments: { default: [], deleted: [] },
	pagination: {
		default: { current: 1, limit: 25, total: 0 },
		deleted: { current: 1, limit: 25, total: 0 },
	},
	filters: { default: {}, deleted: {} },
	searchTreatments: [],
});

export const getters = {
	GET_TABLES_COLUMNS() {
		return [
			{ data: 'title', title: 'global.title' },
			{ slot: 'price', title: 'treatments.price-include-tax' },
			{ data: 'description', title: 'global.description' },
			{ slot: 'style', title: 'treatments.style' },
		];
	},
	GET_TREATMENT({ treatment }) {
		return treatment;
	},
	GET_TREATMENTS({ treatments }) {
		return treatments;
	},
	GET_PAGINATION({ pagination }) {
		return pagination;
	},
	GET_FILTERS({ filters }) {
		return filters;
	},
	GET_SEARCH_TREATMENTS({ searchTreatments }) {
		return searchTreatments;
	},
};

export const actions = {
	async SET_TREATMENT({ commit }, id) {
		await this.$useQuery(TreatmentQuery, { id }, (data) => {
			const treatment = data.treatments_by_pk;
			if (treatment) commit('SET_TREATMENT', PARSE(treatment, { id }));
		});
	},
	async SET_TREATMENTS({ getters, commit }, { tableName = 'default' } = {}) {
		const where = getters['GET_FILTERS'][tableName],
			{ current, limit } = getters['GET_PAGINATION'][tableName],
			filters = { offset: (current - 1) * limit, limit, where };
		await this.$useQuery(PaginateTreatments, filters, (data) => {
			const total = data.treatments_aggregate.aggregate.count;
			commit('SET_PAGINATION', { [tableName]: { total } });
			commit('SET_TREATMENTS', { [tableName]: data.treatments.map(PARSE) });
		});
	},
	async SET_SEARCH_TREATMENTS({ commit }, where) {
		return await this.$useQuery(TreatmentsSearch, { where }, ({ treatments }) => {
			commit('SET_SEARCH_TREATMENTS', treatments.map(PARSE));
		});
	},
	async CHECK_USAGE(_, id) {
		const isUsed = await this.$useQuery(TreatmentsUsage, { id }, (data) => {
			const {
				appointments_treatments_aggregate: { aggregate },
				invoice_bill_items_aggregate: { aggregate: agg },
			} = data;
			return aggregate.count || agg.count;
		});
		return Boolean(isUsed);
	},
	async CREATE_TREATMENT({ rootGetters, dispatch }, { set, push = true }) {
		set.clinic_id = rootGetters['clinic/GET_CLINIC'].id;
		set.style = `${set.color}-${set.style}`;
		delete set.color;
		await this.$useMutation(
			CreateTreatment,
			{ object: set },
			({ insert_treatments_one: { id } }) => {
				this.$useBindAction('created', 'treatments.the-treatment');
				if (push) this.$router.replace(`/treatments/${id}`);
			},
			(_, error) => dispatch('CHECK_TITLE_DUPLICATE_ERROR', error)
		);
	},
	async UPDATE_TREATMENT({ dispatch }, { id, set }) {
		set.style = `${set.color}-${set.style}`;
		delete set.color;
		await this.$useMutation(
			UpdateTreatment,
			{ id, set },
			() => this.$useBindAction('updated', 'treatments.the-treatment'),
			(_, error) => dispatch('CHECK_TITLE_DUPLICATE_ERROR', error)
		);
	},
	async DELETE_TREATMENT({ dispatch }, { id, refresh }) {
		const payload = { where: { id: { _eq: id } }, is_deleted: true };
		await this.$useMutation(UpdateTreatmentDeleted, payload, () => {
			this.$useBindAction('deleted', 'treatments.the-treatment');
			if (refresh) dispatch('SET_TREATMENTS');
		});
	},
	async RESTORE_TREATMENTS({ getters, dispatch }, { id, onSuccess, refresh }) {
		const message = id
			? ['restored', 'treatments.the-treatment']
			: ['all-restored', 'treatments.the-treatments'];
		if (!id && !getters['GET_TREATMENTS'].deleted.length) return this.$useBindAction(...message);
		const payload = {
			where: id ? { id: { _eq: id } } : { is_deleted: { _eq: true } },
			is_deleted: false,
		};
		await this.$useMutation(UpdateTreatmentDeleted, payload, () => {
			this.$useBindAction(...message);
			onSuccess?.();
			if (refresh) dispatch('SET_TREATMENTS', { tableName: 'deleted' });
		});
	},
	CHECK_TITLE_DUPLICATE_ERROR(_, error) {
		if (error.includes('treatments_title_key'))
			this.$useNotify('error', this.$t('treatments.title-duplicate-error'));
		else this.$useNotify('error');
	},
};

export const mutations = {
	SET_TREATMENT(state, payload) {
		state.treatment = payload;
	},
	SET_TREATMENTS(state, payload) {
		state.treatments = { ...state.treatments, ...payload };
	},
	SET_PAGINATION(state, payload) {
		const $payload = { ...state.pagination };
		Object.entries(payload).map(([key, value]) => ($payload[key] = { ...$payload[key], ...value }));
		state.pagination = $payload;
	},
	SET_FILTERS(state, payload) {
		state.filters = { ...state.filters, ...payload };
	},
	SET_SEARCH_TREATMENTS(state, payload) {
		state.searchTreatments = payload;
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
