
export default {
	name: 'FormLabel',
	props: {
		label: { type: String, default: '' },
		suffix: { type: String, default: '' },
		rightSuffix: { type: String, default: '' },
		required: { type: Boolean, default: false },
		visible: { type: Boolean, default: true },
		suffixVisible: { type: Boolean, default: true },
		suffixLink: { type: [Object, String], default: '' },
		viewOnly: { type: Boolean, default: false },
	},
};
