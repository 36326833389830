
const treatments = {
	EN: [
		{
			id: '00',
			title: 'Dental Cleaning',
			description: 'Routine cleaning to remove plaque and tartar from teeth.',
		},
		{
			id: '01',
			title: 'Dental Fillings',
			description: 'Repair of teeth damaged by cavities with restorative material.',
		},
		{
			id: '02',
			title: 'Root Canal Treatment',
			description: 'Procedure to save severely infected or damaged teeth.',
		},
		{
			id: '03',
			title: 'Tooth Extraction',
			description: 'Removal of damaged or impacted teeth.',
		},
		{
			id: '04',
			title: 'Dental Crowns',
			description: 'Protection for damaged or weakened teeth.',
		},
		{
			id: '05',
			title: 'Orthodontic Treatment (Braces)',
			description: 'Correction of misaligned teeth and bite issues.',
		},
		{
			id: '06',
			title: 'Cataract Surgery',
			description: 'Removal of cloudy lens and replacement with an artificial one.',
		},
		{
			id: '07',
			title: 'LASIK Eye Surgery',
			description: 'Refractive surgery to correct vision problems.',
		},
		{
			id: '08',
			title: 'Glaucoma Treatment',
			description: 'Management of high intraocular pressure and optic nerve damage.',
		},
		{
			id: '09',
			title: 'Retinal Detachment Surgery',
			description: 'Reattachment of the retina to restore vision.',
		},
		{
			id: '10',
			title: 'Corneal Transplant',
			description: 'Replacement of a damaged cornea with a healthy donor cornea.',
		},
		{
			id: '11',
			title: 'Blepharoplasty (Eyelid Surgery)',
			description: 'Cosmetic surgery to improve the appearance of the eyes.',
		},
		{
			id: '12',
			title: 'Dental Implants',
			description: 'Surgical procedure to replace missing teeth with artificial tooth roots.',
		},
		{
			id: '13',
			title: 'Teeth Whitening',
			description: 'Cosmetic treatment to lighten the color of teeth.',
		},
		{
			id: '14',
			title: 'Dental Veneers',
			description: 'Thin shells used to improve the appearance of teeth.',
		},
		{
			id: '15',
			title: 'Dental Bridges',
			description: 'Restoration of missing teeth using dental prosthetics.',
		},
		{
			id: '16',
			title: 'Gum Disease Treatment',
			description: 'Management of periodontal disease and gingivitis.',
		},
		{
			id: '17',
			title: 'Dentures',
			description: 'Removable dental appliances for missing teeth.',
		},
		{
			id: '18',
			title: 'Tooth Sealants',
			description: 'Protective coatings applied to prevent tooth decay.',
		},
		{
			id: '19',
			title: 'Oral Surgery',
			description: 'Surgical procedures for various oral conditions.',
		},
		{
			id: '20',
			title: 'Eye Exam',
			description: 'Comprehensive examination of the eyes.',
		},
		{
			id: '21',
			title: 'Contact Lens Fitting',
			description: 'Evaluation and fitting of contact lenses.',
		},
		{
			id: '22',
			title: 'Laser Eye Surgery',
			description: 'Various laser procedures to correct vision.',
		},
		{
			id: '23',
			title: 'Glaucoma Medications',
			description: 'Prescription drugs to lower intraocular pressure.',
		},
		{
			id: '24',
			title: 'Retinal Laser Therapy',
			description: 'Laser treatment for retinal conditions.',
		},
		{
			id: '25',
			title: 'Eye Infections Treatment',
			description: 'Management of eye infections with medications.',
		},
		{
			id: '26',
			title: 'Corneal Ulcer Treatment',
			description: 'Therapy for corneal ulcers and infections.',
		},
		{
			id: '27',
			title: 'Macular Degeneration Therapy',
			description: 'Treatment for age-related macular degeneration.',
		},
		{
			id: '28',
			title: 'Pediatric Eye Care',
			description: 'Eye care for children and adolescents.',
		},
		{
			id: '29',
			title: 'Eyelid Inflammation Treatment',
			description: 'Management of eyelid inflammation and conditions.',
		},
		{
			id: '30',
			title: 'Ocular Allergy Treatment',
			description: 'Management of eye allergies with medications.',
		},
		{
			id: '31',
			title: 'Color Vision Testing',
			description: 'Evaluation of color vision perception and deficiencies.',
		},
	],
	FR: [
		{
			id: '00',
			title: 'Détartrage Dentaire',
			description: 'Nettoyage de routine pour enlever la plaque et le tartre des dents.',
		},
		{
			id: '01',
			title: 'Obturations Dentaires',
			description:
				'Réparation des dents endommagées par les caries avec un matériau de restauration.',
		},
		{
			id: '02',
			title: 'Traitement Canalaire',
			description: 'Procédure pour sauver les dents sévèrement infectées ou endommagées.',
		},
		{
			id: '03',
			title: 'Extraction Dentaire',
			description: 'Retrait des dents endommagées ou impactées.',
		},
		{
			id: '04',
			title: 'Couronnes Dentaires',
			description: 'Protection des dents endommagées ou affaiblies.',
		},
		{
			id: '05',
			title: 'Traitement Orthodontique (Appareil Dentaire)',
			description: 'Correction des dents mal alignées et des problèmes de morsure.',
		},
		{
			id: '06',
			title: 'Chirurgie de la Cataracte',
			description: 'Retrait du cristallin trouble et remplacement par un cristallin artificiel.',
		},
		{
			id: '07',
			title: 'Chirurgie des Yeux au LASIK',
			description: 'Chirurgie réfractive pour corriger les problèmes de vision.',
		},
		{
			id: '08',
			title: 'Traitement du Glaucome',
			description: 'Gestion de la pression intraoculaire élevée et des lésions du nerf optique.',
		},
		{
			id: '09',
			title: 'Chirurgie de Détachement de la Rétine',
			description: 'Réattache de la rétine pour restaurer la vision.',
		},
		{
			id: '10',
			title: 'Transplantation Cornéenne',
			description: 'Remplacement de la cornée endommagée par une cornée de donneur en bonne santé.',
		},
		{
			id: '11',
			title: 'Blépharoplastie (Chirurgie des Paupières)',
			description: "Chirurgie esthétique pour améliorer l'apparence des yeux.",
		},
		{
			id: '12',
			title: 'Implants Dentaires',
			description:
				'Procédure chirurgicale pour remplacer les dents manquantes par des racines de dents artificielles.',
		},
		{
			id: '13',
			title: 'Blanchiment des Dents',
			description: 'Traitement esthétique pour éclaircir la couleur des dents.',
		},
		{
			id: '14',
			title: 'Facettes Dentaires',
			description: "Fines coquilles utilisées pour améliorer l'apparence des dents.",
		},
		{
			id: '15',
			title: 'Ponts Dentaires',
			description: "Restauration des dents manquantes à l'aide de prothèses dentaires.",
		},
		{
			id: '16',
			title: 'Traitement des Maladies des Gencives',
			description: 'Gestion des maladies parodontales et de la gingivite.',
		},
		{
			id: '17',
			title: 'Prothèses Dentaires',
			description: 'Appareils dentaires amovibles pour les dents manquantes.',
		},
		{
			id: '18',
			title: 'Scellements Dentaires',
			description: 'Revêtements protecteurs appliqués pour prévenir la carie dentaire.',
		},
		{
			id: '19',
			title: 'Chirurgie Buccale',
			description: 'Interventions chirurgicales pour diverses affections buccales.',
		},
		{
			id: '20',
			title: 'Examen des Yeux',
			description: 'Examen complet des yeux.',
		},
		{
			id: '21',
			title: 'Adaptation de Lentilles de Contact',
			description: 'Évaluation et adaptation de lentilles de contact.',
		},
		{
			id: '22',
			title: 'Chirurgie des Yeux au Laser',
			description: 'Diverses procédures au laser pour corriger la vision.',
		},
		{
			id: '23',
			title: 'Médicaments contre le Glaucome',
			description: 'Médicaments sur ordonnance pour abaisser la pression intraoculaire.',
		},
		{
			id: '24',
			title: 'Traitement au Laser de la Rétine',
			description: 'Traitement au laser pour les affections de la rétine.',
		},
		{
			id: '25',
			title: 'Traitement des Infections Oculaires',
			description: 'Gestion des infections oculaires avec des médicaments.',
		},
		{
			id: '26',
			title: 'Traitement des Ulcères Cornéens',
			description: 'Traitement des ulcères et infections de la cornée.',
		},
		{
			id: '27',
			title: 'Traitement de la Dégénérescence Maculaire',
			description: "Traitement de la dégénérescence maculaire liée à l'âge.",
		},
		{
			id: '28',
			title: 'Soins Oculaires Pédiatriques',
			description: 'Soins oculaires pour les enfants et les adolescents.',
		},
		{
			id: '29',
			title: "Traitement de l'Inflammation des Paupières",
			description: "Gestion de l'inflammation et des affections des paupières.",
		},
		{
			id: '30',
			title: 'Traitement des Allergies Oculaires',
			description: 'Gestion des allergies oculaires avec des médicaments.',
		},
		{
			id: '31',
			title: 'Test de Vision des Couleurs',
			description: 'Évaluation de la perception et des déficiences de la vision des couleurs.',
		},
	],
};

const data = [
	{ id: '13', price: 150, color: 'green', style: 'dashed' },
	{ id: '19', price: 500, color: 'purple', style: 'dotted' },
	{ id: '01', price: 150, color: 'red', style: 'simple' },
	{ id: '15', price: 600, color: 'orange', style: 'dashed' },
	{ id: '28', price: 100, color: 'blue', style: 'squared' },
	{ id: '12', price: 2000, color: 'blue', style: 'dashed' },
	{ id: '31', price: 175, color: 'orange', style: 'squared' },
	{ id: '10', price: 1200, color: 'pink', style: 'dashed' },
	{ id: '23', price: 100, color: 'orange', style: 'dotted' },
	{ id: '29', price: 200, color: 'green', style: 'squared' },
	{ id: '06', price: 1500, color: 'yellow', style: 'simple' },
	{ id: '26', price: 250, color: 'pink', style: 'squared' },
	{ id: '08', price: 250, color: 'white', style: 'dashed' },
	{ id: '05', price: 1000, color: 'green', style: 'simple' },
	{ id: '16', price: 300, color: 'white', style: 'dotted' },
	{ id: '24', price: 500, color: 'white', style: 'squared' },
	{ id: '25', price: 100, color: 'red', style: 'squared' },
	{ id: '03', price: 250, color: 'purple', style: 'simple' },
	{ id: '22', price: 1500, color: 'yellow', style: 'dotted' },
	{ id: '02', price: 300, color: 'pink', style: 'simple' },
	{ id: '27', price: 1325, color: 'purple', style: 'squared' },
	{ id: '04', price: 500, color: 'blue', style: 'simple' },
	{ id: '00', price: 100, color: 'white', style: 'simple' },
	{ id: '20', price: 400, color: 'blue', style: 'dotted' },
	{ id: '18', price: 200, color: 'pink', style: 'dotted' },
	{ id: '11', price: 750, color: 'purple', style: 'dashed' },
	{ id: '14', price: 800, color: 'yellow', style: 'dashed' },
	{ id: '07', price: 2000, color: 'orange', style: 'simple' },
	{ id: '09', price: 800, color: 'red', style: 'dashed' },
	{ id: '30', price: 100, color: 'yellow', style: 'squared' },
	{ id: '17', price: 1000, color: 'red', style: 'dotted' },
	{ id: '21', price: 100, color: 'green', style: 'dotted' },
];

export default {
	name: 'DeveloperTreatmentsGenerator',
	methods: {
		async generate(lang) {
			const $treatments = treatments[lang];
			for (let { id, price, color, style } of data) {
				const { title, description } = $treatments.find(({ id: $id }) => $id === id);
				const set = { title, description, price, color, style },
					payload = { set, push: false };
				await this.$store.dispatch('treatments/CREATE_TREATMENT', payload);
			}
			const currentRoute = this.$route.matched?.[0]?.path || this.$route.path;
			if (currentRoute === '/treatments') await this.$store.dispatch('treatments/SET_TREATMENTS');
		},
	},
};
