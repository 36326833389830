export default ({ app, $notify, $t }, inject) => {
	const useNotify = (type, message, options = {}) => {
		if (type === 'error' && app.$useRole('screencast')) return;
		if (type === 'error') message = message || $t('global.unknown-error');
		$notify[type]({
			resetOnHover: true,
			position: 'bottomLeft',
			transitionIn: 'fadeInRight',
			transitionOut: 'fadeOutLeft',
			// ? Why the zindex with this max value? this solves the issue of the IntercomMessenger component overlying the notifications
			zindex: 2147483647,
			...options,
			message,
		});
	};

	inject('useNotify', useNotify);
};
