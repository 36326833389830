
export default {
	name: 'PayloadLink',
	inheritAttrs: false,
	props: {
		inline: { type: Boolean, required: false },
		classes: { type: [String, Object], required: false },
		styles: { type: [String, Object], required: false },

		// Tooltip options (If not provided, no tooltip displayed).
		tooltip: { type: String, required: false },
		placement: { type: String, default: 'top' },

		// RouterLink 'to' param can accept a path string or { path, query } object.
		// Additionally, we supports a 'payload' attribute for passing it via the store. { path, query, payload }
		to: { type: [String, Object], required: false },
		target: { type: String, default: '_self' },

		// These props provide flexibility by allowing you to pass 'path', 'query' and 'payload' separately.
		path: { type: String, required: false },
		query: { type: Object, required: false },
		payload: { required: false }, // A new attribute that RouterLink don't support. (Refer to 'onClick' method)
	},
	computed: {
		link() {
			const path = this.path || this.to?.path || this.to,
				query = this.query || this.to?.query;
			if (!path) return false;
			return query ? { path, query } : { path };
		},
		name() {
			return this.inline ? 'span' : 'div';
		},
		simpleLink() {
			if (!this.link || this.target === '_self') return false;
			let { path, query } = this.link;
			if (!query) return path;
			query = Object.entries(query).map(([k, val]) => `${k}=${val}`);
			return `${this.link.path}?${query.join('&')}`;
		},
	},
	methods: {
		onClick() {
			// * The 'payload' meant to be passed to the next page via the store. But why ?
			// In a scenario like navigating from a patient's medical file to a new page that is requiring the patient's contacts:
			// - We pass the patient ID via the query ('/path?p=UUID') to fetch the patient contacts again (in asyncData for example) when opening the page in a new tab with a right-click.
			// - But upon a normal left-click, patient contacts are passed via the store, avoiding query execution since the output is already available, and it is the same as the patient contacts.
			const payload = this.payload !== undefined ? this.payload : this.to?.payload;
			if (payload !== undefined) this.$store.dispatch('STORE_PAYLOAD', payload);
		},
	},
};
