export default ({ app, $sentry }, inject) => {
	const files = {};

	/** Generates a File instance from a URL, JSON object, or Blob.
	 * - The file type is automatically determined based on the file extension in the provided name.
	 * - For URLs, a Promise is returned; otherwise, the File instance is returned directly.
	 * @param {string|Object|Blob} source - The source to create the file from: a URL string, a JSON object, or a Blob.
	 * @param {string} name - The name of the file, used for file naming and type detection (Extension should be included).
	 * @returns {File|Promise<File>} A File instance or a Promise that resolves to a File.
	 * @example
	 * const file1 = await this.$useFile('generate', 'https://...', 'logo.webp');
	 * const file2 = this.$useFile('generate', { blocks: [...] }, 'note.json');
	 */
	const generate = (source, name) => {
		const type = [
			['.webp', 'image/webp'],
			['.json', 'application/json'],
		].find(([extension]) => name.endsWith(extension))[1];
		if (source instanceof Blob) return new File([source], name, { type });
		else if (typeof source !== 'string') return new File([JSON.stringify(source)], name, { type });
		return new Promise(async (resolve) => {
			try {
				resolve(new File([await (await fetch(source)).blob()], name, { type }));
			} catch (e) {
				resolve(console.error(e));
			}
		});
	};

	/** Stores elements in the cache, which can be File instances, URLs, or file contents.
	 * - If `payload.url` is an empty string: the old URL will be revoked.
	 * - If `payload.file` is set but `payload.content` is absent: the content will be reset.
	 * @param {string} key - The key identifying the file data in the cache.
	 * @param {Object} payload - The data to store in the cache, which can include a file, content, or url.
	 * @example
	 * this.$useFile('set', clinic.logo_id, clinic.logo);
	 * this.$useFile('set', clinic.logo_id, { url: '' }); // To revoke the associated URL
	 * const file = this.$useFile('generate', note.editor_content, 'note.json');
	 * this.$useFile('set', note.filename, { file, content: note.editor_content });
	 */
	const setCache = (key, payload = {}) => {
		let { file, content, url } = payload instanceof File ? { file: payload } : payload;
		if (file instanceof File) {
			if (files[key]) files[key].file = file;
			else files[key] = { file };
			if (!url && files[key].url) url = URL.createObjectURL(file);
		}
		if (!files[key]) return;
		if (content !== undefined || file instanceof File) files[key].content = content;
		if (typeof url !== 'string') return;
		if (url && files[key]?.url) URL.revokeObjectURL(files[key].url);
		files[key].url = url;
	};

	/** Removes a specific file from the cache using its key. If the cached file has an associated URL, it will be revoked.
	 * @param {string} key - The key identifying the file data in the cache.
	 * @example
	 * this.$useFile('remove', note.filename);
	 */
	const removeCache = (key) => {
		if (files[key]?.url) URL.revokeObjectURL(files[key].url);
		delete files[key];
	};

	/** Retrieves a File instance from the cache. If the file is not found in the cache, it makes an Axios request to fetch the file, caches it, and then returns the file.
	 * @param {string} key - The key identifying the file data in the cache.
	 * @param {Object} payload - Additional data for the Axios request. The file name `payload.name` can be included for generating the file, but it won't be sent in the Axios request.
	 * @returns {File | Promise<File>} The cached File instance or a Promise that resolves to the File after fetching it via Axios.
	 * @example
	 * const file1 = await this.$useFile('getFile', clinic.logo_id, 'logo.webp');
	 * const file2 = await this.$useFile('getFile', note.filename, { name: 'note.json', module: 'patients', patientId });
	 */
	const getFile = (key, payload) => {
		if (files[key]?.file) return files[key].file;
		const name = typeof payload === 'string' ? payload : payload?.name || '';
		const module = payload?.module || 'general';
		payload = { objectId: key, ...(typeof payload === 'string' ? {} : payload), module };
		delete payload.name;
		return app.$useAxios?.('read', payload, async (data) => {
			if (!data.url) return;
			setCache(key, await generate(data.url, name));
			return files[key]?.file;
		});
	};

	/** Retrieves a URL representing the cached file.
	 * - If the file is not found in the cache, it uses `getFile` to fetch the file via Axios.
	 * - If the URL is not found in the cache, it generates it using `URL.createObjectURL`, and then cache it.
	 * @param {string} key - The key identifying the file data in the cache.
	 * @param {Object} payload - Additional data for the Axios request if the file is not cached yet. The file name `payload.name` can be included for generating the file, but it won't be sent in the Axios request.
	 * @returns {string | Promise<string>} The cached file URL or a Promise that resolves to the file URL after fetching it via Axios.
	 * @example
	 * // Use await and pass the payload if the file is not cached yet.
	 * const url1 = await this.$useFile('getURL', clinic.logo_id, 'logo.webp');
	 * // Get the URL directly if the file is cached.
	 * const url2 = this.$useFile('getURL', clinic.logo_id);
	 */
	const getURL = (key, file) => {
		if (files[key]?.url) return files[key].url;
		if (file instanceof Promise)
			return new Promise(async (resolve) => {
				file = await file;
				setCache(key, { url: file ? URL.createObjectURL(file) : '' });
				resolve(files[key]?.url || '');
			});
		setCache(key, { url: file ? URL.createObjectURL(file) : '' });
		return files[key]?.url || '';
	};

	/** Retrieves the content of the cached file.
	 * - If the file is not found in the cache, it uses `getFile` to fetch the file via Axios.
	 * - If the content is not found in the cache, it generates it using `FileReader`, parses it as JSON, and then caches it.
	 * @param {string} key - The key identifying the file data in the cache.
	 * @param {Object} payload - Additional data for the Axios request if the file is not cached yet. The file name `payload.name` can be included for generating the file, but it won't be sent in the Axios request.
	 * @returns {Object! | Promise<Object!>} The cached content or a Promise that resolves to the file content after fetching it via Axios or reading it via `FileReader`.
	 * @example
	 * // Use await and pass the payload if the file is not cached yet.
	 * const content1 = await this.$useFile('getContent', note.filename, { name: 'note.json', module: 'patients', patientId });
	 * // Use await without passing the payload if the file is cached but its content is not read yet.
	 * const content2 = await this.$useFile('getContent', note.filename);
	 * // Get the content directly if the file and its content are both cached.
	 * const content3 = this.$useFile('getContent', note.filename);
	 */
	const getContent = (key, file) => {
		if (files[key]?.content !== undefined) return files[key].content;
		return new Promise(async (resolve) => {
			file = await file;
			if (!file) return resolve();
			const reader = new FileReader();
			reader.onabort = () => resolve();
			reader.onerror = () => resolve();
			reader.onload = (event) => {
				try {
					setCache(key, { content: JSON.parse(event.target.result) });
					resolve(files[key]?.content);
				} catch (error) {
					resolve();
					$sentry.captureException(error);
				}
			};
			reader.readAsText(file);
		});
	};

	inject('useFile', (method, key, payload) => {
		if (method !== 'clear' && !key) return;
		else if (method === 'generate') return generate(key, payload?.name || payload);
		else if (method === 'set') setCache(key, payload);
		else if (method === 'remove') removeCache(key);
		else if (method === 'clear') Object.keys(files).forEach((key) => removeCache(key));
		else if (['getFile', 'getURL', 'getContent'].includes(method)) {
			const file = getFile(key, payload);
			if (method === 'getFile') return file;
			return method === 'getURL' ? getURL(key, file) : getContent(key, file);
		} else {
			$sentry.captureException(
				new Error(
					'Invalid method name: the first parameter should be either "set", "remove", "getFile", "getURL", "getContent" or "generate".'
				)
			);
			console.error(
				'Invalid method name: the first parameter should be either "set", "remove", "getFile", "getURL", "getContent" or "generate".'
			);
		}
	});
};
