
import { props as ButtonsProps } from '~/components/pop/DefaultButtons';

export const props = {
	// The buttons options.
	...ButtonsProps,
	buttons: { type: Boolean, default: true }, // The buttons existence. (If false, you can use the slot(name='buttons') to customize the bottom section).

	// The popup options.
	value: { type: Boolean, required: false }, // The popup visibility. (v-model)
	trigger: { type: String, default: 'click' }, // "click" | "focus" | "contextmenu" | "hover" | "long-hover"
	title: { type: String, required: false },
	popIcon: { type: String, required: false },
	placement: { type: String, default: 'top' },
	color: { type: String, default: '#faad14' },
	prefixClass: { type: String, required: false },
	closeOnMouseLeave: { type: Boolean, default: true },
};

export default {
	name: 'PopUp',
	emits: [
		/** If you are using any external button that mutate the visibility state, you should return them here to prevent closing the popup when clicking on them. (Refer to `methods.onWindowClick`)
		 * @type {() => HTMLElement | HTMLElement[] | VueComponent | VueComponent[]}
		 * @example PopUP(@external='() => $refs.myExternalButton')
		 */
		'external',
	],
	inheritAttrs: false,
	props,
	data() {
		return { visible: Boolean(this.value) };
	},
	methods: {
		set(state) {
			if (state) return (this.visible = true);
			const visible = this.visible;
			setTimeout(() => {
				if (this.visible && !visible) return;
				// Get all accepted elements considered as the current popup targets to remove the focusing state.
				let targets = this.$listeners.external?.(); // Refer to emits.external description above.
				targets = Array.isArray(targets) ? targets : [targets];
				targets = targets.concat([this.$refs.tooltip, this.$refs.content]);
				targets.forEach((p) => (p?.$el || p)?.blur?.());
				this.visible = false;
			}, 0.1);
		},
		close({ except: that } = {}) {
			if (that !== this) this.set(false);
		},
		onBlur() {
			if (this.trigger === 'focus') this.set(false);
		},
		buttonEvent(name = 'cancel') {
			this.set(false);
			setTimeout(() => this.$emit(name), 200);
		},
		onMouseLeave() {
			if (this.closeOnMouseLeave) this.set(false);
		},
		onWindowClick(event) {
			// Get all accepted element that we can click on them without closing the popup.
			let targets = this.$listeners.external?.(); // Refer to emits.external description above.
			targets = Array.isArray(targets) ? targets : [targets];
			targets = targets.concat([this.$refs.tooltip, this.$refs.content]);
			targets = targets.map((p) => p?.$el || p).filter(Boolean);
			try {
				// Check if the target is a child of one of the accepted targets to prevent hiding the popup.
				let child = event.target;
				while (child) {
					if (targets.includes(child)) return;
					child = child.parentNode;
				}
			} catch (error) {
				this.$sentry.captureException(error);
			}
			this.set(false);
		},
	},
	watch: {
		value(state) {
			if (state !== this.visible) this.set(Boolean(state));
		},
		visible(state) {
			if (state) {
				this.$nuxt.$emit('PopUp__closeAll', { except: this });
				this.$nuxt.$on('PopUp__closeAll', this.close);
				if (this.$listeners.external) window.addEventListener('click', this.onWindowClick);
			} else {
				this.$nuxt.$off('PopUp__closeAll', this.close);
				window.removeEventListener('click', this.onWindowClick);
			}
			this.$emit('input', state);
		},
	},
};
