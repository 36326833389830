export const Calendar = () => import('../../components/global/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const Confetti = () => import('../../components/global/Confetti.vue' /* webpackChunkName: "components/confetti" */).then(c => wrapFunctional(c.default || c))
export const DateTime = () => import('../../components/global/DateTime.vue' /* webpackChunkName: "components/date-time" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../components/global/Empty.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const ExportModal = () => import('../../components/global/ExportModal.vue' /* webpackChunkName: "components/export-modal" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/global/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const IntercomMessenger = () => import('../../components/global/IntercomMessenger.vue' /* webpackChunkName: "components/intercom-messenger" */).then(c => wrapFunctional(c.default || c))
export const Keyboard = () => import('../../components/global/Keyboard.vue' /* webpackChunkName: "components/keyboard" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/global/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/global/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/global/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PayloadLink = () => import('../../components/global/PayloadLink.vue' /* webpackChunkName: "components/payload-link" */).then(c => wrapFunctional(c.default || c))
export const PrintingSheetHeader = () => import('../../components/global/PrintingSheetHeader.vue' /* webpackChunkName: "components/printing-sheet-header" */).then(c => wrapFunctional(c.default || c))
export const Section = () => import('../../components/global/Section.vue' /* webpackChunkName: "components/section" */).then(c => wrapFunctional(c.default || c))
export const SpinnerDNA = () => import('../../components/global/SpinnerDNA.vue' /* webpackChunkName: "components/spinner-d-n-a" */).then(c => wrapFunctional(c.default || c))
export const StoreTable = () => import('../../components/global/StoreTable.vue' /* webpackChunkName: "components/store-table" */).then(c => wrapFunctional(c.default || c))
export const TrialBanner = () => import('../../components/global/TrialBanner.vue' /* webpackChunkName: "components/trial-banner" */).then(c => wrapFunctional(c.default || c))
export const UserSearchSelect = () => import('../../components/global/UserSearchSelect.vue' /* webpackChunkName: "components/user-search-select" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsAppointments = () => import('../../components/analytics/Appointments.vue' /* webpackChunkName: "components/analytics-appointments" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsCard = () => import('../../components/analytics/Card.vue' /* webpackChunkName: "components/analytics-card" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsCards = () => import('../../components/analytics/Cards.vue' /* webpackChunkName: "components/analytics-cards" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsDateRangeChart = () => import('../../components/analytics/DateRangeChart.vue' /* webpackChunkName: "components/analytics-date-range-chart" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsInvoicesQuotations = () => import('../../components/analytics/InvoicesQuotations.vue' /* webpackChunkName: "components/analytics-invoices-quotations" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsPatients = () => import('../../components/analytics/Patients.vue' /* webpackChunkName: "components/analytics-patients" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsPayments = () => import('../../components/analytics/Payments.vue' /* webpackChunkName: "components/analytics-payments" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsTreatments = () => import('../../components/analytics/Treatments.vue' /* webpackChunkName: "components/analytics-treatments" */).then(c => wrapFunctional(c.default || c))
export const AppointmentForm = () => import('../../components/appointment/Form.vue' /* webpackChunkName: "components/appointment-form" */).then(c => wrapFunctional(c.default || c))
export const AppointmentItem = () => import('../../components/appointment/Item.vue' /* webpackChunkName: "components/appointment-item" */).then(c => wrapFunctional(c.default || c))
export const AppointmentSection = () => import('../../components/appointment/Section.vue' /* webpackChunkName: "components/appointment-section" */).then(c => wrapFunctional(c.default || c))
export const DeveloperAppointmentsGenerator = () => import('../../components/developer/AppointmentsGenerator.vue' /* webpackChunkName: "components/developer-appointments-generator" */).then(c => wrapFunctional(c.default || c))
export const DeveloperCopyUserInfo = () => import('../../components/developer/CopyUserInfo.vue' /* webpackChunkName: "components/developer-copy-user-info" */).then(c => wrapFunctional(c.default || c))
export const DeveloperDevtool = () => import('../../components/developer/Devtool.vue' /* webpackChunkName: "components/developer-devtool" */).then(c => wrapFunctional(c.default || c))
export const DeveloperPatientsGenerator = () => import('../../components/developer/PatientsGenerator.vue' /* webpackChunkName: "components/developer-patients-generator" */).then(c => wrapFunctional(c.default || c))
export const DeveloperTreatmentsGenerator = () => import('../../components/developer/TreatmentsGenerator.vue' /* webpackChunkName: "components/developer-treatments-generator" */).then(c => wrapFunctional(c.default || c))
export const DeveloperMixin = () => import('../../components/developer/mixin.js' /* webpackChunkName: "components/developer-mixin" */).then(c => wrapFunctional(c.default || c))
export const FormDatePicker = () => import('../../components/form/DatePicker.vue' /* webpackChunkName: "components/form-date-picker" */).then(c => wrapFunctional(c.default || c))
export const FormEditor = () => import('../../components/form/Editor.vue' /* webpackChunkName: "components/form-editor" */).then(c => wrapFunctional(c.default || c))
export const FormFile = () => import('../../components/form/File.vue' /* webpackChunkName: "components/form-file" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../components/form/Input.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormLabel = () => import('../../components/form/Label.vue' /* webpackChunkName: "components/form-label" */).then(c => wrapFunctional(c.default || c))
export const FormRangePicker = () => import('../../components/form/RangePicker.vue' /* webpackChunkName: "components/form-range-picker" */).then(c => wrapFunctional(c.default || c))
export const FormSearchSelect = () => import('../../components/form/SearchSelect.vue' /* webpackChunkName: "components/form-search-select" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../../components/form/Select.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const FormTimePicker = () => import('../../components/form/TimePicker.vue' /* webpackChunkName: "components/form-time-picker" */).then(c => wrapFunctional(c.default || c))
export const IconArrowDown = () => import('../../components/icon/ArrowDown.vue' /* webpackChunkName: "components/icon-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const IconArrowUp = () => import('../../components/icon/ArrowUp.vue' /* webpackChunkName: "components/icon-arrow-up" */).then(c => wrapFunctional(c.default || c))
export const IconBell = () => import('../../components/icon/Bell.vue' /* webpackChunkName: "components/icon-bell" */).then(c => wrapFunctional(c.default || c))
export const IconCalendar = () => import('../../components/icon/Calendar.vue' /* webpackChunkName: "components/icon-calendar" */).then(c => wrapFunctional(c.default || c))
export const IconCircularArrow = () => import('../../components/icon/CircularArrow.vue' /* webpackChunkName: "components/icon-circular-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconHospital = () => import('../../components/icon/Hospital.vue' /* webpackChunkName: "components/icon-hospital" */).then(c => wrapFunctional(c.default || c))
export const IconInvoices = () => import('../../components/icon/Invoices.vue' /* webpackChunkName: "components/icon-invoices" */).then(c => wrapFunctional(c.default || c))
export const IconNotFound = () => import('../../components/icon/NotFound.vue' /* webpackChunkName: "components/icon-not-found" */).then(c => wrapFunctional(c.default || c))
export const IconRevenue = () => import('../../components/icon/Revenue.vue' /* webpackChunkName: "components/icon-revenue" */).then(c => wrapFunctional(c.default || c))
export const IconSend = () => import('../../components/icon/Send.vue' /* webpackChunkName: "components/icon-send" */).then(c => wrapFunctional(c.default || c))
export const IconUsersPlus = () => import('../../components/icon/UsersPlus.vue' /* webpackChunkName: "components/icon-users-plus" */).then(c => wrapFunctional(c.default || c))
export const InvoiceForm = () => import('../../components/invoice/Form.vue' /* webpackChunkName: "components/invoice-form" */).then(c => wrapFunctional(c.default || c))
export const InvoiceGeneralInfo = () => import('../../components/invoice/GeneralInfo.vue' /* webpackChunkName: "components/invoice-general-info" */).then(c => wrapFunctional(c.default || c))
export const InvoiceItem = () => import('../../components/invoice/Item.vue' /* webpackChunkName: "components/invoice-item" */).then(c => wrapFunctional(c.default || c))
export const InvoiceItems = () => import('../../components/invoice/Items.vue' /* webpackChunkName: "components/invoice-items" */).then(c => wrapFunctional(c.default || c))
export const InvoicePayment = () => import('../../components/invoice/Payment.vue' /* webpackChunkName: "components/invoice-payment" */).then(c => wrapFunctional(c.default || c))
export const InvoicePayments = () => import('../../components/invoice/Payments.vue' /* webpackChunkName: "components/invoice-payments" */).then(c => wrapFunctional(c.default || c))
export const InvoicePrintingSheet = () => import('../../components/invoice/PrintingSheet.vue' /* webpackChunkName: "components/invoice-printing-sheet" */).then(c => wrapFunctional(c.default || c))
export const InvoiceStatus = () => import('../../components/invoice/Status.vue' /* webpackChunkName: "components/invoice-status" */).then(c => wrapFunctional(c.default || c))
export const InvoiceTable = () => import('../../components/invoice/Table.vue' /* webpackChunkName: "components/invoice-table" */).then(c => wrapFunctional(c.default || c))
export const PatientForm = () => import('../../components/patient/Form.vue' /* webpackChunkName: "components/patient-form" */).then(c => wrapFunctional(c.default || c))
export const PatientNoteForm = () => import('../../components/patient/NoteForm.vue' /* webpackChunkName: "components/patient-note-form" */).then(c => wrapFunctional(c.default || c))
export const PatientNotesTable = () => import('../../components/patient/NotesTable.vue' /* webpackChunkName: "components/patient-notes-table" */).then(c => wrapFunctional(c.default || c))
export const PatientSearchSelect = () => import('../../components/patient/SearchSelect.vue' /* webpackChunkName: "components/patient-search-select" */).then(c => wrapFunctional(c.default || c))
export const PopButton = () => import('../../components/pop/Button.vue' /* webpackChunkName: "components/pop-button" */).then(c => wrapFunctional(c.default || c))
export const PopDefaultButtons = () => import('../../components/pop/DefaultButtons.vue' /* webpackChunkName: "components/pop-default-buttons" */).then(c => wrapFunctional(c.default || c))
export const PopDelete = () => import('../../components/pop/Delete.vue' /* webpackChunkName: "components/pop-delete" */).then(c => wrapFunctional(c.default || c))
export const PopReset = () => import('../../components/pop/Reset.vue' /* webpackChunkName: "components/pop-reset" */).then(c => wrapFunctional(c.default || c))
export const PopUp = () => import('../../components/pop/Up.vue' /* webpackChunkName: "components/pop-up" */).then(c => wrapFunctional(c.default || c))
export const SettingsCountry = () => import('../../components/settings/Country.vue' /* webpackChunkName: "components/settings-country" */).then(c => wrapFunctional(c.default || c))
export const SettingsDateTime = () => import('../../components/settings/DateTime.vue' /* webpackChunkName: "components/settings-date-time" */).then(c => wrapFunctional(c.default || c))
export const SettingsGeneral = () => import('../../components/settings/General.vue' /* webpackChunkName: "components/settings-general" */).then(c => wrapFunctional(c.default || c))
export const SettingsManageTeam = () => import('../../components/settings/ManageTeam.vue' /* webpackChunkName: "components/settings-manage-team" */).then(c => wrapFunctional(c.default || c))
export const SettingsManageTeamHeader = () => import('../../components/settings/ManageTeamHeader.vue' /* webpackChunkName: "components/settings-manage-team-header" */).then(c => wrapFunctional(c.default || c))
export const SettingsNumbersFinance = () => import('../../components/settings/NumbersFinance.vue' /* webpackChunkName: "components/settings-numbers-finance" */).then(c => wrapFunctional(c.default || c))
export const SettingsTaxesSection = () => import('../../components/settings/TaxesSection.vue' /* webpackChunkName: "components/settings-taxes-section" */).then(c => wrapFunctional(c.default || c))
export const SettingsTimezone = () => import('../../components/settings/Timezone.vue' /* webpackChunkName: "components/settings-timezone" */).then(c => wrapFunctional(c.default || c))
export const TreatmentForm = () => import('../../components/treatment/Form.vue' /* webpackChunkName: "components/treatment-form" */).then(c => wrapFunctional(c.default || c))
export const TreatmentSearchSelect = () => import('../../components/treatment/SearchSelect.vue' /* webpackChunkName: "components/treatment-search-select" */).then(c => wrapFunctional(c.default || c))
export const WaitingRoomHeader = () => import('../../components/waiting-room/Header.vue' /* webpackChunkName: "components/waiting-room-header" */).then(c => wrapFunctional(c.default || c))
export const WaitingRoomPatient = () => import('../../components/waiting-room/Patient.vue' /* webpackChunkName: "components/waiting-room-patient" */).then(c => wrapFunctional(c.default || c))
export const WaitingRoomPlaylist = () => import('../../components/waiting-room/Playlist.vue' /* webpackChunkName: "components/waiting-room-playlist" */).then(c => wrapFunctional(c.default || c))
export const WaitingRoomQueue = () => import('../../components/waiting-room/Queue.vue' /* webpackChunkName: "components/waiting-room-queue" */).then(c => wrapFunctional(c.default || c))
export const WaitingRoomScreencast = () => import('../../components/waiting-room/Screencast.vue' /* webpackChunkName: "components/waiting-room-screencast" */).then(c => wrapFunctional(c.default || c))
export const WaitingRoomScreencastActivation = () => import('../../components/waiting-room/ScreencastActivation.vue' /* webpackChunkName: "components/waiting-room-screencast-activation" */).then(c => wrapFunctional(c.default || c))
export const WaitingRoomVideo = () => import('../../components/waiting-room/Video.vue' /* webpackChunkName: "components/waiting-room-video" */).then(c => wrapFunctional(c.default || c))
export const WaitingRoomWelcomeModal = () => import('../../components/waiting-room/WelcomeModal.vue' /* webpackChunkName: "components/waiting-room-welcome-modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
