
export default {
	name: 'DeveloperDevtool',
	data() {
		return {
			popupVisibility: false,
			modalVisibility: false,
		};
	},
	computed: {
		currentPatient() {
			return this.$store.getters['patients/GET_PATIENT'];
		},
	},
	methods: {
		setModalVisibility(status) {
			this.$refs.popupButton.$el.blur();
			this.modalVisibility = status;
		},
	},
	created() {
		setTimeout(() => {
			this.popupVisibility = true;
			setTimeout(() => (this.popupVisibility = false), 2000);
		}, 1000);
	},
};
