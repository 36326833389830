
export const props = {
	buttonsVertical: { type: Boolean, default: false }, // Applied only on the default buttons. You can't use it with the buttons slot.
	buttonsReverse: { type: Boolean, default: false }, // Applied only on the default buttons. You can't use it with the buttons slot.

	// The ok button.
	ok: { type: [Object, Boolean], default: true }, // A shorthand object of { text, icon, type }
	okText: { type: [String, Boolean], default: '' },
	okType: { type: String, required: false },
	okIcon: { type: String, required: false },
	okLink: { type: [String, Object], required: false }, // A shorthand of what "PayloadLink.$props.to" accept (path string or { path, payload, query })

	// The secondary button.
	sec: { type: [Object, Boolean], default: false }, // A shorthand object of { text, icon, type }
	secText: { type: [String, Boolean], default: '' },
	secType: { type: String, required: false },
	secIcon: { type: String, required: false },
	secLink: { type: [String, Object], required: false }, // A shorthand of what "PayloadLink.$props.to" accept (path string or { path, payload, query })

	// The cancel button.
	cancel: { type: [Object, Boolean], default: true }, // A shorthand object of { text, icon, type }
	cancelText: { type: [String, Boolean], default: '' },
	cancelIcon: { type: String, required: false },
	cancelType: { type: String, required: false },
};

export default {
	name: 'PopDefaultButtons',
	inheritAttrs: false,
	props: {
		...props,
		size: undefined,
	},
	computed: {
		buttonsContainerClass() {
			const axis = this.buttonsVertical ? 'col' : 'row';
			return `flex-${axis}${this.buttonsReverse ? '-reverse' : ''}`;
		},
		button() {
			return { class: { 'w-full': this.buttonsVertical }, size: this.size };
		},
		okButton() {
			if (this.ok === false) return false;
			const props = {
				...this.button,
				text: this.ok?.text || this.okText || this.$t('global.yes'),
				type: this.ok?.type || this.okType || 'primary',
				icon: this.ok?.icon || this.okIcon || undefined,
			};
			props.type = props.type === 'normal' ? undefined : props.type;
			return props;
		},
		secButton() {
			if (this.sec === false) return false;
			return {
				...this.button,
				text: this.sec?.text || this.secText,
				type: this.sec?.type || this.secType || undefined,
				icon: this.sec?.icon || this.secIcon || undefined,
			};
		},
		cancelButton() {
			if (this.cancel === false) return false;
			return {
				...this.button,
				text: this.cancel?.text || this.cancelText || this.$t('actions.cancel'),
				type: this.cancel?.type || this.cancelType || undefined,
				icon: this.cancel?.icon || this.cancelIcon || undefined,
			};
		},
	},
};
