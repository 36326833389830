export default ({ $cookies }, inject) => {
	const useCookie = (name) => {
		return $cookies.get(name);
	};

	const useSetCookie = (name, value, must_exp = true, options = {}) => {
		const maxAge = must_exp ? 7 * 24 * 60 * 60 : 31 * 24 * 60 * 60;
		$cookies.set(name, value, { maxAge, ...options });
	};

	const useRemoveCookie = (name) => {
		$cookies.remove(name);
	};

	inject('useCookie', useCookie);
	inject('useSetCookie', useSetCookie);
	inject('useRemoveCookie', useRemoveCookie);
};
