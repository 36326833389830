
import mixin from './mixin';

export default {
	name: 'DeveloperAppointmentsGenerator',
	mixins: [mixin],
	methods: {
		async generate() {
			this.patientError = false;
			this.treatmentError = false;
			const dates = this.getDates('days', this.number),
				time = this.getDates('hours', this.number),
				patients = await this.getPatients(this.number),
				treatments = await this.getTreatments();
			if (this.patientError || this.treatmentError) return;

			for (let i = 0; i < this.number - 1; i++) {
				const from = this.$toUTC(dates[i], time[i][0], undefined, false),
					to = this.$toUTC(dates[i], time[i][1], undefined, false),
					set = {
						date_from: from[0],
						time_from: from[1],
						date_to: to[0],
						time_to: to[1],
						patient_id: patients[i],
					},
					number = Math.round(this.number / 3);
				set.description = '';
				if (i >= number) {
					const $i = number + (i - number) * 2;
					set.treatments = [treatments[$i], treatments[$i + 1]];
				} else set.treatments = [treatments[i]];
				set.assignees = [];
				await this.$store.dispatch('appointments/CREATE_APPOINTMENT', { set });
			}

			const route = this.$route.matched?.[0]?.path || this.$route.path;
			if (this.$store.getters.GET_SUBSCRIPTIONS.appointments === undefined)
				if (route === '/patients/:id') {
					await this.$store.dispatch('appointments/SET_APPOINTMENTS', {
						patient: true,
						view: 'table',
					});
				} else if (route === '/appointments') {
					await this.$store.dispatch('appointments/SET_APPOINTMENTS', { view: 'table' });
				}
		},
	},
};
