
// ! When clearing the input programmatically, reset the entire value.
// parentContext.selectedOption = { value: '' | 0 | [], valid: true }
// parentContext.selectedOption = '' | 0 | []

import input from '~/mixins/input';

export default {
	name: 'FormSelect',
	mixins: [input],
	props: {
		value: { type: [Object, String, Number, Array], default: '' },
		disabledValue: { type: [String, Number, Array], required: false },
		options: { type: Array, required: false },
		groups: { type: Array, required: false },
		allowClear: { type: Boolean, default: false },
		searchFilter: { type: Boolean, default: false },
		popupPlacement: { type: String, required: false },
	},
	data() {
		return { searchQuery: '' };
	},
	computed: {
		componentProps() {
			const options = { is: this.mode === 'cascade' ? 'a-cascader' : 'a-select' };
			if (this.popupPlacement) options.popupPlacement = this.popupPlacement;
			if (this.mode === 'multiple') options.mode = 'multiple';
			if (this.searchFilter) {
				options.filterOption = false;
				options.showSearch = true;
				options.autoClearSearchValue = true;
			}
			if (this.mode === 'cascade') {
				options.fieldNames = { label: 'label', value: 'value', children: 'options' };
				options.options = this.renderedGroups || [];
				if (this.searchFilter)
					options.showSearch = {
						filter: (qr, op) => op.some((it) => it.label.toLowerCase().includes(qr.toLowerCase())),
					};
			}
			return options;
		},
		formattedOptions() {
			if (this.mode === 'cascade') return undefined;
			return this.options?.map(this.formatOption);
		},
		formattedGroups() {
			if (this.formattedOptions) return undefined;
			return this.groups?.map((group) => ({
				label: group.label,
				value: group.value,
				options: group.options?.map(this.formatOption) || [],
			}));
		},
		renderedOptions() {
			if (this.mode === 'cascade' || !this.formattedOptions) return [];
			if (!this.searchFilter || !this.searchQuery) return this.formattedOptions;
			return this.filterOptions(this.formattedOptions);
		},
		renderedGroups() {
			if (!this.formattedGroups || !this.searchFilter || !this.searchQuery)
				return this.formattedGroups;
			const groups = this.formattedGroups.map((group) => {
				const options = this.filterOptions(group.options);
				if (options.length) return { ...group, options };
			});
			return groups.filter(Boolean);
		},
		values() {
			const array =
				this.mode === 'cascade'
					? this.formattedGroups?.map((g) => g.options.map((op) => `${g.value}/${op.value}`))
					: this.formattedOptions?.map((option) => option.value) ||
					  this.formattedGroups?.map(({ options }) => options.map((o) => o.value));
			return array?.flat?.() || [];
		},
	},
	methods: {
		getModes() {
			return ['select', 'multiple', 'cascade'];
		},
		getMode(value) {
			return Array.isArray(value) ? 'multiple' : 'select';
		},
		clone(value, mode, defaultVal) {
			if (!this.values) return value;
			if (defaultVal) defaultVal = defaultVal.value;
			else defaultVal = mode === 'cascade' ? [] : typeof this.values[0] === 'number' ? 0 : '';
			if (mode === 'select') return this.values.includes(value) ? value : defaultVal;
			value = (Array.isArray(value) ? value : [value]).filter(Boolean);
			if (mode === 'cascade') return this.values.includes(value.join('/')) ? value : defaultVal;
			return value.map((it) => (this.values.includes(it) ? it : defaultVal)).filter(Boolean);
		},
		areEqual(value, value2, mode) {
			if (mode === 'select') return value === value2;
			if (typeof value !== typeof value2 || value?.length !== value2?.length) return false;
			return value?.every((v, i) => v === value2[i]);
		},
		formatValue(value, mode) {
			return this.clone(value, mode, { value: undefined });
		},
		parseValue(value) {
			return value;
		},
		onSearch(value) {
			if (this.mode !== 'cascade') this.searchQuery = value.trim().toLowerCase();
		},
		onBlur() {
			if (this.mode !== 'cascade') this.searchQuery = '';
		},
		formatOption(option) {
			option = typeof option !== 'object' || !option ? { value: option } : { ...option };
			if (!['string', 'number'].includes(typeof option.value))
				option.value = ['string', 'number'].includes(typeof option.id) ? option.id : '';
			option.label = this.$listeners.formatter?.(option) || option.label || `${option.value}`;
			if (this.searchFilter)
				option.search = (option.search || []).concat(option.label).map((val) => val.toLowerCase());
			return option;
		},
		filterOptions(options) {
			return options.filter((option) => {
				if (option.value === this.internal.value) return true;
				return (option.search || []).some((val) => val.includes(this.searchQuery));
			});
		},
		getSlotOption({ labels, selectedOptions }) {
			const value = selectedOptions.map((option) => option.value);
			return { labels, value, options: selectedOptions };
		},
	},
};
