import AppointmentsSubscription from '~/apollo/subscriptions/appointments';
import AppointmentsDates from '~/apollo/queries/appointments/dates';
import PaginateAppointments from '~/apollo/queries/appointments/paginate';
import PaginatePatientAppointments from '~/apollo/queries/appointments/paginate-patient';
import AppointmentsSearch from '~/apollo/queries/appointments/all';
import AppointmentRelationsQuery from '~/apollo/queries/appointments/relations';
import AppointmentRelationsIdsQuery from '~/apollo/queries/appointments/relations-id';
import AddAppointment from '~/apollo/mutations/appointments/add';
import UpdateAppointment from '~/apollo/mutations/appointments/update';
import UpdateAppointmentDates from '~/apollo/mutations/appointments/update-dates';
import DeleteAppointment from '~/apollo/mutations/appointments/delete';

import { PARSE_PATIENT } from '../patients';
import { PARSE_TREATMENT } from '../treatments';

const PARSE = (appointment, { id, $$moment }) => {
	appointment.id = appointment.id || id;
	appointment.date_to = appointment.date_to || appointment.date_from;
	appointment.time_from = $$moment(appointment.time_from, 'HH:mm').format('HH:mm');
	appointment.time_to = appointment.time_to
		? $$moment(appointment.time_to, 'HH:mm').format('HH:mm')
		: $$moment(appointment.time_from, 'HH:mm').add(5, 'minutes').format('HH:mm');
	appointment.description = appointment.description || '';
	appointment.patient = appointment.patient ? PARSE_PATIENT(appointment.patient) : {};
	appointment.treatments =
		appointment.treatments?.map((treatment, key) => PARSE_TREATMENT(treatment, { key })) || [];
	delete appointment.__typename;
	return appointment;
};

export { PARSE as PARSE_APPOINTMENT };

export const state = () => ({
	pagination: { current: 1, limit: 25, total: 0 },
	filters: {},
	patientFilters: {},
	appointments: [],
	patientAppointments: [],
});

export const getters = {
	GET_TABLES_COLUMNS() {
		return [
			{
				slot: 'patient',
				title: 'patient-records.patient',
				conditional: true, // Visible in the appointments page but not visible in someone's medical file
			},
			{ slot: 'date', title: 'dates.date' },
			{ slot: 'from', title: 'dates.from' },
			{ slot: 'to', title: 'dates.to' },
			{ data: 'description', title: 'global.description' },
			{ slot: 'treatments', title: 'treatments.treatments' },
		];
	},
	GET_PAGINATION({ pagination }) {
		return pagination;
	},
	GET_FILTERS({ filters }) {
		return filters;
	},
	GET_PATIENT_FILTERS({ patientFilters }) {
		return patientFilters;
	},
	GET_APPOINTMENTS({ appointments }) {
		return appointments;
	},
	GET_PATIENT_APPOINTMENTS({ patientAppointments }) {
		return patientAppointments;
	},
};

export const actions = {
	async SET_APPOINTMENTS({ getters, commit }, { patient, view }) {
		let query = patient ? PaginatePatientAppointments : PaginateAppointments;
		if (view !== 'table') query = view === 'month' ? AppointmentsDates : AppointmentsSubscription;

		const where = getters[patient ? 'GET_PATIENT_FILTERS' : 'GET_FILTERS'],
			{ current, limit } = getters['GET_PAGINATION'],
			filters = view === 'table' ? { offset: (current - 1) * limit, limit, where } : { where };

		const onSuccess = ({ appointments, appointments_aggregate }) => {
			const onEach = (it, id) => PARSE(it, { id, $$moment: this.$$moment });
			commit(patient ? 'SET_PATIENT_APPOINTMENTS' : 'SET_APPOINTMENTS', appointments.map(onEach));
			commit('SET_PAGINATION', { total: appointments_aggregate?.aggregate?.count || 0 });
		};

		if (view !== 'table' && view !== 'month')
			return this.$useSubscription('appointments', query, filters, onSuccess);
		await this.$useCloseSubscription('appointments');
		await this.$useQuery(query, filters, onSuccess);
	},
	async SET_SEARCH_APPOINTMENTS(_, patient_id) {
		const [date, time] = this.$toUTC(this.$$moment().format('YYYY-MM-DD'), '23:59', true);
		const where = {
			patient_id: { _eq: patient_id },
			_or: [{ date_from: { _lt: date } }, { date_from: { _eq: date }, time_from: { _lte: time } }],
		};
		return await this.$useQuery(AppointmentsSearch, { where, limit: 5 }, ({ appointments }) => {
			return appointments.map((it) => PARSE(it, this));
		});
	},
	async GET_APPOINTMENTS_USERS_AND_TREATMENTS({ commit }, id) {
		return await this.$useQuery(AppointmentRelationsQuery, { id }, (data) => {
			const users = data.users_appointments.map((u) => PARSE_PATIENT(u.user));
			const treatments = data.appointments_treatments.map(PARSE_TREATMENT);
			commit('team/SET_SEARCH_TEAM', users, { root: true });
			commit('treatments/SET_SEARCH_TREATMENTS', treatments, { root: true });
			return { users: users.map(({ id }) => id), treatments: treatments.map(({ id }) => id) };
		});
	},
	async CREATE_APPOINTMENT({ rootGetters, dispatch }, { set, refresh }) {
		set.assignees = { data: set.assignees.map((id) => ({ user_id: id })) };
		set.treatments = { data: set.treatments.map((id) => ({ treatment_id: id })) };
		return await this.$useMutation(AddAppointment, { object: set }, async (data) => {
			this.$useBindAction('created', 'appointments.the-appointment');
			if (refresh && rootGetters.GET_SUBSCRIPTIONS.appointments === undefined)
				await dispatch('SET_APPOINTMENTS', refresh);
			return { ...set, id: data.insert_appointments_one.id };
		});
	},
	async UPDATE_APPOINTMENT({ rootGetters, dispatch }, { id, set, refresh }) {
		const payload = { id, set: { ...set } },
			onSuccess = async (data) => {
				this.$useBindAction('updated', 'appointments.the-appointment');
				if (refresh && rootGetters.GET_SUBSCRIPTIONS.appointments === undefined)
					await dispatch('SET_APPOINTMENTS', refresh);
				return { ...set, id: data.update_appointments_by_pk.id };
			};
		delete payload.set.assignees, delete payload.set.treatments;
		if (!set.treatments) return this.$useMutation(UpdateAppointmentDates, payload, onSuccess);

		return await this.$useQuery(AppointmentRelationsIdsQuery, { id }, async (data) => {
			// Fetch the current lists from the database to determine which assignees and treatments need addition or deletion.
			payload.usersToAdd = set.assignees
				.filter((id) => !data.users_appointments.some((el) => el.user_id === id))
				.map((u) => ({ appointment_id: id, user_id: u }));
			payload.usersToKeep = data.users_appointments
				.filter((el) => set.assignees.includes(el.user_id))
				.map((el) => el.id);

			const treatments = set.treatments.map((t) => t.replace('_disabled_', ''));
			payload.treatmentsToAdd = treatments
				.filter((id) => !data.appointments_treatments.some((el) => el.treatment_id === id))
				.map((t) => ({ appointment_id: id, treatment_id: t }));
			payload.treatmentsToKeep = data.appointments_treatments
				.filter((el) => treatments.includes(el.treatment_id))
				.map((el) => el.id);

			return await this.$useMutation(UpdateAppointment, payload, onSuccess);
		});
	},
	async DELETE_APPOINTMENT({ rootGetters, dispatch }, { id, refresh }) {
		await this.$useMutation(DeleteAppointment, { id }, async () => {
			this.$useBindAction('deleted', 'appointments.the-appointment');
			if (refresh && rootGetters.GET_SUBSCRIPTIONS.appointments === undefined)
				await dispatch('SET_APPOINTMENTS', refresh);
		});
	},
};

export const mutations = {
	SET_PAGINATION(state, payload) {
		state.pagination = { ...state.pagination, ...payload };
	},
	SET_FILTERS(state, payload) {
		state.filters = payload;
	},
	SET_PATIENT_FILTERS(state, payload) {
		state.patientFilters = payload;
	},
	SET_APPOINTMENTS(state, payload) {
		state.appointments = payload;
	},
	SET_PATIENT_APPOINTMENTS(state, payload) {
		state.patientAppointments = payload;
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
