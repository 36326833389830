export default ({ $cookies }, inject) => {
	const tokenKeyName = 'auth_token';

	const useToken = () => {
		return $cookies.get(tokenKeyName);
	};

	const useSetToken = (token, must_exp = true, options = {}) => {
		const maxAge = must_exp ? 7 * 24 * 60 * 60 : 31 * 24 * 60 * 60;
		$cookies.set(tokenKeyName, token, { maxAge, ...options });
	};

	const useRemoveToken = () => {
		$cookies.remove(tokenKeyName);
	};

	inject('useToken', useToken);
	inject('useSetToken', useSetToken);
	inject('useRemoveToken', useRemoveToken);
};
