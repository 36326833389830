import PaginateInvoices from '~/apollo/queries/invoices/paginate';
import PaginatePatientInvoices from '~/apollo/queries/invoices/paginate-patient';
import InvoiceQuery from '~/apollo/queries/invoices/one';
import CreateInvoice from '~/apollo/mutations/invoices/create';
import UpdateInvoice from '~/apollo/mutations/invoices/update';
import DeleteInvoice from '~/apollo/mutations/invoices/delete';
import ItemsPaymentsQuery from '~/apollo/queries/invoices/items-payments';
import UpdateItem from '~/apollo/mutations/invoices/update-item';
import UpdatePayment from '~/apollo/mutations/invoices/update-payment';

import { gql } from '@apollo/client/core';
import { PARSE_PATIENT } from '../patients';
import { PARSE_TREATMENT } from '../treatments';

const PARSE = (invoice, { id, moment } = {}) => {
	invoice.id = invoice.id || id;
	invoice.description = invoice.description || '';
	if (invoice.patient) invoice.patient = PARSE_PATIENT(invoice.patient);
	if (invoice.total <= invoice.rest) invoice.status = 'quotation';
	else invoice.status = invoice.rest > 0 ? 'partly-paid' : 'paid';
	invoice.items = invoice.items?.map((item, key) => {
		item.treatment = PARSE_TREATMENT(item, { key });
		const price =
			item.treatment.price * (1 + (item.treatment.tax?.value || 0) / 100) * item.quantity;
		item.total = price - (price * item.discount) / 100;
		delete item.__typename;
		return item;
	});
	invoice.payments = invoice.payments?.map((payment) => {
		payment.date = moment(payment.created_at).format('YYYY-MM-DD');
		delete payment.created_at, delete payment.__typename;
		return payment;
	});
	delete invoice.__typename;
	return invoice;
};

export const state = () => ({
	pagination: { current: 1, limit: 25, total: 0 },
	filters: {},
	patientFilters: {},
	invoice: null,
	invoices: [],
	patientInvoices: [],
});

export const getters = {
	GET_TABLES_COLUMNS() {
		return [
			{
				slot: 'patient',
				title: 'patient-records.patient',
				conditional: true, // Visible in the invoices page but not visible in someone's medical file
			},
			{ slot: 'date', title: 'dates.date' },
			{ slot: 'total', title: 'invoices.total' },
			{ slot: 'rest', title: 'invoices.rest' },
			{ slot: 'status', title: 'global.status' },
		];
	},
	GET_PAGINATION({ pagination }) {
		return pagination;
	},
	GET_FILTERS({ filters }) {
		return filters;
	},
	GET_PATIENT_FILTERS({ patientFilters }) {
		return patientFilters;
	},
	GET_INVOICE({ invoice }) {
		return invoice;
	},
	GET_INVOICES({ invoices }) {
		return invoices;
	},
	GET_PATIENT_INVOICES({ patientInvoices }) {
		return patientInvoices;
	},
};

const getType = (element) => {
	let isInvoice = element.payments?.length || element.payments?.data?.length;
	if (element.status) isInvoice = element.status !== 'quotation';
	return isInvoice ? 'invoices.the-invoice' : 'invoices.the-quotation';
};

export const actions = {
	async SET_INVOICE({ commit }, id) {
		await this.$useQuery(InvoiceQuery, { id }, async (data) => {
			const invoice = data.invoices_bills_by_pk;
			if (!invoice) return;
			const element = PARSE(invoice, { id, moment: this.$$moment }),
				patients = [element.patient],
				treatments = element.items.map((item) => item.treatment);
			commit('SET_INVOICE', element);
			commit('patients/SET_SEARCH_PATIENTS', patients, { root: true });
			commit('treatments/SET_SEARCH_TREATMENTS', treatments, { root: true });
		});
	},
	async SET_INVOICES({ getters, commit }) {
		const where = getters['GET_FILTERS'],
			{ current, limit } = getters['GET_PAGINATION'],
			filters = { offset: (current - 1) * limit, limit, where };
		await this.$useQuery(PaginateInvoices, filters, (data) => {
			commit('SET_PAGINATION', { total: data.invoices_bills_aggregate.aggregate.count });
			commit('SET_INVOICES', data.invoices_bills.map(PARSE));
		});
	},
	async SET_PATIENT_INVOICES({ getters, commit }) {
		const where = getters['GET_PATIENT_FILTERS'],
			{ current, limit } = getters['GET_PAGINATION'],
			filters = { offset: (current - 1) * limit, limit, where };
		await this.$useQuery(PaginatePatientInvoices, filters, (data) => {
			commit('SET_PAGINATION', { total: data.invoices_bills_aggregate.aggregate.count });
			commit('SET_PATIENT_INVOICES', data.invoices_bills.map(PARSE));
		});
	},
	async GET_EXPORTED_INVOICES(_, { columns, where }) {
		let cols = '';
		if (columns.includes('$id')) cols += '\nid';
		if (columns.includes('treatments'))
			cols += '\nitems(order_by: { created_at: asc }) {\n\ttreatment {\n\t\ttitle\n\t}\n}';
		if (['total', 'status'].some((c) => columns.includes(c))) cols += '\ntotal';
		if (['rest', 'status'].some((c) => columns.includes(c))) cols += '\nrest';
		if (columns.includes('$date')) cols += '\ndate';
		if (columns.includes('description')) cols += '\ndescription';
		if (columns.some((col) => col.startsWith('patient'))) {
			cols += '\npatient {\n\tcontact {\n';
			if (columns.includes('patient.full_name')) cols += '\t\tfull_name\n';
			cols += '\t}\n}';
		}
		const ExportedInvoices = gql`
			query ExportedInvoices($where: invoices_bills_bool_exp!) {
				invoices_bills(order_by: { date: asc }, where: $where) {
					${cols}
				}
			}
		`;
		return await this.$useQuery(ExportedInvoices, { where }, ({ invoices_bills }) => {
			return invoices_bills.map((invoice) => {
				invoice = PARSE(invoice, { moment: this.$$moment });
				invoice.$id = this.$useFormat('id', invoice.id || '');
				invoice.$date = this.$useFormat('date', invoice.date);
				invoice.status = this.$t(`invoices.${invoice.status}`);
				invoice.treatments = invoice.items?.map((item) => item.treatment.title)?.join(', ') || '';
				return invoice;
			});
		});
	},
	async CREATE_INVOICE({ rootGetters }, set) {
		const clinic_id = rootGetters['clinic/GET_CLINIC'].id;
		set.items = {
			data: set.items.map((item) => {
				delete item.id, delete item._data;
				return { ...item, clinic_id };
			}),
		};
		set.payments = {
			data: set.payments.map(({ amount, created_at }) => {
				return { amount, created_at, patient_id: set.patient_id };
			}),
		};
		await this.$useMutation(CreateInvoice, { object: set }, (data) => {
			const id = data.insert_invoices_bills_one.id;
			this.$useBindAction('created', getType(set));
			this.$router.replace(`/invoices/${id}`);
		});
	},
	async UPDATE_INVOICE({ rootGetters }, { id, set: $set, showMessage }) {
		let validMutation = true;
		const columns = [
				['discount', 'quantity', 'treatment_id', 'treatment_price_alt', 'tax_alt'],
				['amount', 'created_at', 'patient_id'],
			],
			onError = () => (showMessage = validMutation = showMessage && this.$useNotify('error')),
			compareLists = ({ invoice_bill_items, payments }) =>
				Promise.all(
					[invoice_bill_items, payments].map((list, index) => {
						const mutation = index ? UpdatePayment : UpdateItem,
							name = index ? 'payments' : 'items',
							$l = $set[name].filter((e) => list.some((el) => e.id === el.id)),
							fields = columns[index];
						payload[name].id = { _nin: $l.map((e) => e.id) };
						return Promise.all(
							list.map(async ($el) => {
								const el = $el.id && $l.find((e) => e.id === $el.id);
								if (!el) return;
								if (index) {
									const createdAt = this.$$moment($el.created_at);
									$el.created_at = createdAt.format('YYYY-MM-DD');
									el.patient_id = $set.patient_id;
								} else {
									$el.treatment_price_alt = el.treatment_price_alt;
									$el.tax_alt = el.tax_alt;
								}
								if (!fields.some((k) => el[k] !== $el[k])) return;
								const set = fields.reduce((a, k) => ({ ...a, [k]: el[k] }), {});
								await this.$useMutation(mutation, { id: el.id, set }, () => {}, onError);
							})
						);
					})
				);

		// Fetch the current list from the database to determine which elements need updating or deletion and perform the mutations loop to update the modified items and payments.
		const filter = { invoice_bill_id: { _eq: id } },
			payload = { id, set: $set, items: filter, payments: { ...filter } };
		if ($set.items.length || $set.payments.length)
			await this.$useQuery(ItemsPaymentsQuery, { id }, compareLists, onError);
		if (!validMutation) return {};

		// Perform the mutation to update the invoice, delete the removed items and payments, insert the new ones and return their IDs.
		[
			[$set.items, { clinic_id: rootGetters['clinic/GET_CLINIC'].id }],
			[$set.payments, { patient_id: $set.patient_id }],
		].map(([list, extraFields], index) => {
			payload[index ? 'paymentsAdd' : 'itemsAdd'] = list
				.filter((e) => !e.id)
				.map((el) => {
					el = columns[index].reduce((a, k) => ({ ...a, [k]: el[k] }), {});
					return { ...el, ...extraFields, invoice_bill_id: id };
				});
		});
		const type = getType($set),
			onSuccess = (data) => {
				const { insert_invoice_bill_items: $t, insert_payments: $p } = data,
					items = $t?.returning?.map(({ id }) => id) || [],
					payments = $p?.returning?.map(({ id }) => id) || [];
				if (showMessage) this.$useBindAction('updated', type);
				return { items, payments, valid: true };
			};
		delete $set.items, delete $set.payments;
		return (await this.$useMutation(UpdateInvoice, payload, onSuccess, onError)) || {};
	},
	async DELETE_INVOICE({ dispatch }, payload) {
		await this.$useMutation(DeleteInvoice, { id: payload.id }, () => {
			this.$useBindAction('deleted', getType(payload));
			if (payload.refresh) dispatch(payload.refresh);
		});
	},
};

export const mutations = {
	SET_PAGINATION(state, payload) {
		state.pagination = { ...state.pagination, ...payload };
	},
	SET_FILTERS(state, payload) {
		state.filters = payload;
	},
	SET_PATIENT_FILTERS(state, payload) {
		state.patientFilters = payload;
	},
	SET_INVOICE(state, payload) {
		state.invoice = payload;
	},
	SET_INVOICES(state, payload) {
		state.invoices = payload;
	},
	SET_PATIENT_INVOICES(state, payload) {
		state.patientInvoices = payload;
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
