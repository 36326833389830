import PaginateUsers from '~/apollo/queries/users/paginate';
import UsersSearch from '~/apollo/queries/users/all';
import AddUser from '~/apollo/mutations/users/add';
import UpdateUser from '~/apollo/mutations/users/update';
import DeleteUser from '~/apollo/mutations/users/delete';

import { PARSE_PATIENT as PARSE } from '../patients';

export const state = () => ({
	pagination: { current: 1, limit: 10, total: 0 },
	filters: {},
	team: [],
	searchTeam: [],
});

export const getters = {
	GET_TABLES_COLUMNS() {
		return [
			{ data: 'fname', title: 'auth.fname', conditional: true },
			{ data: 'lname', title: 'auth.lname', conditional: true },
			{ data: 'email', title: 'auth.email' },
			{ slot: 'phone', title: 'auth.phone', conditional: true },
			{ slot: 'role', title: 'settings.role' },
		];
	},
	GET_PAGINATION({ pagination }) {
		return pagination;
	},
	GET_FILTERS({ filters }) {
		return filters;
	},
	GET_TEAM({ team }) {
		return team;
	},
	GET_SEARCH_TEAM({ searchTeam }) {
		return searchTeam;
	},
};

export const actions = {
	async SET_TEAM({ getters, commit }) {
		const where = getters['GET_FILTERS'],
			{ current, limit } = getters['GET_PAGINATION'],
			filters = { offset: (current - 1) * limit, limit, where: { ...where } };
		if (!filters.where.role || filters.where.role?._eq === 'owner')
			filters.where.role = { _neq: 'owner' };
		return await this.$useQuery(PaginateUsers, filters, (data) => {
			commit('SET_PAGINATION', { total: data.users_aggregate.aggregate.count });
			commit('SET_TEAM', data.users.map(PARSE));
			return true;
		});
	},
	async SET_SEARCH_TEAM({ commit }, where) {
		return await this.$useQuery(UsersSearch, { where }, ({ users }) => {
			commit('SET_SEARCH_TEAM', users.map(PARSE));
		});
	},
	async ADD_MEMBER({ rootGetters, commit, dispatch }, { set: { email, role }, onNotAdd }) {
		const onSuccess = async () => {
			commit('SET_FILTERS', { joined: { _eq: false } });
			await dispatch('SET_TEAM');
		};
		return this.$useMutation(
			AddUser,
			{ object: { email, role, clinic_id: rootGetters['clinic/GET_CLINIC'].id } },
			async () => {
				const onError = () => this.$useNotify('error', this.$t('settings.invitation-not-sent'));
				await dispatch('SEND_MAIL', { email, onError });
				await onSuccess();
			},
			async (_, error) => {
				if (!error.includes('users_email_key')) return this.$useNotify('error');
				await this.$useAxios(
					'invite-duplicate-check',
					{ email, role, clinicName: this.$useSettings('general').name },
					async ({ emailAlreadyInvited, emailOwnedByAnotherClinic }) => {
						if (emailAlreadyInvited) onNotAdd(this.$t('settings.exists-same-clinic'));
						else if (emailOwnedByAnotherClinic)
							return onNotAdd(this.$t('settings.exists-other-clinic'));
						else this.$useNotify('success', this.$t('settings.invitation-sent'));
						await onSuccess();
					},
					async (_, error) => {
						if (!error.includes('invite-user')) return this.$useNotify('error');
						this.$useNotify('error', this.$t('settings.invitation-not-sent'));
						await onSuccess();
					}
				);
			}
		);
	},
	async SEND_MAIL(_, { email, onError }) {
		await this.$useAxios(
			'invite-user',
			{ 'recipient-address': email, 'clinic-name': this.$useSettings('general').name },
			() => this.$useNotify('success', this.$t('settings.invitation-sent')),
			onError
		);
	},
	async UPDATE_MEMBER({ dispatch }, { id, role }) {
		await this.$useMutation(UpdateUser, { id, set: { role } }, () => {
			this.$useBindAction('updated', 'settings.the-member');
			dispatch('SET_TEAM');
		});
	},
	async DELETE_MEMBER({ dispatch }, id) {
		await this.$useMutation(DeleteUser, { id }, () => {
			this.$useBindAction('removed', 'settings.the-member');
			dispatch('SET_TEAM');
		});
	},
};

export const mutations = {
	SET_PAGINATION(state, payload) {
		state.pagination = { ...state.pagination, ...payload };
	},
	SET_FILTERS(state, payload) {
		state.filters = payload;
	},
	SET_TEAM(state, payload) {
		state.team = payload;
	},
	SET_SEARCH_TEAM(state, payload) {
		state.searchTeam = payload;
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
