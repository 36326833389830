
export default {
	name: 'GuideLayout',
	computed: {
		info() {
			return this.$store.getters['GET_APP_INFO'];
		},
		copyrights() {
			return this.$useBind('global.copyrights', { year: this.$$moment().year() });
		},
	},
	methods: {
		onPressEnter(e) {
			if (e.keyCode === 13) this.$nuxt.$emit('onEnterKeyPress', e.target?.type === 'checkbox', e);
			else this.$nuxt.$emit('onKeyPress', e);
		},
	},
	mounted() {
		window.addEventListener('keypress', this.onPressEnter);
	},
	beforeDestroy() {
		window.removeEventListener('keypress', this.onPressEnter);
	},
};
