export default async function ({ store, route, redirect, $sentry }) {
	try {
		route = route.matched?.[0]?.path || route.path;
		const routes = [...store.getters.GET_ROUTES];
		let $route;

		// Search for the current path key in the available routes.
		if (route === '/') {
			$route = routes.find(({ root }) => root === '/');
			if (!$route) return redirect(routes[0].root);
		} else {
			$route = routes
				.filter(({ root }) => root !== '/')
				.sort(({ root }, { root: $root }) => $root.split('/').length - root.split('/').length)
				.find(({ root }) => route.startsWith(root));
		}

		// Check the special cases of deep verifications
		if ($route.exact) {
			if ($route.root !== route) $route = undefined;
		} else if ($route.except) {
			const except = Array.isArray($route.except) ? $route.except : [$route.except];
			if (except.includes(route)) $route = undefined;
		}

		// Set the key to update the menu style to the active page
		store.commit('SET_ACTIVE_ROUTE', $route.key);
	} catch (error) {
		// If the user has no access to the current page redirect to '/404'
		$sentry.captureException(error);
		redirect('/404');
	}
}
