
export default {
	name: 'LanguageSwitcher',
	methods: {
		switcher(closePopup, locale) {
			this.$i18n.setLocale(locale);
			closePopup();
		},
	},
};
