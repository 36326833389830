const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

export default ({ app: { apolloProvider }, $useToken, $useNotify, $sentry, store }, inject) => {
	// Control the WebSocket (Only one socket is used for all subscriptions)
	const isSocketConnected = () => apolloProvider.defaultClient.wsClient.status === 1;

	let connecting = false;
	const connectSocket = async () => {
		const connect = async () => {
			apolloProvider.defaultClient.wsClient.connect();
			await sleep(5000);
			if (isSocketConnected()) return;
			closeSocket();
			sleep(750).then(connect);
		};
		if (!connecting && !isSocketConnected()) {
			connecting = true;
			connect();
		}
		while (!isSocketConnected()) await sleep(50);
		connecting = false;
	};

	const closeSocket = () => apolloProvider.defaultClient.wsClient.close(true);
	closeSocket(); // Ensure the socket is closed initially

	// Control the subscriptions instances
	const subscriptions = {};
	const createInstance = async (key, query, variables, onSuccess, onError) => {
		await subscriptions[key]?.close(); // Close the old subscription with the same key if exists.
		return (subscriptions[key] = {
			isOpen() {
				return !($useToken() ? this.closing : this.close());
			},
			close() {
				if (!this.closing) this.closing = new Promise((resolve) => (this.closed = resolve));
				this.operation?._cleanup?._observer?.complete();
				this.operation = undefined;
				store.commit('SET_SUBSCRIPTION', { key, status: undefined });
				return this.closing;
			},
			subscribe() {
				const $onSuccess = (data) => this.isOpen() && onSuccess(data);
				return new Promise((resolve) => {
					this.operation = apolloProvider.defaultClient
						.subscribe({ query, variables })
						.subscribe({ next: ({ data }) => resolve($onSuccess(data)), error: onError });
				});
			},
		});
	};

	inject(
		'useSubscription',
		(key, query, variables = {}, onSuccess = () => {}, onError = () => $useNotify('error')) => {
			return new Promise(async (resolve) => {
				const $onError = (e) => (
					console.error(e?.message || e),
					$sentry.captureException(e),
					resolve(onError(e?.message || e))
				);
				try {
					if (!$useToken()) throw new Error('Invalid jwt token!');
					const instance = await createInstance(key, query, variables, onSuccess, $onError);
					while (instance.isOpen()) {
						store.commit('SET_SUBSCRIPTION', { key, status: false });
						await connectSocket(); // Await the WebSocket to be connected successfully.
						if (instance.isOpen()) resolve(await instance.subscribe()); // Create the subscription via Apollo.
						if (instance.isOpen()) store.commit('SET_SUBSCRIPTION', { key, status: true });
						while (instance.isOpen() && isSocketConnected()) await sleep(2000); // Periodically check the subscription connection every 2 seconds.
						if (instance.isOpen()) instance.operation?._cleanup?._observer?.complete(); // Unsubscribe the current Apollo subscription.
					}
					delete subscriptions[key];
					resolve(instance.closed());
					if (Object.keys(subscriptions).length === 0) closeSocket(); // Close the WebSocket if there are no more subscriptions.
				} catch (error) {
					$sentry.captureException(error);
					$onError(error);
				}
			});
		}
	);

	inject('useCloseSubscription', async (key) => {
		if (key) await subscriptions[key]?.close();
		else await Promise.all(Object.values(subscriptions).map((it) => it.close()));
	});
};
