export default ({ i18n, $moment, $sentry, app }, inject) => {
	const $$moment = (dateTime, format, ...args) => {
		dateTime = dateTime?.value || dateTime;
		if (typeof dateTime === 'string' && !format) {
			if (/^\d{1,2}:\d{1,2}$/.test(dateTime)) format = 'HH:mm';
		}
		const localMoment = $moment(dateTime, format, ...args);
		localMoment.locale(i18n.locale);
		return localMoment;
	};

	const getFixedDate = (date, time, isEnd, exact, ifPositive, ifNegative) => {
		// Create a Moment.js instance for the given date and time, and get the timezone offset in minutes.
		let $date = $$moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm'),
			offset = -$moment
				.tz($moment.tz(app.$useSettings('dates').timezone).format('YYYY-MM-DD HH:mm'), 'UTC')
				.diff($moment.tz('UTC'), 'minutes');

		// Adjust the Moment.js instance by adding or subtracting the offset to get the correct local time.
		if (offset > 0) $date[ifPositive](offset, 'minutes');
		else if (offset < 0) $date[ifNegative](-offset, 'minutes');

		if (!exact) {
			// Round the minutes to the nearest multiple of 5, except for 23:59 which remains as is.
			const minutes = $date.minutes();
			if (minutes % 5 && ($date.hours() !== 23 || minutes !== 59))
				$date.set('minutes', Math.round(minutes / 5) * 5);
		}

		// Ensure that the end-of-day times are correctly adjusted if rounding pushes them to the next day.
		if (isEnd === true && $date.format('HH:mm') === '00:00') $date = $date.subtract(1, 'minutes');
		else if (isEnd === false && $date.format('HH:mm') === '23:59') $date = $date.add(1, 'minutes');

		// Return the formatted date and time strings.
		return [$date.format('YYYY-MM-DD'), $date.format('HH:mm')];
	};

	const $toUTC = (date, time, isEnd, exact = true) => {
		return getFixedDate(date, time, isEnd, exact, 'add', 'subtract');
	};

	const $fromUTC = (date, time, isEnd, exact = true) => {
		return getFixedDate(date, time, isEnd, exact, 'subtract', 'add');
	};

	/** Converts a UTC timestamp fetched from Hasura to an array containing the date and time in UTC.
	 * @param {string} timestamp - The UTC timestamp in the format 'YYYY-MM-DDTHH:mm:ss.SSSSSS±HH:mm'.
	 * @returns {string[]} An array where the first element is the date in 'YYYY-MM-DD' format, and the second element is the time in 'HH:mm' format, both in UTC.
	 * @example
	 * this.$asUTC("2024-08-01T14:23:45.678910+00:00"); // Output: ["2024-08-01", "14:23"]
	 */
	const $asUTC = (timestamp) => {
		return $moment.tz(timestamp, 'UTC').format('YYYY-MM-DD HH:mm').split(' ');
	};

	/** Adjusts date filters to ensure accurate data retrieval by handling timezone differences when using Hasura/GraphQL, which operates in UTC.
	 *
	 * **Example Problem:**
	 * - Fetching appointments for "2024-08-28" while the current timezone is GMT+5:
	 *   - Direct filters: `{ date_from: { _eq: "2024-08-28" }, date_to: { _eq: "2024-08-28" } }`
	 *   - Actual range fetched after using `$fromUTC`: (from "2024-08-28 05:00" to "2024-08-29 05:00")
	 *   - Issues: Missing data from "2024-08-28 00:00" to "2024-08-28 05:00" and unnecessary data from "2024-08-29 00:00" to "2024-08-29 05:00".
	 *
	 * **Solution:**
	 * 1. Translate filters by 5 hours to the left using `$toUTC`: from "2024-08-27" to the range (from "2024-08-27 19:00" to "2024-08-28 18:59")
	 * 2. After receiving data, adjust by 5 hours to the right using `$fromUTC`: (from "2024-08-28 00:00" to "2024-08-28 23:59")
	 * This function provides the correct filters to pass. You only need to perform Step 2 after receiving the data.
	 *
	 * **Note:** The output may differ depending on several conditions (after translating from the current timezone to UTC):
	 * - Is date_from equal to date_to? (Range requested is within the same day)
	 * - Is time_from not equal to "00:00"? (Range requested includes just some parts of the day)
	 * - Is time_to not equal to "23:59"? (Range requested includes just some parts of the day)
	 *
	 * **Examples:**
	 * - The output of the range (from "2024-03-15 00:00" to "2024-03-15 23:59") is `{ date_from: { _eq: "2024-03-15" }, date_to: { _eq: "2024-03-15" } }`
	 * - The output of the range (from "2024-03-01 12:45" to "2024-03-31 23:59") is `{ _or: [ { date_from: { _eq: "2024-03-01" }, time_from: { _gte: "12:45" } }, { date_to: { _eq: "2024-03-31" }, time_to: { _lte: "18:00" } }, { date_from: { _gt: "2024-03-01" }, date_to: { _lt: "2024-03-31" } } ] }`
	 */
	const $useUTCRangeFilter = ({
		date_from,
		date_to,
		time_from = '00:00',
		time_to = '23:59',
		isAlreadyInUTC = false,
	} = {}) => {
		// Ensure the right params are passed
		let error;
		if ([date_from, date_to, time_from, time_to].some((param) => typeof param !== 'string'))
			error = 'All the params need to be strings';
		if (date_from > date_to || (date_from === date_to && time_from > time_to))
			error = 'Start date is after End date';
		if (error) {
			const params = { date_from, date_to, time_from, time_to };
			$sentry.captureException(new Error(`$useUTCRangeFilter Error: ${error}. Current params`));
			return console.error(`$useUTCRangeFilter Error: ${error}. Current params`, params);
		}

		if (!isAlreadyInUTC) {
			[date_from, time_from] = $toUTC(date_from, time_from, false, false);
			[date_to, time_to] = $toUTC(date_to, time_to, true, false);
		}

		if (date_from === date_to) {
			const filters = { date_from: { _eq: date_from }, date_to: { _eq: date_to } };
			if (time_from !== '00:00') filters.time_from = { _gte: time_from };
			if (time_to !== '23:59') filters.time_to = { _lte: time_to };
			return filters;
		} else if (time_from === '00:00' && time_to === '23:59')
			return { date_from: { _gte: date_from }, date_to: { _lte: date_to } };
		const inStartDay = { date_from: { _eq: date_from }, time_from: { _gte: time_from } },
			inEndDay = { date_to: { _eq: date_to }, time_to: { _lte: time_to } };
		if (time_from === '00:00')
			return {
				_or: [
					{ date_from: { _gte: date_from }, ...inEndDay },
					{ date_from: { _gte: date_from }, date_to: { _lt: date_to } },
				],
			};
		if (time_to === '23:59')
			return {
				_or: [
					{ date_to: { _lte: date_to }, ...inStartDay },
					{ date_to: { _lte: date_to }, date_from: { _gt: date_from } },
				],
			};
		return {
			_or: [inStartDay, inEndDay, { date_from: { _gt: date_from }, date_to: { _lt: date_to } }],
		};
	};

	inject('$moment', $$moment);
	inject('asUTC', $asUTC);
	inject('toUTC', $toUTC);
	inject('fromUTC', $fromUTC);
	inject('useUTCRangeFilter', $useUTCRangeFilter);
};
