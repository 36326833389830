import getRoutes from '~/utils/app/routes';
import { getCountry } from '~/utils/app/countries';

export const state = () => ({
	clinic: null,
	token: null,
});

export const getters = {
	GET_CLINIC({ clinic }) {
		return clinic;
	},
	GET_TOKEN({ token }) {
		return token;
	},
};

export const actions = {
	async VERIFY_EMAIL(_, { email, subscribed }) {
		return await this.$useSimpleAxios(
			'verify-email',
			{ email, subscribed },
			({ verified }) => ({ verified }),
			(error) => ({ used: error?.response?.data?.message?.includes?.('email already used') })
		);
	},
	async VERIFY_CODE(_, { email, OTP }) {
		const variables = { email, digits_code: OTP };
		return (
			(await this.$useSimpleAxios('verify-code', variables, ({ verified }) => ({ verified }))) || {}
		);
	},
	async OPEN_BILLING_PORTAL({ state, rootGetters }) {
		const user = rootGetters['user/GET_USER'];
		const portalURL =
			state.clinic.subscribed && user.role === 'owner'
				? `https://billing.stripe.com/p/login/test_9AQ29cfcM8wj4927ss?prefilled_email=${user.email}`
				: null;
		if (portalURL) window.open(portalURL, '_blank');
	},
	async CREATE_STRIPE_SESSION() {
		return await this.$useAxios('create-stripe-session', {}, ({ url }) => {
			window.location.href = url;
		});
	},
	async ACTIVATE_SCREENCAST() {
		return await this.$useAxios('activate-screencast', {}, (data) => {
			return data.screencast_activation_code;
		});
	},
	async CREATE_CLINIC({ commit, dispatch, rootGetters }, { set, recaptcha }) {
		const defaults = getCountry(set.country),
			phones = set.phone ? [set.phone] : undefined;
		const object = {
			contact: { data: { name: [set.name], specialties: set.specialties, phones } },
			location: { data: { country: set.country } },
			settings: { data: { language: this.$i18n.locale } },
			settings_numbers_finance: {
				data: { currency: { code: defaults.currency, index: 0 } },
			},
			settings_date_time: {
				data: {
					timezone: set.timezone,
					time_format: set.timeFormat,
					date_format: defaults.dateOrder,
					date_separator: defaults.dateSeparator,
				},
			},
			users: {
				data: {
					email: set.email,
					password: set.password,
					contact: {
						data: {
							honorific: set.title,
							fname: set.fname,
							lname: set.lname,
							emails: [set.email],
							phones,
						},
					},
				},
			},
		};

		if (!set.title) delete object.users.data.contact.data.honorific;
		if (!set.phone) {
			delete object.contact.data.phones;
			delete object.users.data.contact.data.phones;
		}

		return await this.$useSimpleAxios(
			'onboarding',
			{ object, g_recaptcha_response: recaptcha },
			async ({ onboardingToken, clinicId, userId }) => {
				this.$useSetToken(onboardingToken, false);
				this.$useSetCookie('auth_email', set.email, false);
				const clinic = {
					id: clinicId,
					name: set.name,
					is_suspended: false,
					is_trial_ended: false,
					subscribed: false,
				};
				const user = {
					id: userId,
					fname: set.fname,
					lname: set.lname,
					full_name: `${set.fname} ${set.lname}`,
					role: 'owner',
					title: set.title || '',
				};
				commit('SET_CLINIC', clinic);
				commit('user/SET_USER', user, { root: true });
				commit('SET_ROUTES', getRoutes(user.role), { root: true });
				await Promise.all([
					dispatch('settings/SET_GENERAL', object, { root: true }),
					dispatch('settings/SET_NUMBERS_FINANCE', object.settings_numbers_finance, { root: true }),
					dispatch('settings/SET_DATE_TIME', object.settings_date_time, { root: true }),
				]);
				await dispatch('STORE_PAYLOAD', { firstLog: true }, { root: true });
				this.$router.push(rootGetters['GET_ROUTES'][0].root);
				return true;
			}
		);
	},
	async JOIN_CLINIC({ dispatch, rootGetters }, { set, recaptcha, inviteToken }) {
		const user_object = { password: set.password },
			user_contact_object = {
				honorific: set.title,
				fname: set.fname,
				lname: set.lname,
				phones: set.phone ? [set.phone] : undefined,
			},
			payload = {
				user_object,
				user_contact_object,
				g_recaptcha_response: recaptcha,
			};
		if (!set.phone) delete payload.user_contact_object.phones;
		return await this.$useSimpleAxios(
			'onboarding-team-member',
			payload,
			{ Authorization: `Bearer ${inviteToken}` },
			async ({ onboardingToken, intercomUserHmac, email }) => {
				const onSuccess = async () => {
					await dispatch('STORE_PAYLOAD', { firstLog: true }, { root: true });
					this.$router.push(rootGetters['GET_ROUTES'][0].root);
					return true;
				};
				this.$useSetToken(onboardingToken, false);
				this.$useSetCookie('auth_email', email, false);
				this.$useSetCookie('auth_intercom_user_hmac', intercomUserHmac, false);
				await dispatch('user/INIT', { email, onSuccess }, { root: true });
			}
		);
	},
};

export const mutations = {
	SET_CLINIC(state, payload) {
		state.clinic = payload;
	},
	SET_TOKEN(state, payload) {
		state.token = payload;
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
