
import Intercom from '@intercom/messenger-js-sdk';
export default {
	name: 'IntercomMessenger',
	props: {
		language: { type: String, default: 'en' },
	},
	methods: {
		loadIntercomMessenger() {
			const user = this.$store.getters['user/GET_USER'],
				language_override = this.language,
				intercomObj = {
					app_id: 'w8tzpsmy',
					alignment: 'left',
					language_override,
				};

			if (user) {
				const { id: user_id, email, fname: name, created_at } = user,
					user_hash = this.$useCookie('auth_intercom_user_hmac');

				Object.assign(intercomObj, {
					vertical_padding: 53,
					user_id,
					email,
					name,
					created_at,
					user_hash,
				});
			}

			Intercom(intercomObj);
		},
	},
	watch: {
		language() {
			this.loadIntercomMessenger();
		},
	},
	mounted() {
		this.loadIntercomMessenger();
	},
	beforeDestroy() {
		window.Intercom('shutdown');
	},
};
