import QueueSubscription from '~/apollo/subscriptions/queues';
import AddQueue from '~/apollo/mutations/queues/add';
import UpdateQueue from '~/apollo/mutations/queues/update';
import DeleteQueue from '~/apollo/mutations/queues/delete';

import { PARSE_PATIENT } from '../patients';
import { ringtoneAudioBase64 } from '~/utils/app/screencast-ringtone-base64';

let intervalId = null;
const PARSE = (item, { dispatch, moment }) => {
	item.patient = PARSE_PATIENT(item.patient, { id: item.patient_id });
	if (item.pinned_at && moment().diff(item.pinned_at, 'seconds') < 25) {
		clearInterval(intervalId);
		intervalId = setInterval(() => {
			if (moment().diff(item.pinned_at, 'seconds') >= 25) {
				dispatch('UPDATE_QUEUE_STATE', { screencast: false, id: item.patient_id, pinned_at: null });
				clearInterval(intervalId);
			}
		}, 1000);
	} else item.pinned_at = null;
	delete item.__typename;
	return item;
};

export const state = () => ({
	queue: [],
	audiosThreadInfo: { ref: null, ringtone: null, patient: null },
});

export const getters = {
	GET_QUEUE({ queue }) {
		return queue;
	},
	GET_THREAD_INFO({ audiosThreadInfo }) {
		return audiosThreadInfo;
	},
	GET_PINNED_PATIENT({ queue }) {
		return queue.find((queueItem) => queueItem.pinned_at);
	},
};

export const actions = {
	SET_QUEUE({ getters, rootGetters, dispatch, commit }) {
		if (rootGetters.GET_SUBSCRIPTIONS.queue) return;
		this.$useSubscription('queue', QueueSubscription, {}, async ({ queues }) => {
			getters['GET_THREAD_INFO'].ringtone?.pause?.();
			getters['GET_THREAD_INFO'].patient?.pause?.();
			const currentRef = Symbol();
			commit('SET_THREAD_INFO', { ref: currentRef, patient: null });
			queues = queues.map((item) => PARSE(item, { dispatch, moment: this.$$moment }));
			const itemIndex = queues.findIndex((queueItem) => queueItem.pinned_at);
			if (itemIndex !== -1) {
				const item = queues[itemIndex];
				// Preparing arguments to bind with the sentences
				let titles = item.is_child ? ['traits.child', `الطفل`] : ['traits.mister', `السيد`];
				if (item.patient.sex === 'female')
					titles = item.is_child ? ['traits.child', `الطفلة`] : ['traits.madame', `السيدة`];
				const data = {
					prefix: this.$t(titles[0]),
					prefix_ar: titles[1],
					full_name: item.patient.full_name,
					full_name_ar: item.patient.full_name_ar,
				};

				// Generating sentences in both languages and manage the audio playing for screencast
				item.text = this.$useBind('waiting-room.patient-turn-phrase', data);
				if (item.patient.full_name_ar) {
					const arabicSentence = '{{ prefix_ar }} {{ full_name_ar }}، لقد حان دورك لزيارة الطبيب.';
					item.text_ar = this.$useBind(arabicSentence, data, false);
				}
				if (this.$useRole('screencast')) {
					const text = `${item.text}${item.text_ar ? ' ' + item.text_ar : ''}`;
					dispatch('PLAY_AUDIOS_SEQUENCE', { currentRef, item, text });
				}
				queues[itemIndex] = item;
			}
			if (getters['GET_THREAD_INFO'].ref === currentRef) commit('SET_QUEUE', queues);
		});
	},
	async ADD_TO_QUEUE({ rootGetters }, { patient: { id, full_name }, is_child }) {
		if (this.$useRole('screencast')) return;
		const clinic_id = rootGetters['clinic/GET_CLINIC'].id;
		await this.$useMutation(AddQueue, { object: { patient_id: id, clinic_id, is_child } }, () => {
			const options = { args: full_name, end: this.$t('waiting-room.to-waiting-room') };
			this.$useBindAction('added', 'patient-records.the-patient', options);
		});
	},
	async UPDATE_QUEUE({ getters }, { id, pinned_at }) {
		if (this.$useRole('screencast')) return;
		if (getters['GET_PINNED_PATIENT'] && pinned_at)
			return this.$useNotify('warning', this.$t('waiting-room.cannot-pin-now'));
		// ? Cannot use the queue id directly because we don't have Queue objects in all pages, that's why we are using Patient objects
		id = getters['GET_QUEUE'].find(({ patient_id }) => patient_id === id)?.id;
		if (id) await this.$useMutation(UpdateQueue, { id, set: { pinned_at } });
	},
	UPDATE_QUEUE_STATE({ state, commit }, { screencast, id, pinned_at }) {
		if (!screencast && this.$useRole('screencast')) return;
		const queue = state.queue.map((item) =>
			item.patient_id === id ? { ...item, pinned_at } : item
		);
		commit('SET_QUEUE', queue);
	},
	async DELETE_FROM_QUEUE({ getters }, id) {
		if (this.$useRole('screencast')) return;
		// ? Cannot use the queue id directly because we don't have Queue objects in all pages, that's why we are using Patient objects
		id = getters['GET_QUEUE'].find(({ patient_id }) => id === patient_id)?.id;
		if (!id) return;
		await this.$useMutation(DeleteQueue, { id }, () => {
			const options = { end: this.$t('waiting-room.from-waiting-room') };
			this.$useBindAction('removed', 'patient-records.the-patient', options);
		});
	},
	async PLAY_AUDIOS_SEQUENCE({ getters, commit, dispatch }, { currentRef, item, text }) {
		const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

		// Generate the patient sentence audio and wait for its metadata to load as we need its duration then commit to the store
		if (!this.$useRole('screencast') || getters['GET_THREAD_INFO'].ref !== currentRef) return;
		this.$useAxios('text-to-speech', { text }, (data) => {
			if (!data.audio_base64 || getters['GET_THREAD_INFO'].ref !== currentRef) return;
			const patient = new Audio(`data:audio/mp3;base64,${data.audio_base64}`);
			patient.addEventListener('loadedmetadata', () => {
				if (getters['GET_THREAD_INFO'].ref === currentRef) commit('SET_THREAD_INFO', { patient });
			});
		});

		commit('SET_THREAD_INFO', { ringtone: new Audio(ringtoneAudioBase64) });

		// A function that plays an audio (by its name {ringtone | patient}) only if it's the same thread
		const playAudio = async (name) => {
			if (getters['GET_THREAD_INFO'].ref !== currentRef) return;
			const audio = getters['GET_THREAD_INFO'][name];
			audio.play();
			return await sleep(audio.duration * 1000 - 276);
		};

		// Play 4 ringtones with a 3sec length + 5sec gap only if the patient sentence audio not fetched
		playAudio('ringtone');
		let onlyRingtones = true;
		(async () => {
			await sleep(5000);
			if (onlyRingtones) await playAudio('ringtone');
			await sleep(5000);
			if (onlyRingtones) await playAudio('ringtone');
			if (onlyRingtones && getters['GET_THREAD_INFO'].ref === currentRef)
				dispatch('UPDATE_QUEUE_STATE', { screencast: true, id: item.patient_id, pinned_at: null });
		})();

		// Multiple attempts to get the patient sentence audio and run the sequence of audios below
		const patientSentence = async () => {
			await sleep(3000); // Wait for the previous ringtone to finish to avoid any conflict
			let expired = false;
			setTimeout(() => (expired = true), 5000); // Wait the patient sentence only for 5sec before the next ringtone starts
			while (!expired && !getters['GET_THREAD_INFO'].patient) await sleep(50);
			if (!getters['GET_THREAD_INFO'].patient) return false;
			onlyRingtones = false;
			await playAudio('patient');
			await playAudio('ringtone');
			await playAudio('patient');
			await playAudio('ringtone');
			if (getters['GET_THREAD_INFO'].ref === currentRef)
				dispatch('UPDATE_QUEUE_STATE', { screencast: true, id: item.patient_id, pinned_at: null });
			return true;
		};
		if (!(await patientSentence())) if (!(await patientSentence())) onlyRingtones = true;
	},
};

export const mutations = {
	SET_QUEUE(state, payload) {
		state.queue = payload;
	},
	SET_THREAD_INFO(state, payload) {
		state.audiosThreadInfo = { ...state.audiosThreadInfo, ...payload };
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
