import { render, staticRenderFns } from "./DefaultButtons.vue?vue&type=template&id=0dd8e37e&scoped=true&lang=pug"
import script from "./DefaultButtons.vue?vue&type=script&lang=js"
export * from "./DefaultButtons.vue?vue&type=script&lang=js"
import style0 from "./DefaultButtons.vue?vue&type=style&index=0&id=0dd8e37e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dd8e37e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PayloadLink: require('/app/components/global/PayloadLink.vue').default})
