import GeneralQuery from '~/apollo/queries/settings/general.gql';
import GeneralAllQuery from '~/apollo/queries/settings/general-all.gql';
import DateTimeQuery from '~/apollo/queries/settings/date-time.gql';
import DateTimeDaysQuery from '~/apollo/queries/settings/date-time-days.gql';
import NumbersFinanceQuery from '~/apollo/queries/settings/numbers-finance.gql';
import TaxesQuery from '~/apollo/queries/settings/taxes.gql';
import UpdateGeneral from '~/apollo/mutations/settings/update-general.gql';
import UpdateDateTime from '~/apollo/mutations/settings/update-date-time.gql';
import UpdateNumbersFinance from '~/apollo/mutations/settings/update-numbers-finance.gql';
import UpdateTax from '~/apollo/mutations/settings/update-tax.gql';

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const state = () => ({
	general: {
		name: '',
		phone: '',
		email: '',
		website: '',
		details: '',
		country: '',
		state: '',
		city: '',
		postcode: '',
		address: ['', ''],
		language: '',
		logo: '',
	},
	dates: {
		date: 'DD/MM/YYYY',
		time: 'h:mm A',
		dateOrder: 'dmy',
		dateSeparator: '/',
		timeFormat: 'xm',
		workingDays: [1, 2, 3, 4, 5, 6], // Includes values from 0 (Sunday) to 6 (Saturday)
		businessHours: { from: '08:00', to: '18:00' },
		breakTime: { from: '', to: '' },
		timezone: 'Africa/Casablanca',
	},
	numbers: {
		currency: { code: 'USD', index: 0 },
		numbersFormat: 'en',
		taxes: [],
	},
	appointments: {
		splitDays: [], // Multiple timelines. Useful for clinics with numerous doctors, each requiring their own timelines (Refer to VueCal documentation for more information)
		timeStep: 15, // Calendar cell length in minutes.
		timeCellHeight: 33, // Calendar cell size in pixels.
	},
});

export const getters = {
	GET(state) {
		return state;
	},
	GET_GENERAL({ general }) {
		return general;
	},
	GET_DATES({ dates }) {
		return dates;
	},
	GET_NUMBERS({ numbers }) {
		return numbers;
	},
	GET_APPOINTMENTS({ appointments }) {
		return appointments;
	},
};

export const actions = {
	async SET_GENERAL({ state: { general }, commit }, object) {
		const query = object === false ? GeneralQuery : GeneralAllQuery;
		const onSuccess = async (data) => {
			data = { ...data.settings[0], ...data.clinics_contacts?.[0], ...data.clinics_location?.[0] };
			const object = {
				name: data.name[0],
				phone: data.phones?.[0] || '',
				email: data.emails?.[0] || '',
				details: data.registration_info || '',
				language: data.language,
				logo: data.logo,
			};
			if (query === GeneralAllQuery) {
				Object.assign(object, {
					website: data.website || '',
					country: data.country,
					state: data.state || '',
					city: data.city || '',
					postcode: data.postcode || '',
					address: [data.address?.[0] || '', data.address?.[1] || ''],
				});
			}
			await this.$i18n.setLocale(object.language);
			if (!object.logo || object.logo !== general.logo) this.$useFile('remove', general.logo);
			commit('SET_GENERAL', object);
			return true;
		};
		if (!object) return await this.$useQuery(query, {}, onSuccess);
		const { settings: s, contact: c, location: l } = object.data || object;
		return await onSuccess({ settings: [{ ...s.data, ...c.data, ...l.data }] });
	},
	async SET_NUMBERS_FINANCE({ commit }, object) {
		if (object) {
			object = object.data || object;
			const numbersFormat = object.numbers_format || 'en';
			commit('SET_NUMBERS', { currency: object.currency, numbersFormat });
			return true;
		}
		const query = object === false ? NumbersFinanceQuery : TaxesQuery;
		return await this.$useQuery(query, {}, (data) => {
			const { numbers_format, currency } = data.settings_numbers_finance?.[0] || {};
			let taxes = data.clinic_taxes?.map(({ id, name, value }) => ({ id, name, value }));
			if (taxes) commit('SET_NUMBERS', { taxes });
			else commit('SET_NUMBERS', { currency, numbersFormat: numbers_format });
			return true;
		});
	},
	async SET_DATE_TIME({ commit }, object) {
		if (object) {
			object = object.data || object;
			const dateOrder = object.date_format,
				dateSeparator = object.date_separator,
				timeFormat = object.time_format,
				workingDays = [0, 1, 2, 3, 4, 5, 6],
				businessHours = { from: '00:00', to: '23:59' },
				breakTime = { from: '', to: '' },
				timezone = object.timezone,
				hours = { workingDays, businessHours, breakTime };
			commit('SET_DATES', { dateOrder, dateSeparator, timeFormat, timezone, ...hours });
			return true;
		}
		const query =
			object === false || this.$useRole('bookkeeper') ? DateTimeQuery : DateTimeDaysQuery;
		return await this.$useQuery(query, {}, ({ settings_date_time }) => {
			const timezone = settings_date_time[0].timezone,
				dateOrder = settings_date_time[0].date_format,
				dateSeparator = settings_date_time[0].date_separator,
				timeFormat = settings_date_time[0].time_format;
			let hours, businessHours;
			if (query === DateTimeDaysQuery) {
				let breakTime = { from: '', to: '' };
				const workingDays = days
					.map((name, index) => {
						const dayInfo = settings_date_time[0][name];
						if (!dayInfo) return -1;
						businessHours = dayInfo.time_range;
						if (dayInfo.break_time) breakTime = dayInfo.break_time;
						return index;
					})
					.filter((day) => day !== -1);
				hours = { workingDays, businessHours, breakTime };
			}
			commit('SET_DATES', { dateOrder, dateSeparator, timeFormat, timezone, ...hours });
			return true;
		});
	},
	async UPDATE_GENERAL({ state: { general }, commit }, set) {
		const variables = {
			settings: { language: set.language },
			clinics_contacts: {
				name: [set.name],
				phones: set.phone ? [set.phone] : null,
				emails: set.email ? [set.email] : null,
				website: set.website || null,
				registration_info: set.details || null,
			},
			clinics_location: {
				country: set.country,
				state: set.state || null,
				city: set.city || null,
				postcode: set.postcode || null,
				address: !set.address[0] && !set.address[1] ? null : set.address,
			},
		};
		if (!set.logo) {
			this.$useFile('remove', general.logo);
			set.logo = '';
			variables.settings.logo = null;
		} else if (set.logo === (await this.$useFile('getFile', general.logo, 'logo.webp'))) {
			set.logo = general.logo;
			variables.settings.logo = general.logo;
		} else {
			const payload = { module: 'general', object: set.logo, 'previous-object-id': general.logo };
			if (!general.logo) delete payload['previous-object-id'];
			await this.$useAxios(
				'upload',
				payload,
				({ objectId }) => {
					this.$useFile('set', objectId, set.logo);
					set.logo = objectId;
					variables.settings.logo = objectId;
				},
				() => {
					set.logo = general.logo;
					this.$useNotify('error', this.$t('settings.logo-not-uploaded'));
				}
			);
		}
		return this.$useMutation(UpdateGeneral, variables, async () => {
			await this.$i18n.setLocale(set.language);
			commit('SET_GENERAL', set);
			return true;
		});
	},
	async UPDATE_DATE_TIME({ commit }, set) {
		const daysInfo = {
				time_range: set.businessHours,
				break_time: set.breakTime.from ? set.breakTime : null,
			},
			$set = {
				timezone: set.timezone,
				date_format: set.dateOrder,
				date_separator: set.dateSeparator,
				time_format: set.timeFormat,
				...Object.fromEntries(
					days.map((name, index) => [name, set.workingDays.includes(index) ? daysInfo : null])
				),
			};
		return this.$useMutation(UpdateDateTime, { set: $set }, () => {
			commit('SET_DATES', set);
			return true;
		});
	},
	async UPDATE_NUMBERS_FINANCE({ rootGetters, commit }, set) {
		const clinic_id = rootGetters['clinic/GET_CLINIC'].id;
		return await this.$useQuery(TaxesQuery, {}, async ({ clinic_taxes }) => {
			const ids = clinic_taxes.map((tax) => tax.id),
				toAdd = set.taxes
					.filter((tax) => !tax.id)
					.map(({ name, value }) => ({ name, value, clinic_id })),
				toKeep = set.taxes.filter((tax) => ids.includes(tax.id)).map((tax) => tax.id),
				toUpdate = set.taxes.filter((tax) => {
					if (!ids.includes(tax.id)) return;
					const originalTax = clinic_taxes.find(($tax) => $tax.id === tax.id);
					return tax.name !== originalTax.name || tax.value !== originalTax.value;
				});
			let validUpdate = true;
			await Promise.all(
				toUpdate.map(({ id, name, value }) => {
					const variables = { id, set: { name, value } };
					this.$useMutation(UpdateTax, variables, undefined, () => (validUpdate = false));
				})
			);
			if (!validUpdate) return this.$useNotify('error');
			const $set = { currency: set.currency, numbers_format: set.numbersFormat };
			return this.$useMutation(UpdateNumbersFinance, { toAdd, toKeep, set: $set }, (data) => {
				const ids = data.insert_clinic_taxes.returning.map(({ id }) => id),
					added = toAdd.map(({ name, value }, i) => ({ name, value, id: ids[i] }));
				set.taxes = set.taxes.filter((tax) => tax.id).concat(added);
				commit('SET_NUMBERS', set);
				return [true, ids];
			});
		});
	},
};

export const mutations = {
	SET_GENERAL(state, payload) {
		state.general = { ...state.general, ...payload };
	},
	SET_DATES(state, payload) {
		let date = payload.dateOrder.split('').join(payload.dateSeparator);
		date = date.replace('y', 'YYYY').replace('m', 'MM').replace('d', 'DD');
		const time = payload.timeFormat === 'xm' ? 'h:mm A' : 'HH:mm';
		state.dates = { ...state.dates, ...payload, date, time };
	},
	SET_NUMBERS(state, payload) {
		state.numbers = { ...state.numbers, ...payload };
	},
	SET_APPOINTMENTS(state, payload) {
		state.appointments = { ...state.appointments, ...payload };
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
