
import frFR from 'ant-design-vue/es/locale/fr_FR';
import enUS from 'ant-design-vue/es/locale/en_US';
import { mapGetters } from 'vuex';
import global from '~/mixins/global';
import IntercomMessenger from '~/components/global/IntercomMessenger.vue';

export default {
	name: 'AppLayout',
	middleware: ['auth', 'redirect-sign-in', 'parse-route', 'trial-ended', 'suspended'],
	mixins: [global],
	components: { IntercomMessenger },
	data() {
		return { collapsed: true };
	},
	computed: {
		...mapGetters({
			routes: 'GET_ROUTES',
			activeRoute: 'GET_ACTIVE_ROUTE',
			info: 'GET_APP_INFO',
			sessionEnded: 'user/GET_IS_SESSION_ENDED',
		}),
		welcome() {
			const user = this.$store.getters['user/GET_USER'];
			if (!user) return '';
			return this.$useBind('auth.welcome-user', {
				clinic: this.$store.getters['clinic/GET_CLINIC'].name,
				title: user.title ? `${user.title}${user.title.endsWith('.') ? '' : '.'} ` : '',
				name: user.fname,
				role: this.$t(user.role === 'owner' ? 'settings.owner' : `settings.roles.${user.role}`),
			});
		},
		copyrights() {
			const text = this.$useBind('global.copyrights', { year: this.$$moment().year() });
			return `${text} - ${this.$t('global.version')}`;
		},
		locale() {
			return this.$i18n.locale === 'en' ? enUS : frFR;
		},
	},
	methods: {
		signOut() {
			this.$store.dispatch('user/SIGN_OUT_USER');
		},
	},
};
