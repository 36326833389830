import PatientsAnalyticsCard from '~/apollo/queries/patients/analytics-card.gql';
import PatientsAnalyticsChart from '~/apollo/queries/patients/analytics-chart.gql';
import PaymentsAnalyticsCard from '~/apollo/queries/payments/analytics-card.gql';
import PaymentsAnalyticsChart from '~/apollo/queries/payments/analytics-chart.gql';
import AppointmentsAnalyticsCard from '~/apollo/queries/appointments/analytics-card.gql';
import AppointmentsAnalyticsChart from '~/apollo/queries/appointments/analytics-chart.gql';
import InvoicesAnalyticsCard from '~/apollo/queries/invoices/analytics-card.gql';
import InvoicesAnalyticsChart from '~/apollo/queries/invoices/analytics-chart.gql';
import TreatmentsAnalyticsChart from '~/apollo/queries/treatments/analytics-chart.gql';

import { PARSE_TREATMENT } from '../treatments';

export const state = () => ({
	months: [],
	dates: { value: [], range: [] },
	cards: {
		patients: {
			name: 'analytics.new-patients-registered',
			description: 'analytics.new-patients-description',
			icon: 'IconUsersPlus',
		},
		payments: {
			name: 'analytics.revenue-generated',
			description: 'analytics.revenue-description',
			icon: 'IconRevenue',
			price: true,
		},
		appointments: {
			name: 'analytics.appointments-generated',
			description: 'analytics.appointments-description',
			icon: 'IconCalendar',
		},
		invoices: {
			name: 'analytics.invoices-and-quotations-generated',
			description: 'analytics.invoices-and-quotations-description',
			icon: 'IconInvoices',
		},
	},
	charts: {
		patients: { value: [], loading: true },
		payments: { value: [], loading: true },
		appointments: { value: [], loading: true },
		invoices: { value: [], loading: true },
		quotations: { value: [], loading: true },
		treatments: { appointments: [] },
	},
});

export const getters = {
	GET_MONTHS({ months }) {
		return months;
	},
	GET_DATES({ dates }) {
		return dates;
	},
	GET_CARDS({ cards }) {
		const { patients, payments, appointments, invoices } = cards;
		return [patients, payments, appointments, invoices];
	},
	GET_CHARTS({ charts }) {
		return charts;
	},
};

export const actions = {
	SET_DATES({ commit }, dates) {
		if (!dates) {
			// If `dates` param was not passed, set the default dates: (the previous month and the current month)
			const date = this.$$moment(),
				start = date.clone().startOf('month'),
				end = date.clone().endOf('month'),
				$date = date.clone().subtract(1, 'months'),
				$start = $date.clone().startOf('month'),
				$end = $date.clone().endOf('month'),
				months = [$start, $end, start, end].map((d) => d.format('YYYY-MM-DD'));
			commit('SET_MONTHS', months);
			dates = [months[2], months[3]];
		}
		const $dates = dates.map((d) => this.$$moment(d)),
			range = [];
		while ($dates[1].isSameOrAfter($dates[0])) {
			range.push($dates[0].format('YYYY-MM-DD'));
			$dates[0].add(1, 'days');
		}
		commit('SET_DATES', { value: dates, range });
	},
	async SET_ANALYTICS({ dispatch }, delay = true) {
		await Promise.all([
			dispatch('SET_PATIENTS', delay),
			dispatch('SET_PAYMENTS', delay),
			dispatch('SET_APPOINTMENTS', delay),
			dispatch('SET_INVOICES', delay),
			dispatch('SET_TREATMENTS', delay),
		]);
	},
	async SET_PATIENTS({ dispatch }, delay = true) {
		const payload = { delay, key: 'patients', dateKey: 'registered_at' };
		await Promise.all([
			dispatch('SET_CARD', { ...payload, query: PatientsAnalyticsCard }),
			dispatch('SET_CHART', { ...payload, query: PatientsAnalyticsChart }),
		]);
	},
	async SET_PAYMENTS({ dispatch }, delay = true) {
		const payload = { delay, key: 'payments', dateKey: 'created_at' };
		await Promise.all([
			dispatch('SET_CARD', { ...payload, query: PaymentsAnalyticsCard }),
			dispatch('SET_CHART', { ...payload, query: PaymentsAnalyticsChart }),
		]);
	},
	async SET_APPOINTMENTS({ getters, dispatch }, delay = true) {
		const payload = { delay, key: 'appointments', dateKey: 'none' };
		const [date_from, date_to] = getters['GET_DATES'].value;
		payload.where = this.$useUTCRangeFilter({ date_from, date_to });
		await Promise.all([
			dispatch('SET_CARD', { ...payload, query: AppointmentsAnalyticsCard }),
			dispatch('SET_CHART', { ...payload, query: AppointmentsAnalyticsChart }),
		]);
	},
	async SET_INVOICES({ dispatch }, delay = true) {
		const payload = { delay, key: 'invoices_bills', dateKey: 'date' };
		await Promise.all([
			dispatch('SET_CARD', { ...payload, name: 'invoices', query: InvoicesAnalyticsCard }),
			dispatch('SET_CHART', {
				...payload,
				name: 'invoices',
				query: InvoicesAnalyticsChart,
				where: { payments_aggregate: { count: { predicate: { _gt: 0 } } } },
			}),
			dispatch('SET_CHART', {
				...payload,
				name: 'quotations',
				query: InvoicesAnalyticsChart,
				where: { payments_aggregate: { count: { predicate: { _eq: 0 } } } },
			}),
		]);
	},
	async SET_TREATMENTS({ getters, commit }, delay = true) {
		const [date_from, date_to] = getters['GET_DATES'].value,
			where = { appointment: this.$useUTCRangeFilter({ date_from, date_to }) };
		commit('SET_CHART', ['treatments', { value: [], loading: true }]);
		await this.$useQuery(TreatmentsAnalyticsChart, { where }, (data) => {
			const value = data.treatments.map((treatment) => {
					const count = treatment.appointments_aggregate.aggregate.count;
					return { treatment: PARSE_TREATMENT(treatment), count };
				}),
				$payload = ['treatments', { appointments: value, loading: false }];
			if (!delay) commit('SET_CHART', $payload);
			else setTimeout(() => commit('SET_CHART', $payload), 800);
		});
	},
	async SET_CARD({ getters, commit }, payload) {
		const name = payload.name || payload.key,
			dates = getters['GET_DATES'].value,
			months = getters['GET_MONTHS'],
			// Execute the passed query with a filter of dates and a delay using setTimeout before mutating the store state with the query response.
			execQuery = (dates, value, options, delay) => {
				const where = { [payload.dateKey]: { _gte: dates[0], _lte: dates[1] }, ...payload.where };
				delete where.none;
				return this.$useQuery(payload.query, { where }, (data) => {
					const { count, sum } = data[`${payload.key}_aggregate`].aggregate,
						output = [name, options];
					output[1][value] = sum ? sum.amount : count;
					if (!delay) commit('SET_CARD', output);
					else setTimeout(() => commit('SET_CARD', output), 800);
				});
			};

		// The previous month data. (only if the selected date is the current month)
		commit('SET_CARD', [name, { loading: true }]);
		let promise;
		if (months[2] === dates[0] && months[3] === dates[1]) {
			promise = execQuery(months, 'lastMonth', {}, 0);
		} else commit('SET_CARD', [name, { lastMonth: undefined }]);

		// The selected dates data.
		const options = { loading: false };
		await Promise.all([promise, execQuery(dates, 'value', options, payload.delay)]);
	},
	async SET_CHART({ getters, commit }, payload) {
		const name = payload.name || payload.key,
			[_gte, _lte] = getters['GET_DATES'].value,
			where = { [payload.dateKey]: { _gte, _lte }, ...payload.where },
			moment = (date) => this.$$moment(date, 'YYYY-MM-DD'),
			onGetList = (list) => {
				if (list.length)
					list = getters['GET_DATES'].range.map((d) => {
						const dateStart = this.$$moment(this.$toUTC(d, '00:00', false, false).join(' ')),
							dateEnd = this.$$moment(this.$toUTC(d, '23:59', false, false).join(' '));
						return list.reduce((a, it) => {
							if (payload.dateKey === 'none') {
								const d = this.$$moment(it.date_from + ' ' + it.time_from);
								return a + (dateStart.isSameOrBefore(d) && dateEnd.isSameOrAfter(d) ? 1 : 0);
							} else if (!moment(it[payload.dateKey]).isSame(moment(d))) return a;
							return a + ('amount' in it ? it.amount : 1);
						}, 0);
					});
				return list;
			};
		delete where.none;
		commit('SET_CHART', [name, { value: [], loading: true }]);
		await this.$useQuery(payload.query, { where }, (data) => {
			const nodes = data[`${payload.key}_aggregate`].nodes,
				$payload = [name, { value: onGetList(nodes), loading: false }];
			if (!payload.delay) commit('SET_CHART', $payload);
			else setTimeout(() => commit('SET_CHART', $payload), 800);
		});
	},
};

export const mutations = {
	SET_MONTHS(state, months) {
		state.months = months;
	},
	SET_DATES(state, dates) {
		state.dates = dates;
	},
	SET_CARD(state, [key, payload]) {
		state.cards[key] = { ...state.cards[key], ...payload };
	},
	SET_CHART(state, [key, payload]) {
		state.charts[key] = { ...state.charts[key], ...payload };
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
