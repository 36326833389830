import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c6432fe8 = () => interopDefault(import('../pages/analytics.vue' /* webpackChunkName: "pages/analytics" */))
const _dc2894c4 = () => interopDefault(import('../pages/appointments.vue' /* webpackChunkName: "pages/appointments" */))
const _7a9bb3bc = () => interopDefault(import('../pages/authenticating.vue' /* webpackChunkName: "pages/authenticating" */))
const _75e88b5a = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _407db405 = () => interopDefault(import('../pages/onboarding.vue' /* webpackChunkName: "pages/onboarding" */))
const _59b33b7b = () => interopDefault(import('../pages/patients/index.vue' /* webpackChunkName: "pages/patients/index" */))
const _103c0642 = () => interopDefault(import('../pages/payment-success.vue' /* webpackChunkName: "pages/payment-success" */))
const _bb1ec17a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _a68148d6 = () => interopDefault(import('../pages/screencast.vue' /* webpackChunkName: "pages/screencast" */))
const _2c20a960 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _211f388a = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _52bbb03e = () => interopDefault(import('../pages/suspended.vue' /* webpackChunkName: "pages/suspended" */))
const _f4ebf952 = () => interopDefault(import('../pages/token-expired.vue' /* webpackChunkName: "pages/token-expired" */))
const _7dca2ba8 = () => interopDefault(import('../pages/treatments/index.vue' /* webpackChunkName: "pages/treatments/index" */))
const _49f9e7c9 = () => interopDefault(import('../pages/trial-ended.vue' /* webpackChunkName: "pages/trial-ended" */))
const _441e70e2 = () => interopDefault(import('../pages/invoices/add.vue' /* webpackChunkName: "pages/invoices/add" */))
const _1b841c0a = () => interopDefault(import('../pages/patients/add.vue' /* webpackChunkName: "pages/patients/add" */))
const _23033377 = () => interopDefault(import('../pages/treatments/add.vue' /* webpackChunkName: "pages/treatments/add" */))
const _7b39ecba = () => interopDefault(import('../pages/treatments/recycle-bin.vue' /* webpackChunkName: "pages/treatments/recycle-bin" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3a4b950a = () => interopDefault(import('../pages/invoices/_id.vue' /* webpackChunkName: "pages/invoices/_id" */))
const _8b803eba = () => interopDefault(import('../pages/patients/_id.vue' /* webpackChunkName: "pages/patients/_id" */))
const _7c820fe0 = () => interopDefault(import('../pages/treatments/_id.vue' /* webpackChunkName: "pages/treatments/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/analytics",
    component: _c6432fe8,
    name: "analytics"
  }, {
    path: "/appointments",
    component: _dc2894c4,
    name: "appointments"
  }, {
    path: "/authenticating",
    component: _7a9bb3bc,
    name: "authenticating"
  }, {
    path: "/invoices",
    component: _75e88b5a,
    name: "invoices"
  }, {
    path: "/onboarding",
    component: _407db405,
    name: "onboarding"
  }, {
    path: "/patients",
    component: _59b33b7b,
    name: "patients"
  }, {
    path: "/payment-success",
    component: _103c0642,
    name: "payment-success"
  }, {
    path: "/reset-password",
    component: _bb1ec17a,
    name: "reset-password"
  }, {
    path: "/screencast",
    component: _a68148d6,
    name: "screencast"
  }, {
    path: "/settings",
    component: _2c20a960,
    name: "settings"
  }, {
    path: "/sign-in",
    component: _211f388a,
    name: "sign-in"
  }, {
    path: "/suspended",
    component: _52bbb03e,
    name: "suspended"
  }, {
    path: "/token-expired",
    component: _f4ebf952,
    name: "token-expired"
  }, {
    path: "/treatments",
    component: _7dca2ba8,
    name: "treatments"
  }, {
    path: "/trial-ended",
    component: _49f9e7c9,
    name: "trial-ended"
  }, {
    path: "/invoices/add",
    component: _441e70e2,
    name: "invoices-add"
  }, {
    path: "/patients/add",
    component: _1b841c0a,
    name: "patients-add"
  }, {
    path: "/treatments/add",
    component: _23033377,
    name: "treatments-add"
  }, {
    path: "/treatments/recycle-bin",
    component: _7b39ecba,
    name: "treatments-recycle-bin"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/invoices/:id",
    component: _3a4b950a,
    name: "invoices-id"
  }, {
    path: "/patients/:id",
    component: _8b803eba,
    name: "patients-id"
  }, {
    path: "/treatments/:id",
    component: _7c820fe0,
    name: "treatments-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
