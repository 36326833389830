import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_54604f30 from 'nuxt_plugin_plugin_54604f30' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_4de1dc0a from 'nuxt_plugin_sentryserver_4de1dc0a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2faf2afc from 'nuxt_plugin_sentryclient_2faf2afc' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_pluginutils_ea6a7e96 from 'nuxt_plugin_pluginutils_ea6a7e96' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_nuxtizitoastplugin00c0a1e0_de6c3624 from 'nuxt_plugin_nuxtizitoastplugin00c0a1e0_de6c3624' // Source: ./nuxt-izitoast.plugin.00c0a1e0.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_674415ee from 'nuxt_plugin_cookieuniversalnuxt_674415ee' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_apollomodule_77b2e812 from 'nuxt_plugin_apollomodule_77b2e812' // Source: ./apollo-module.js (mode: 'all')
import nuxt_plugin_recaptcha_01dc8496 from 'nuxt_plugin_recaptcha_01dc8496' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_moment_42881812 from 'nuxt_plugin_moment_42881812' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_antdesignvue_2e245193 from 'nuxt_plugin_antdesignvue_2e245193' // Source: ../plugins/ant-design-vue (mode: 'all')
import nuxt_plugin_i18nt_641ec90a from 'nuxt_plugin_i18nt_641ec90a' // Source: ../plugins/i18n-t (mode: 'all')
import nuxt_plugin_moment_4f74a614 from 'nuxt_plugin_moment_4f74a614' // Source: ../plugins/moment (mode: 'all')
import nuxt_plugin_userole_16636571 from 'nuxt_plugin_userole_16636571' // Source: ../plugins/hooks/use-role (mode: 'all')
import nuxt_plugin_usesettings_27efab9e from 'nuxt_plugin_usesettings_27efab9e' // Source: ../plugins/hooks/use-settings (mode: 'all')
import nuxt_plugin_useformat_12aeeb9c from 'nuxt_plugin_useformat_12aeeb9c' // Source: ../plugins/hooks/use-format (mode: 'all')
import nuxt_plugin_usenotify_04502984 from 'nuxt_plugin_usenotify_04502984' // Source: ../plugins/hooks/use-notify (mode: 'all')
import nuxt_plugin_usecookie_1ceec6c2 from 'nuxt_plugin_usecookie_1ceec6c2' // Source: ../plugins/hooks/use-cookie (mode: 'all')
import nuxt_plugin_usetoken_93b51744 from 'nuxt_plugin_usetoken_93b51744' // Source: ../plugins/hooks/use-token (mode: 'all')
import nuxt_plugin_usequery_940459a6 from 'nuxt_plugin_usequery_940459a6' // Source: ../plugins/hooks/use-query (mode: 'all')
import nuxt_plugin_usemutation_06058224 from 'nuxt_plugin_usemutation_06058224' // Source: ../plugins/hooks/use-mutation (mode: 'all')
import nuxt_plugin_usebind_165c0938 from 'nuxt_plugin_usebind_165c0938' // Source: ../plugins/hooks/use-bind (mode: 'all')
import nuxt_plugin_usefile_165dda77 from 'nuxt_plugin_usefile_165dda77' // Source: ../plugins/hooks/use-file (mode: 'all')
import nuxt_plugin_usesubscription_010dd9d0 from 'nuxt_plugin_usesubscription_010dd9d0' // Source: ../plugins/hooks/use-subscription (mode: 'client')
import nuxt_plugin_useaxios_95c4724a from 'nuxt_plugin_useaxios_95c4724a' // Source: ../plugins/hooks/use-axios (mode: 'client')
import nuxt_plugin_vuecal_163abed1 from 'nuxt_plugin_vuecal_163abed1' // Source: ../plugins/vue-cal (mode: 'client')
import nuxt_plugin_chart_353d13b0 from 'nuxt_plugin_chart_353d13b0' // Source: ../plugins/chart (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Clinikdna - your ultimate tool to streamline your practice and gain your comfort","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"name":"theme-color","content":"#0169FF"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Fapple-touch-icon.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Ffavicon-16x16.png"},{"rel":"manifest","href":"\u002Fsite.webmanifest"},{"rel":"mask-icon","href":"\u002Fsafari-pinned-tab.svg","color":"#0169FF"},{"hid":"gf-prefetch","rel":"dns-prefetch","href":"https:\u002F\u002Ffonts.gstatic.com\u002F"},{"hid":"gf-preconnect","rel":"preconnect","href":"https:\u002F\u002Ffonts.gstatic.com\u002F","crossorigin":""},{"hid":"gf-preload","rel":"preload","as":"style","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Nunito:wght@400;500;600;700;800;900&family=Rubik:wght@400;500"}],"style":[],"script":[{"hid":"gf-script","innerHTML":"(function(){var l=document.createElement('link');l.rel=\"stylesheet\";l.href=\"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Nunito:wght@400;500;600;700;800;900&family=Rubik:wght@400;500\";document.querySelector(\"head\").appendChild(l);})();"}],"noscript":[{"hid":"gf-noscript","innerHTML":"\u003Clink rel=\"stylesheet\" href=\"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Nunito:wght@400;500;600;700;800;900&family=Rubik:wght@400;500\"\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gf-script":["innerHTML"],"gf-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_54604f30 === 'function') {
    await nuxt_plugin_plugin_54604f30(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_4de1dc0a === 'function') {
    await nuxt_plugin_sentryserver_4de1dc0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2faf2afc === 'function') {
    await nuxt_plugin_sentryclient_2faf2afc(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_ea6a7e96 === 'function') {
    await nuxt_plugin_pluginutils_ea6a7e96(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtizitoastplugin00c0a1e0_de6c3624 === 'function') {
    await nuxt_plugin_nuxtizitoastplugin00c0a1e0_de6c3624(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_674415ee === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_674415ee(app.context, inject)
  }

  if (typeof nuxt_plugin_apollomodule_77b2e812 === 'function') {
    await nuxt_plugin_apollomodule_77b2e812(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_01dc8496 === 'function') {
    await nuxt_plugin_recaptcha_01dc8496(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_42881812 === 'function') {
    await nuxt_plugin_moment_42881812(app.context, inject)
  }

  if (typeof nuxt_plugin_antdesignvue_2e245193 === 'function') {
    await nuxt_plugin_antdesignvue_2e245193(app.context, inject)
  }

  if (typeof nuxt_plugin_i18nt_641ec90a === 'function') {
    await nuxt_plugin_i18nt_641ec90a(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_4f74a614 === 'function') {
    await nuxt_plugin_moment_4f74a614(app.context, inject)
  }

  if (typeof nuxt_plugin_userole_16636571 === 'function') {
    await nuxt_plugin_userole_16636571(app.context, inject)
  }

  if (typeof nuxt_plugin_usesettings_27efab9e === 'function') {
    await nuxt_plugin_usesettings_27efab9e(app.context, inject)
  }

  if (typeof nuxt_plugin_useformat_12aeeb9c === 'function') {
    await nuxt_plugin_useformat_12aeeb9c(app.context, inject)
  }

  if (typeof nuxt_plugin_usenotify_04502984 === 'function') {
    await nuxt_plugin_usenotify_04502984(app.context, inject)
  }

  if (typeof nuxt_plugin_usecookie_1ceec6c2 === 'function') {
    await nuxt_plugin_usecookie_1ceec6c2(app.context, inject)
  }

  if (typeof nuxt_plugin_usetoken_93b51744 === 'function') {
    await nuxt_plugin_usetoken_93b51744(app.context, inject)
  }

  if (typeof nuxt_plugin_usequery_940459a6 === 'function') {
    await nuxt_plugin_usequery_940459a6(app.context, inject)
  }

  if (typeof nuxt_plugin_usemutation_06058224 === 'function') {
    await nuxt_plugin_usemutation_06058224(app.context, inject)
  }

  if (typeof nuxt_plugin_usebind_165c0938 === 'function') {
    await nuxt_plugin_usebind_165c0938(app.context, inject)
  }

  if (typeof nuxt_plugin_usefile_165dda77 === 'function') {
    await nuxt_plugin_usefile_165dda77(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_usesubscription_010dd9d0 === 'function') {
    await nuxt_plugin_usesubscription_010dd9d0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_useaxios_95c4724a === 'function') {
    await nuxt_plugin_useaxios_95c4724a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecal_163abed1 === 'function') {
    await nuxt_plugin_vuecal_163abed1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_chart_353d13b0 === 'function') {
    await nuxt_plugin_chart_353d13b0(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
