import { getCurrency } from '~/utils/app/currencies';

export default ({ $useSettings, $$moment }, inject) => {
	const options = { maximumFractionDigits: 2, minimumFractionDigits: 0 },
		floatOptions = { maximumFractionDigits: 2, minimumFractionDigits: 2 },
		REF = {
			getPrice(value) {
				const { code, index } = $useSettings('numbers').currency;
				if (code !== REF.code || index !== REF.index) {
					REF.code = code;
					REF.index = index;
					const symbols = getCurrency(code).symbols;
					REF.price = symbols[index] || symbols[0];
				}
				return REF.price.value.replace('{{ value }}', value);
			},
		};
	REF.digits = ['en', 'fr', 'de', 'de-CH', 'hi', 'ar-SA', 'fa', 'mr', 'bn'].map((locale, i) => {
		const float = new Intl.NumberFormat(locale, floatOptions),
			number = new Intl.NumberFormat(locale, options),
			numbers = Array.from({ length: 10 }, (_, num) => number.format(num)),
			point = number.format(1.1).replaceAll(numbers[1], '');
		REF[locale] = { number, float, numbers, point };
		return numbers[0] === '0' && i !== 0 ? undefined : numbers;
	});

	/** Replace every digit in a string with its corresponding digit depending on numbers format in settings
	 * @param {number|string} value - The value to be formatted.
	 * @example
	 * // $store.settings.numbers = { numbersFormat: "en" }
	 * $useFormat("numeric", "2024/03/15") // Output: "2024/03/15"
	 * // $store.settings.numbers = { numbersFormat: "ar-SA" }
	 * $useFormat("numeric", "2024/03/15") // Output: "٢٠٢٤/٠٣/١٥"
	 */
	const numeric = (value, numbersFormat) => {
		const target = REF[numbersFormat || $useSettings('numbers').numbersFormat].numbers;
		REF.digits.map((arr) => arr?.map((num, i) => (value = `${value}`.replaceAll(num, target[i]))));
		return value;
	};

	/** Format numerical values as prices with percentage or currency representations or locale string numbers.
	 * @param {'number' | 'price' | 'percent'} format - The format type.
	 * @param {number} value - The numerical value to be formatted.
	 * @param {number} [tax] - Specify a tax rate value to apply (Between 0 and 100)
	 * @example
	 * // For instance let's say the store numbers settings is
	 * $store.settings.numbers = { numbersFormat: "en", currency: { code: "MAD", index: 0 } }
	 * $useFormat("number", 1245) // Output: "1,245"
	 * $useFormat("number", 1245.2) // Output: "1,245.2"
	 * $useFormat("percent", 73) // Output: "73%"
	 * $useFormat("percent", 73.9) // Output: "73.9%"
	 * $useFormat("price", 1245) // Output: "1,245.00 DH"
	 * $useFormat("price", 1245.2) // Output: "1,245.20 DH"
	 * $useFormat("price", 200, 20) // Output: "240.00 DH"
	 */
	const number = (format, value, tax = 0) => {
		const numbersFormat = $useSettings('numbers').numbersFormat,
			{ tax: TAX, input, numbersFormat: f } = typeof tax !== 'object' ? { tax } : tax,
			{ number, float, numbers, point } = REF[f || numbersFormat];
		if (TAX) value = value * (1 + TAX / 100);
		value = (format === 'price' && input === undefined ? float : number).format(value);
		if (format === 'percent') value = `${value}${numbers[1] === '1' ? '%' : '٪'}`;
		if (typeof input === 'boolean') value = `${value}${point}${input ? numbers[0] : ''}`;
		return format === 'price' ? REF.getPrice(value) : value;
	};

	/** Format UUIDs to be displayed for the user in a short format
	 * @param {string} value - The UUID value to be formatted.
	 * @param {string} prefix - The prefix value to be placed before the ID.
	 * @example
	 * const patient = { id: "2d5cd677-8fcf-4bef-8c70-d852a555f5fa" }
	 * $useFormat("id", patient.id) // Output: "2D5CD6778"
	 * $useFormat("id", patient.id, patient.full_name) // Output: "Oussama Saadaoui #2D5CD6778"
	 */
	const id = (value, prefix) => {
		value = numeric(value?.replaceAll('-', '').substring(0, 9).toUpperCase() || '');
		return prefix ? `${prefix} #${value}` : value;
	};

	/** Format date/time values based on the provided format in the settings.
	 * @param {'date' | 'time'} formatKey - The format type (the object key name in $store.state.settings.dates).
	 * @param {string | MomentInstance} value - The date value to be formatted. (It can be a moment instance or a string in the format "YYYY-MM-DD" or "HH:mm")
	 * @example
	 * // For instance let's say the store dates settings is
	 * $store.settings.dates = { time: "h:mm a", date: "DD/MM/YYYY" }
	 * $useFormat("time", "19:13") // Output: "7:13 pm"
	 * $useFormat("date", "2023-12-05") // Output: "05/12/2023"
	 * $useFormat("date", "2023-12-05", "D MMM") // Output: "5 Dec"
	 */
	const dateTime = (formatKey, value, extraFormat) => {
		const format = extraFormat || $useSettings('dates')[formatKey];
		return numeric($$moment(value).format(format));
	};

	/** Function to choose and apply the appropriate formatter based on the format type.
	 * @param {'id' | 'date' | 'time' | 'number' | 'price' | 'percent' | 'numeric' | 'currency'} format - The format type.
	 * @param {any} value - The value to be formatted.
	 * @param {any} [options] - Additional options for formatting (id prefix, price tax rate).
	 * @returns {string} The formatted value.
	 */
	const useFormat = (format, value, options) => {
		// ? For more info, refer to each function above. (each one has some examples in its description)
		if (format === 'id') return id(value, options);
		if (format === 'numeric') return numeric(value, options);
		if (format === 'currency') return REF.getPrice(value || '').trim();
		if (['time', 'date'].includes(format)) return dateTime(format, value, options);
		if (['number', 'price', 'percent'].includes(format)) return number(format, value, options);
		return '';
	};

	inject('useFormat', useFormat);
};
