/**
 * @typedef {Object} Country
 * @property {string} code The standard (ISO 3166) code of the country.
 * @property {string} currency The standard (ISO 4217) code of the currency.
 * @property {string} dateOrder The standard date format order (dmy | mdy | ymd).
 * @property {string} dateSeparator The standard date format separator (dot . | slash / | dash -).
 */

/** @type {Country[]} */
export const countries = Object.entries({
	AD: { currency: 'EUR' }, // Andorra
	AE: { currency: 'AED' }, // The United Arab Emirates
	AF: { currency: 'AFN' }, // Afghanistan
	AG: { currency: 'XCD' }, // Antigua and Barbuda
	AL: { currency: 'ALL' }, // Albania
	AM: { currency: 'AMD' }, // Armenia
	AO: { currency: 'AOA' }, // Angola
	AR: { currency: 'ARS', dateSeparator: '.' }, // Argentina
	AT: { currency: 'EUR', dateOrder: 'ymd', dateSeparator: '-' }, // Austria
	AU: { currency: 'AUD' }, // Australia
	AZ: { currency: 'AZN' }, // Azerbaijan
	BA: { currency: 'BAM', dateSeparator: '.' }, // Bosnia and Herzegovina
	BB: { currency: 'BBD' }, // Barbados
	BD: { currency: 'BDT' }, // Bangladesh
	BE: { currency: 'EUR', dateSeparator: '.' }, // Belgium
	BF: { currency: 'XOF' }, // Burkina Faso
	BG: { currency: 'BGN', dateSeparator: '.' }, // Bulgaria
	BH: { currency: 'BHD' }, // Bahrain
	BI: { currency: 'BIF' }, // Burundi
	BJ: { currency: 'XOF' }, // Benin
	BN: { currency: 'BND' }, // Brunei Darussalam
	BO: { currency: 'BOB' }, // Plurinational State of Bolivia
	BR: { currency: 'BRL' }, // Brazil
	BS: { currency: 'BSD' }, // The Bahamas
	BT: { currency: 'BTN', dateOrder: 'ymd' }, // Bhutan
	BW: { currency: 'BWP' }, // Botswana
	BY: { currency: 'BYN', dateSeparator: '.' }, // Belarus
	BZ: { currency: 'BZD' }, // Belize
	CA: { currency: 'CAD', dateSeparator: '-' }, // Canada
	CD: { currency: 'CDF' }, // the Democratic Republic of the Congo
	CF: { currency: 'XAF' }, // The Central African Republic
	CG: { currency: 'XAF' }, // The Congo
	CH: { currency: 'CHF', dateSeparator: '.' }, // Switzerland
	CI: { currency: 'XOF' }, // Côte d'Ivoire
	CL: { currency: 'CLP' }, // Chile
	CM: { currency: 'XAF' }, // Cameroon
	CN: { currency: 'CNY', dateOrder: 'ymd', dateSeparator: '-' }, // China
	CO: { currency: 'COP' }, // Colombia
	CR: { currency: 'CRC' }, // Costa Rica
	CU: { currency: 'CUP', dateOrder: 'ymd' }, // Cuba
	CV: { currency: 'CVE' }, // Cabo Verde
	CY: { currency: 'EUR' }, // Cyprus
	CZ: { currency: 'CZK' }, // Czechia
	DE: { currency: 'EUR', dateSeparator: '.' }, // Germany
	DJ: { currency: 'DJF' }, // Djibouti
	DK: { currency: 'DKK', dateSeparator: '.' }, // Denmark
	DM: { currency: 'XCD' }, // Dominica
	DO: { currency: 'DOP' }, // The Dominican Republic
	DZ: { currency: 'DZD' }, // Algeria
	EC: { currency: 'USD' }, // Ecuador
	EE: { currency: 'EUR' }, // Estonia
	EG: { currency: 'EGP' }, // Egypt
	ER: { currency: 'ERN', dateOrder: 'ymd' }, // Eritrea
	ES: { currency: 'EUR' }, // Spain
	ET: { currency: 'ETB' }, // Ethiopia
	FI: { currency: 'EUR', dateSeparator: '.' }, // Finland
	FJ: { currency: 'FJD' }, // Fiji
	FM: { currency: 'USD', dateOrder: 'mdy' }, // Federated States of Micronesia
	FR: { currency: 'EUR' }, // France
	GA: { currency: 'XAF' }, // Gabon
	GB: { currency: 'GBP' }, // The United Kingdom of Great Britain and Northern Ireland
	GD: { currency: 'XCD' }, // Grenada
	GE: { currency: 'GEL' }, // Georgia
	GH: { currency: 'GHS' }, // Ghana
	GM: { currency: 'GMD' }, // The Gambia
	GN: { currency: 'GNF' }, // Guinea
	GQ: { currency: 'XAF' }, // Equatorial Guinea
	GR: { currency: 'EUR' }, // Greece
	GT: { currency: 'GTQ' }, // Guatemala
	GW: { currency: 'XOF' }, // Guinea-Bissau
	GY: { currency: 'GYD' }, // Guyana
	HN: { currency: 'HNL' }, // Honduras
	HR: { currency: 'EUR', dateSeparator: '-' }, // Croatia
	HT: { currency: 'HTG' }, // Haiti
	HU: { currency: 'HUF', dateOrder: 'ymd', dateSeparator: '-' }, // Hungary
	ID: { currency: 'IDR' }, // Indonesia
	IE: { currency: 'EUR' }, // Ireland
	IL: { currency: 'ILS', dateSeparator: '.' }, // Israel
	IN: { currency: 'INR', dateSeparator: '-' }, // India
	IQ: { currency: 'IQD' }, // Iraq
	IR: { currency: 'IRR', dateOrder: 'ymd' }, // Islamic Republic of Iran
	IS: { currency: 'ISK' }, // Iceland
	IT: { currency: 'EUR', dateSeparator: '-' }, // Italy
	JM: { currency: 'JMD' }, // Jamaica
	JO: { currency: 'JOD' }, // Jordan
	JP: { currency: 'JPY', dateOrder: 'ymd' }, // Japan
	KE: { currency: 'KES', dateSeparator: '-' }, // Kenya
	KG: { currency: 'KGS', dateSeparator: '.' }, // Kyrgyzstan
	KH: { currency: 'KHR' }, // Cambodia
	KI: { currency: 'AUD' }, // Kiribati
	KM: { currency: 'KMF' }, // The Comoros
	KN: { currency: 'XCD' }, // Saint Kitts and Nevis
	KP: { currency: 'KPW', dateOrder: 'ymd', dateSeparator: '-' }, // The Democratic People's Republic of Korea
	KR: { currency: 'KRW', dateOrder: 'ymd', dateSeparator: '-' }, // The Republic of Korea
	KW: { currency: 'KWD' }, // Kuwait
	KZ: { currency: 'KZT' }, // Kazakhstan
	LA: { currency: 'LAK' }, // The Lao People's Democratic Republic
	LB: { currency: 'LBP' }, // Lebanon
	LC: { currency: 'XCD' }, // Saint Lucia
	LI: { currency: 'CHF' }, // Liechtenstein
	LK: { currency: 'LKR', dateOrder: 'ymd' }, // Sri Lanka
	LR: { currency: 'LRD' }, // Liberia
	LS: { currency: 'LSL' }, // Lesotho
	LT: { currency: 'EUR', dateOrder: 'ymd', dateSeparator: '-' }, // Lithuania
	LU: { currency: 'EUR' }, // Luxembourg
	LV: { currency: 'EUR' }, // Latvia
	LY: { currency: 'LYD' }, // Libya
	MA: { currency: 'MAD' }, // Morocco
	MC: { currency: 'EUR' }, // Monaco
	MD: { currency: 'MDL' }, // The Republic of Moldova
	ME: { currency: 'EUR', dateSeparator: '.' }, // Montenegro
	MG: { currency: 'MGA' }, // Madagascar
	MH: { currency: 'USD' }, // The Marshall Islands
	MK: { currency: 'MKD', dateSeparator: '.' }, // North Macedonia
	ML: { currency: 'XOF' }, // Mali
	MM: { currency: 'MMK' }, // Myanmar
	MN: { currency: 'MNT', dateOrder: 'ymd', dateSeparator: '-' }, // Mongolia
	MR: { currency: 'MRU' }, // Mauritania
	MT: { currency: 'EUR' }, // Malta
	MU: { currency: 'MUR' }, // Mauritius
	MV: { currency: 'MVR' }, // Maldives
	MW: { currency: 'MWK' }, // Malawi
	MX: { currency: 'MXN' }, // Mexico
	MY: { currency: 'MYR' }, // Malaysia
	MZ: { currency: 'MZN' }, // Mozambique
	NA: { currency: 'NAD' }, // Namibia
	NE: { currency: 'XOF' }, // The Niger
	NG: { currency: 'NGN' }, // Nigeria
	NI: { currency: 'NIO', dateSeparator: '.' }, // Nicaragua
	NL: { currency: 'EUR', dateSeparator: '-' }, // The Netherlands
	NO: { currency: 'NOK', dateSeparator: '.' }, // Norway
	NP: { currency: 'NPR', dateSeparator: '-' }, // Nepal
	NR: { currency: 'AUD' }, // Nauru
	NZ: { currency: 'NZD' }, // New Zealand
	OM: { currency: 'OMR' }, // Oman
	PA: { currency: 'PAB' }, // Panama
	PE: { currency: 'PEN' }, // Peru
	PG: { currency: 'PGK' }, // Papua New Guinea
	PH: { currency: 'PHP' }, // The Philippines
	PK: { currency: 'PKR' }, // Pakistan
	PL: { currency: 'PLN', dateSeparator: '.' }, // Poland
	PS: { currency: 'ILS' }, // Palestine, State of
	PT: { currency: 'EUR' }, // Portugal
	PW: { currency: 'USD' }, // Palau
	PY: { currency: 'PYG' }, // Paraguay
	QA: { currency: 'QAR' }, // Qatar
	RO: { currency: 'RON', dateSeparator: '.' }, // Romania
	RS: { currency: 'RSD', dateSeparator: '.' }, // Serbia
	RU: { currency: 'RUB' }, // The Russian Federation
	RW: { currency: 'RWF' }, // Rwanda
	SA: { currency: 'SAR' }, // Saudi Arabia
	SB: { currency: 'SBD' }, // Solomon Islands
	SC: { currency: 'SCR' }, // Seychelles
	SD: { currency: 'SDG' }, // The Sudan
	SE: { currency: 'SEK', dateOrder: 'ymd', dateSeparator: '-' }, // Sweden
	SG: { currency: 'SGD' }, // Singapore
	SI: { currency: 'EUR' }, // Slovenia
	SK: { currency: 'EUR' }, // Slovakia
	SL: { currency: 'SLL' }, // Sierra Leone
	SM: { currency: 'EUR' }, // San Marino
	SN: { currency: 'XOF' }, // Senegal
	SO: { currency: 'SOS' }, // Somalia
	SR: { currency: 'SRD' }, // Suriname
	SS: { currency: 'SSP' }, // South Sudan
	ST: { currency: 'STN' }, // Sao Tome and Principe
	SV: { currency: 'USD' }, // El Salvador
	SY: { currency: 'SYP' }, // Syrian Arab Republic
	SZ: { currency: 'SZL', dateOrder: 'ymd' }, // Eswatini
	TD: { currency: 'XAF' }, // Chad
	TG: { currency: 'XOF' }, // Togo
	TH: { currency: 'THB' }, // Thailand
	TJ: { currency: 'TJS' }, // Tajikistan
	TL: { currency: 'USD' }, // Timor-Leste
	TM: { currency: 'TMT', dateSeparator: '.' }, // Turkmenistan
	TN: { currency: 'TND' }, // Tunisia
	TO: { currency: 'TOP' }, // Tonga
	TR: { currency: 'TRY', dateSeparator: '.' }, // Turkey
	TT: { currency: 'TTD' }, // Trinidad and Tobago
	TV: { currency: 'AUD' }, // Tuvalu
	TZ: { currency: 'TZS' }, // Tanzania, United Republic of
	UA: { currency: 'UAH', dateSeparator: '.' }, // Ukraine
	UG: { currency: 'UGX' }, // Uganda
	US: { currency: 'USD', dateOrder: 'mdy', dateSeparator: '-' }, // The United States of America
	UY: { currency: 'UYU' }, // Uruguay
	UZ: { currency: 'UZS', dateSeparator: '.' }, // Uzbekistan
	VA: { currency: 'EUR' }, // The Holy See
	VC: { currency: 'XCD' }, // Saint Vincent and the Grenadines
	VE: { currency: 'VEF' }, // Bolivarian Republic of Venezuela
	VN: { currency: 'VND' }, // Viet Nam
	VU: { currency: 'VUV' }, // Vanuatu
	WS: { currency: 'WST' }, // Samoa
	YE: { currency: 'YER' }, // Yemen
	ZA: { currency: 'ZAR', dateOrder: 'ymd' }, // South Africa
	ZM: { currency: 'ZMW' }, // Zambia
	ZW: { currency: 'ZWL' }, // Zimbabwe
})
	.map(([code, { currency, dateOrder, dateSeparator }]) => {
		return { code, currency, dateOrder: dateOrder || 'dmy', dateSeparator: dateSeparator || '/' };
	})
	.sort((country1, country2) => (country1.code > country2.code ? 1 : -1));

export const importantCountries = [
	'US',
	'GB',
	'CA',
	'FR',
	'DE',
	'MA',
	'DK',
	'JP',
	'CN',
	'ES',
	'BE',
	'IT',
	'AU',
	'PL',
	'NO',
];

/** @type {(code: string) => Country} */
export const getCountry = (code) => countries.find((country) => country.code === code);
