export default ({ app, $useToken, $useNotify, $sentry }, inject) => {
	const client = app.apolloProvider.defaultClient;

	const useMutation = async (
		mutation,
		variables = {},
		onSuccess = () => {},
		onError = () => $useNotify('error')
	) => {
		try {
			const jwtToken = $useToken();
			if (!jwtToken) throw new Error('Invalid jwt token!');
			const headers = { Authorization: `Bearer ${jwtToken}` };
			const options = { mutation, variables, context: { headers } };
			return onSuccess((await client.mutate(options)).data);
		} catch (error) {
			$sentry.captureException(error);
			console.error(error?.message || error);
			return onError(error, error?.message || '');
		}
	};

	inject('useMutation', useMutation);
};
