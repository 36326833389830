export default ({ app, $useToken, $useNotify, $sentry }, inject) => {
	const useAxios = async (
		endpoint,
		variables,
		onSuccess = () => {},
		onError = () => $useNotify('error')
	) => {
		try {
			const jwtToken = $useToken();
			if (!jwtToken) throw new Error('Invalid jwt token!');
			const headers = { Authorization: `Bearer ${jwtToken}` };
			return useSimpleAxios(endpoint, variables, headers, onSuccess, onError);
		} catch (error) {
			$sentry.captureException(error);
			console.error(error?.message || error);
			return onError(error, error.message || '');
		}
	};

	const useSimpleAxios = async (
		endpoint,
		variables,
		headers = {},
		onSuccess = () => {},
		onError = () => $useNotify('error')
	) => {
		if (typeof headers === 'function') {
			onError = onSuccess;
			onSuccess = headers;
			headers = {};
		}
		try {
			// TOFIX: Clean and don't use FormData in the back-end functions, then delete this if-statement block
			const endpoints_with_FormData = ['read', 'upload', 'invite-user', 'create-stripe-session', 'verify-email', 'verify-code']; // prettier-ignore
			if (endpoints_with_FormData.includes(endpoint)) {
				const variablesData = new FormData();
				Object.entries(variables).map(([key, value]) => variablesData.append(key, value));
				const options = { headers: { 'Content-Type': 'multipart/form-data', ...headers } };
				return onSuccess(await app.$axios.$post(`/${endpoint}`, variablesData, options));
			}
			return onSuccess(await app.$axios.$post(`/${endpoint}`, variables, { headers }));
		} catch (error) {
			$sentry.captureException(error);
			console.error(error?.message || error);
			return onError(error, error?.message || '');
		}
	};

	inject('useAxios', useAxios);
	inject('useSimpleAxios', useSimpleAxios);
};
