
export default {
	name: 'DeveloperCopyUserInfo',
	methods: {
		async copyId() {
			let id = this.$store.getters['user/GET_USER']?.id;
			if (id) this.toClipboard(id, 'User UUID');
			else this.$useNotify('error', 'Oops! No token found.');
		},
		async copyToken() {
			let token = this.$useToken();
			if (token) this.toClipboard(token);
			else this.$useNotify('error', 'Oops! No token found.');
		},
		async copyBearer() {
			let token = `Bearer ${this.$useToken() || ''}`;
			if (token !== 'Bearer ') this.toClipboard(token);
			else this.$useNotify('error', 'Oops! No token found.');
		},
		toClipboard(text, element = 'Token') {
			const textarea = document.createElement('textarea');
			textarea.value = text;
			textarea.style.position = 'absolute';
			textarea.style.left = '-9999px';
			document.body.appendChild(textarea);
			textarea.select();
			document.execCommand('copy');
			document.body.removeChild(textarea);
			this.$useNotify('success', `${element} copied to clipboard successfully.`);
		},
	},
};
