import PlaylistsSubscription from '~/apollo/subscriptions/playlists';
import UpdatePlaylist from '~/apollo/mutations/playlist/update';

export const state = () => ({
	playlist: { id: '', videos: [] },
});

export const getters = {
	GET_PLAYLIST({ playlist }) {
		return playlist;
	},
};

export const actions = {
	SET_PLAYLIST({ commit }) {
		const filters = { where: { is_active: { _eq: true } } };
		this.$useSubscription('playlist', PlaylistsSubscription, filters, ({ playlists }) => {
			if (!playlists.length) return;
			commit('SET_PLAYLIST', { id: playlists[0].id, videos: playlists[0].videos });
		});
	},
	async ADD_TO_PLAYLIST({ getters }, videoId) {
		const { id, videos } = getters['GET_PLAYLIST'],
			set = { videos: [...videos, videoId] };
		await this.$useMutation(UpdatePlaylist, { id, set }, () =>
			this.$useBindAction('added', 'waiting-room.the-youtube-video')
		);
	},
	async REMOVE_FROM_PLAYLIST({ getters }, videoId) {
		const { id, videos } = getters['GET_PLAYLIST'],
			set = { videos: videos.filter((item) => item !== videoId) };
		await this.$useMutation(UpdatePlaylist, { id, set }, () =>
			this.$useBindAction('deleted', 'waiting-room.the-youtube-video')
		);
	},
};

export const mutations = {
	SET_PLAYLIST(state, payload) {
		state.playlist = payload;
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
