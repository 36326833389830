export default ({ app, $useToken, $useNotify, $sentry }, inject) => {
	const client = app.apolloProvider.defaultClient;

	const useQuery = async (
		query,
		variables = {},
		onSuccess = () => {},
		onError = () => $useNotify('error')
	) => {
		try {
			const jwtToken = $useToken();
			if (!jwtToken) throw new Error('Invalid jwt token!');
			const headers = { Authorization: `Bearer ${jwtToken}` };
			return useSimpleQuery(query, variables, onSuccess, onError, headers);
		} catch (error) {
			$sentry.captureException(error);
			console.error(error?.message || error);
			return onError(error, error?.message || '');
		}
	};

	const useSimpleQuery = async (
		query,
		variables = {},
		onSuccess = () => {},
		onError = () => $useNotify('error'),
		headers
	) => {
		try {
			const options = { query, variables, context: { headers }, fetchPolicy: 'no-cache' };
			if (!headers) delete options.context;
			return onSuccess((await client.query(options)).data);
		} catch (error) {
			$sentry.captureException(error);
			console.error(error?.message || error);
			return onError(error, error?.message || '');
		}
	};

	inject('useQuery', useQuery);
	inject('useSimpleQuery', useSimpleQuery);
};
