
// ! When clearing the input programmatically, reset the entire value.
// parentContext.article = { value: {}, valid: true }
// parentContext.article = {}

/*
	The JSON object used by this component accepts these properties:
	- version: string (optional) — Represents the Editor.js version, currently '2.30.3'.
	- blocks: any[] (required) — Represents the blocks data. Each block can be an object with the following properties:
		- type: string (required) — The block type: 'paragraph' | 'heading' | 'list' | 'checklist' | 'table' | 'delimiter'.
		- data: object (required) — The actual data to be rendered, corresponding to the block type:
			- paragraph: { text: string }
			- heading: { text: string, level: 1 | 2 | 3 | 4 }
			- list: { items: string[], style: 'ordered' | 'unordered' }
			- checklist: { items: { text: string, checked: boolean }[] }
			- table: { content: string[][], withHeadings: boolean }
			- delimiter: { style: 'divider' | 'delimiter' }
*/

import input from '~/mixins/input';
import { EditorJS } from '~/utils/libs/override-editor-js';

export default {
	name: 'FormEditor',
	mixins: [input],
	props: {
		// HTML element id, this prop should be unique if there are many editor components in the same page.
		editorId: { type: String, default: '-js' },
		// The field value, which can be a JSON object that includes exactly what Editor.JS would accept {time, blocks, version}. Or an object of { value, valid }, Refer to the input mixin for object value { value, valid }.
		value: { type: Object, default: () => {} },
		// The visible value (JSON object) when the field is disabled. By default, it takes the current value.
		disabledValue: { type: Object, required: false },
	},
	data() {
		return {
			editor: null,
			currentData: {},
		};
	},
	computed: {
		placeholderText() {
			return this.$t('notes.note-placeholder');
		},
		internationalization() {
			return {
				messages: {
					ui: {
						blockTunes: {
							toggler: {
								'Click to tune': this.$t('notes.tune-click'),
								'or drag to move': this.$t('notes.tune-drag'),
							},
						},
						toolbar: { toolbox: { Add: this.$t('actions.add') } },
						inlineToolbar: { converter: { 'Convert to': this.$t('notes.convert') } },
						popover: {
							Filter: this.$t('invoices.filter'),
							'Nothing found': this.$t('notes.empty'),
							'Convert to': this.$t('notes.convert'),
						},
					},
					tools: { link: { 'Add a link': this.$t('notes.add-link') } },
					blockTunes: {
						delete: {
							Delete: this.$t('actions.delete'),
							'Click to delete': this.$t('notes.ensure-delete'),
						},
						moveUp: { 'Move up': this.$t('notes.move-up') },
						moveDown: { 'Move down': this.$t('notes.move-down') },
					},
				},
			};
		},
	},
	methods: {
		clone(value, _, toEmit) {
			const str = (value) => (value ? `${value}` : '');
			const blocks = value?.blocks?.map?.((block) => {
				if (!this.isBlockValid(block)) return;
				let data;
				const { text, level, items: i, style, content, withHeadings } = block.data || {};
				if (block.type === 'paragraph') data = { text: str(text) };
				else if (block.type === 'heading')
					data = { text: str(text), level: [1, 2, 3, 4].includes(level) ? level : 2 };
				else if (block.type === 'delimiter')
					data = { style: style === 'delimiter' ? 'delimiter' : 'divider' };
				else if (block.type === 'list')
					data = { items: i.map(str), style: style === 'ordered' ? 'ordered' : 'unordered' };
				else if (block.type === 'checklist')
					data = { items: i.map((it) => ({ text: str(it?.text), checked: Boolean(it?.checked) })) };
				else if (block.type === 'table')
					data = { content: content.map((i) => i.map(str)), withHeadings: Boolean(withHeadings) };
				block = data ? { id: block.id, type: block.type, data } : undefined;
				if (toEmit || typeof block.id !== 'string') delete block.id;
				return block;
			});
			value = { time: value.time, version: value.version, blocks: blocks?.filter?.(Boolean) || [] };
			if (toEmit || typeof value.time !== 'number') delete value.time;
			if (typeof value.version !== 'string') value.version = '2.30.3';
			return value;
		},
		areEqual({ blocks: value } = {}, { blocks: value2 } = {}) {
			const isSame = (obj1, obj2, ...attrs) => attrs.every((a) => obj1?.[a] === obj2?.[a]);
			if (!isSame(value, value2, 'length')) return false;
			return value?.every((v, i) => {
				const v2 = value2?.[i];
				if (!this.isBlockValid(v) || !this.isBlockValid(v2) || v.type !== v2.type) return false;
				if (['paragraph', 'heading', 'delimiter'].includes(v.type))
					return isSame(v.data, v2.data, 'text', 'level', 'style');
				else if (!isSame(v.data, v2.data, 'style', 'withHeadings')) return false;
				const items = v.type === 'table' ? v.data?.content : [v.data?.items];
				const items2 = v.type === 'table' ? v2.data?.content : [v2.data?.items];
				if (!isSame(items, items2, 'length')) return false;
				return items.every((item, i) => {
					if (!isSame(item, items2[i], 'length')) return false;
					return item.every((c, j) => {
						const c2 = items2[i][j];
						return v.type === 'checklist' ? isSame(c, c2, 'text', 'checked') : c === c2;
					});
				});
			});
		},
		parseValue(value) {
			return value;
		},
		formatToEmit(value) {
			return this.clone(value, undefined, true);
		},
		isEmpty(value) {
			return !Array.isArray(value?.blocks) || !value.blocks.length;
		},
		isValueValid(value, required, callback) {
			let valid = false;
			if (Array.isArray(value?.blocks) && (!required || value.blocks.length))
				valid = value.blocks.every(this.isBlockValid);
			return callback ? callback(valid, valid ? this.formatToEmit(value) : undefined) : valid;
		},
		isBlockValid({ type, data } = {}) {
			if (['paragraph', 'heading', 'delimiter'].includes(type)) return true;
			if (['list', 'checklist'].includes(type)) return Array.isArray(data?.items);
			if (type === 'table')
				return Array.isArray(data?.content) && data.content.every(Array.isArray);
		},
	},
	async mounted() {
		this.editor = await EditorJS.create(this, {
			holder: `editor${this.editorId}`,
			data: this.props.value,
			placeholder: this.props.placeholder,
			disabled: this.props.disabled,
			i18n: this.internationalization,
			onChange: (data) => this.onChange((this.currentData = data)),
		});
	},
	async beforeDestroy() {
		await this.editor?.destroy?.();
		this.editor = null;
	},
	watch: {
		'props.value'(value, old) {
			if (this.editor && !this.areEqual(value, this.currentData) && !this.areEqual(value, old)) {
				this.currentData = value;
				this.editor.render?.({ blocks: [], ...value });
			}
		},
		'props.disabled'(value) {
			if (!this.editor || this.editor.readOnly.isEnabled === value) return;
			this.editor.readOnly.toggle();
		},
	},
};

// Values not implemented yet: 'this.props.allowClear'
