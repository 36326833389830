const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['not-suspended'] = require('../middleware/not-suspended.js')
middleware['not-suspended'] = middleware['not-suspended'].default || middleware['not-suspended']

middleware['parse-route'] = require('../middleware/parse-route.js')
middleware['parse-route'] = middleware['parse-route'].default || middleware['parse-route']

middleware['redirect-index'] = require('../middleware/redirect-index.js')
middleware['redirect-index'] = middleware['redirect-index'].default || middleware['redirect-index']

middleware['redirect-sign-in'] = require('../middleware/redirect-sign-in.js')
middleware['redirect-sign-in'] = middleware['redirect-sign-in'].default || middleware['redirect-sign-in']

middleware['suspended'] = require('../middleware/suspended.js')
middleware['suspended'] = middleware['suspended'].default || middleware['suspended']

middleware['trial-ended'] = require('../middleware/trial-ended.js')
middleware['trial-ended'] = middleware['trial-ended'].default || middleware['trial-ended']

middleware['trial-not-ended'] = require('../middleware/trial-not-ended.js')
middleware['trial-not-ended'] = middleware['trial-not-ended'].default || middleware['trial-not-ended']

export default middleware
