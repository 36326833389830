// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/global/error-illustration.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-page-content[data-v-0af84061]{align-items:center;display:flex;flex-direction:column;gap:2.75rem;padding-top:13rem}h1[data-v-0af84061]{font-size:1.25rem;font-weight:700;line-height:1.75rem;margin-bottom:1.25rem;padding-left:.75rem;padding-right:.75rem;text-align:center;--tw-text-opacity:1;color:#374151;color:rgba(55,65,81,var(--tw-text-opacity))}@media (min-width:768px){h1[data-v-0af84061]{font-size:1.5rem;line-height:2rem;margin-bottom:2rem}}.error-illustration[data-v-0af84061]{aspect-ratio:704/373.703;max-width:95%;width:20rem}.error-illustration.with-image[data-v-0af84061]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:contain}@media (min-width:1024px){.error-illustration[data-v-0af84061]{width:31rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
