export default ({ i18n, app }, inject) => {
	const formatterRegex = /%(date|time|number|price|percent|numeric|currency)%[\d\.\-:]+%/g;

	inject('tr', (key) => {
		const text = i18n.t(key);
		return text.replace(formatterRegex, (format_num) => {
			format_num = format_num.split('%');
			return app.$useFormat(format_num[1], format_num[2]);
		});
	});

	// * Binding i18n to the function, because they use the keyword 'this' inside the function 't' which refers to the i18n object
	inject('t', i18n.t.bind(i18n));
};
