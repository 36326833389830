import PaginatePatients from '~/apollo/queries/patients/paginate';
import TreatmentsSearch from '~/apollo/queries/treatments/all';

export default {
	name: 'DeveloperEntriesGenerator',
	props: {
		currentPatient: { type: Object, required: false },
	},
	data() {
		return {
			patientError: false,
			treatmentError: false,
			patient: 'random',
			patients: [
				{ value: 'current', label: 'Current patient' },
				{ value: 'random', label: 'Random patients' },
			],
			range: 'week',
			ranges: [
				{ value: 'week', label: 'This week' },
				{ value: 'month', label: 'This month' },
				{ value: 'last-month', label: 'The last month' },
			],
			dateTimeSettings: ['days', 'hours'],
		};
	},
	computed: {
		settings() {
			const settings = {};
			if (this.dateTimeSettings.includes('hours')) {
				const hours = this.$useSettings('dates').businessHours;
				let $hours = [hours.from, hours.to].map((time) => {
					time = time.split(':').map((n) => parseInt(n));
					return time[0] * 60 + time[1];
				});
				settings.hours = [];
				let current = $hours[0];
				while (current + 15 < $hours[1]) {
					settings.hours.push([current, current + 15]);
					current += 15;
				}
			}
			if (this.dateTimeSettings.includes('days')) {
				let dates = this.$$moment();
				let range = this.range === 'week' ? 'isoWeek' : this.range;
				if (range === 'last-month') {
					dates = dates.clone().subtract(1, 'months');
					range = 'month';
				}
				dates = [dates.clone().startOf(range), dates.clone().endOf(range)];
				settings.days = [];
				while (dates[1].isSameOrAfter(dates[0])) {
					settings.days.push(dates[0].clone());
					dates[0].add(1, 'days');
				}
			}
			return settings;
		},
		number() {
			if (this.patient === 'current' && this.currentPatient) return this.range === 'week' ? 6 : 12;
			return this.range === 'week' ? 45 : 90;
		},
		treatmentsNumber() {
			return (this.number / 3) * 5;
		},
	},
	methods: {
		extendByDuplicating(array, length, errorHolder) {
			if (!Array.isArray(array) || !array.length) {
				if (typeof errorHolder === 'string') this[errorHolder] = true;
				return [];
			}
			while (array.length < length) array = [...array, ...array];
			return array.slice(0, length);
		},
		shuffleArray(arr) {
			arr = arr.slice();
			for (let i = arr.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[arr[i], arr[j]] = [arr[j], arr[i]];
			}
			return arr;
		},
		async getPatients(length) {
			const offset = Math.floor(Math.random() * 6);
			const array =
				this.currentPatient && this.patient === 'current'
					? [this.currentPatient.id]
					: await this.$useQuery(
							PaginatePatients,
							{ offset, limit: Math.floor(this.number * 0.67), where: {} },
							({ patients }) => patients.map((p) => p.id)
					  );
			return this.extendByDuplicating(array, length, 'patientError');
		},
		async getTreatments() {
			const limit = this.treatmentsNumber,
				array = await this.$useQuery(TreatmentsSearch, { limit, where: {} }, ({ treatments }) =>
					treatments.map((t) => t.id)
				);
			return this.extendByDuplicating(array, limit, 'treatmentError');
		},
		validDate(date) {
			date = typeof date === 'string' ? this.$$moment(date) : date.clone();
			const days = [...this.$useSettings('dates').workingDays].sort(),
				day = Number(date.format('d'));
			if (days.includes(day)) return date.format('YYYY-MM-DD');
			let diff = days.some((d) => d > day) ? days.find((d) => d > day) - day : 7 + days[0] - day;
			return date.add(diff, 'days').format('YYYY-MM-DD');
		},
		getDates(type, length) {
			return this.shuffleArray(
				this.extendByDuplicating(this.shuffleArray(this.settings[type]), length)
			).map((date) =>
				type === 'hours' ? date.map((n) => `${Math.floor(n / 60)}:${n % 60}`) : this.validDate(date)
			);
		},
	},
};
