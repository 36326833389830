import { render, staticRenderFns } from "./app.vue?vue&type=template&id=c5e56ae2&scoped=true&lang=pug"
import script from "./app.vue?vue&type=script&lang=js"
export * from "./app.vue?vue&type=script&lang=js"
import style0 from "./app.vue?vue&type=style&index=0&id=c5e56ae2&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5e56ae2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IntercomMessenger: require('/app/components/global/IntercomMessenger.vue').default,DeveloperDevtool: require('/app/components/developer/Devtool.vue').default,Logo: require('/app/components/global/Logo.vue').default,PayloadLink: require('/app/components/global/PayloadLink.vue').default,DateTime: require('/app/components/global/DateTime.vue').default})
