const waitingRoom = {
		root: '/',
		title: 'waiting-room.waiting-room',
		icon: 'clock-circle',
	},
	patients = {
		root: '/patients',
		title: 'patient-records.patients',
		icon: 'folder',
	},
	appointments = {
		root: '/appointments',
		title: 'appointments.appointments',
		icon: 'calendar',
	},
	treatments = {
		root: '/treatments',
		title: 'treatments.treatments',
		icon: 'tags',
	},
	treatmentsViewOnly = { ...treatments, except: ['/treatments/add', '/treatments/recycle-bin'] },
	invoices = {
		root: '/invoices',
		title: 'invoices.invoices-quotations',
		icon: 'file-text',
	},
	invoicesViewOnly = { ...invoices, except: '/invoices/add' },
	analytics = {
		root: '/analytics',
		title: 'analytics.analytics',
		icon: 'bar-chart',
	},
	settings = {
		root: '/settings',
		title: 'settings.settings',
		icon: 'setting',
	};

export default function getRoutes(role) {
	switch (role) {
		case 'owner':
			return [waitingRoom, appointments, patients, invoices, analytics, treatments, settings];
		case 'administrator':
			return [waitingRoom, appointments, patients, invoices, analytics, treatments, settings];
		case 'practitioner':
			return [waitingRoom, appointments, patients];
		case 'nurse':
			return [waitingRoom, appointments, patients, invoices];
		case 'receptionist':
			return [waitingRoom, appointments, patients, invoices, treatmentsViewOnly];
		case 'bookkeeper':
			return [analytics, invoicesViewOnly, treatments];
		default:
			return null;
	}
}
